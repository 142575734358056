import {axiosGet} from "../../../Helpers/AxiosHelper";

const state = {
    statusList: [],
    proposalStatusList: [],
    dealStatusList: []
};

const getters = {
    getStatus: state => state.statusList,
    getProposalStatus: state => state.proposalStatusList,
    getDealStatus: state => state.dealStatusList

};
const mutations = {
    STATUS_INFO(state, data) {
        state.statusList = data;
    },
    PROPOSAL_STATUS_INFO(state, data){
        state.proposalStatusList = data;
    },
    DEAL_STATUS_INFO(state, data){
        state.dealStatusList = data
    }
};

const actions = {
    getStatus({commit}) {
        axiosGet(`admin/app/statuses`).then(response => {
            commit('STATUS_INFO', response.data)
        }).catch((error) => console.log(error));
    },
    getProposalStatus({commit}){
        axiosGet(`crm/statuses?type=proposal`).then(response => {
            commit('PROPOSAL_STATUS_INFO', response.data)
        }).catch((error) => console.log(error));
    },
    getDealStatus({commit}){
        axiosGet(`crm/statuses?type=deal`).then(response => {
            commit('DEAL_STATUS_INFO', response.data)
        }).catch((error) => console.log(error));
    }
};




export default {
    state,
    getters,
    actions,
    mutations
}
