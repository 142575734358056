<template>
	<app-modal modal-alignment="top" modal-body-class="quick-view-modal" modal-id="quickViewModal"
	           modal-size="fullscreen" @close-modal="closeModal">
		<template slot="header">
			<div class="d-flex align-items-center justify-content-between w-100">
				<div>
					<a :class="{ disabled: noMorePrevious }"
					   href=""
					   @click.prevent="Navigation('previous')">
						<app-icon :name="'chevron-left'"></app-icon>
					</a>
					<a :class="{ disabled: noMoreNext }"
					   href=""
					   @click.prevent="Navigation('next')">
						<app-icon :name="'chevron-right'"></app-icon>
					</a>
				</div>

				<div class="d-flex align-items-center">
                    <span class="mr-3 text-secondary"
                    ><app-icon :name="'user'"></app-icon
                    ></span>
					<span class="mt-1">{{ name }}</span>
				</div>

				<button
					aria-label="Close"
					class="close outline-none m-0 p-0"
					data-dismiss="modal"
					type="button">
					<span><app-icon :name="'x'"></app-icon></span>
				</button>
			</div>
		</template>

		<app-overlay-loader v-if="!loader"/>
		<template v-else slot="body">
			<div class="row">
				<div class="col-sm-12 col-md-6">
					<app-breadcrumb
						:directory="$t('contact')"
						:icon="'users'"
						:page-title="name"/>
				</div>
				<div class="col-sm-12 col-md-6">
					<div class="float-md-right mb-3 mb-sm-3 mb-md-0">
						<button
							class="btn btn-primary btn-with-shadow"
							data-toggle="modal"
							type="button"
							@click="openDealModal()">
							{{ $t("add_deal") }}
						</button>
					</div>
				</div>
			</div>

      <component is="app-user-info-details"
                 :user-info-sync-url="`crm/persons/profile-picture/${formData.id}`"
                 :user-info-data="formData"
                 component-type="person"
                 @update-request="updateRequest"/>

			<div class="row">
				<div class="col-12 col-sm-6 col-md-4">

					<app-details-information
						class="mb-primary"
						:details-data="formData"
						:details-sync-url="`crm/persons/${this.formData.id}`"
						@update-request="updateRequest"/>


					<!-- <component is="app-details-job-title"
					           class="mb-primary"
					           v-if="isComponent"
					           :job-title-data="formData.organizations"
					           :job-title-url-sync="`crm/persons/organizations/sync/${this.formData.id}`"
					           :data-list="organizationList"
					           component-type="person"
					           @update-request="updateRequest"/> -->

          <app-details-contact-info
            :contact-info-data="formData"
            :contact-info-sync-url="`crm/persons/contact/sync/${formData.id}`"
            :person-id="formData.id"
            @update-request="updateRequest"
          />


					<app-address-details
						:address-details="formData"
						:address-update-url="`crm/persons/${formData.id}`"
						class="mb-primary"
						@update-request="updateRequest"
					/>

          <component is="app-details-deals"
                     :Deals="formData.deals"
                     :contact-list="peopleList"
                     :Id="formData.id"
                     :total-deal="formData.total_deals"
                     component-type="person"
                     class="mb-primary"
                     :quick-view="true"
                     @viewAllDeal="viewAllDeal"
          />

					<tags-person :person="formData.id"
					             :tagData="formData.tags"
					             class="mb-primary"
					/>
<!--
					<app-follower-details
						:follower-data="formData"
						:follower-sync-url="`crm/persons/followers/${formData.id}`"
						:get-follower-url="`crm/persons/${this.formData.id}/followers`"
						:people-list="peopleList"
						@viewAllFollower="viewAllFollower"
						class="mb-primary"
						@update-request="updateRequest"/>-->
				</div>

				<div class="col-12 col-sm-6 col-md-8">
					<app-tab v-if="appTapShow" :tabs="todoActivityTab" class="mb-primary" type="horizontal"/>

          <app-common-activity-show
            :Data="formData"
            :edit-url="selectedUrl"
            :activity-filter-url="`crm/person/activities/`"
            :file-note-url="`crm/person`"
            :quick-view="true"
            component-type="person"
            v-if="isComponent"
            @open-activity="editActivity"
            @open-note-modal="editNote"
          />

				</div>
			</div>
		</template>

		<app-deal-modal
			v-if="isDealModalActive"
			:pipeline-id="pipelineId"
			:pre-selected-option="{'person_id':formData.id}"
      component-type="person"
      :selectedUrlId="formData.id"
			@close-modal="closeDealModal"
		/>
	</app-modal>
</template>

<script>
import {FormMixin} from "@core/mixins/form/FormMixin.js";
import {formatDateToLocal, onlyTime, onlyTimeFromTime, textTruncate, urlGenerator} from "@app/Helpers/helpers"
import ActivityMixin from "@app/Mixins/Global/ActivityMixin";
import {collect} from "@app/Helpers/Collection";

export default {
  props: ["selectedUrl"],
	name: "PersonQuickViewModal",
	mixins: [FormMixin, ActivityMixin],
	components: {
		"tags-person": require("./Details/TagsPerson").default,
	},
	data() {
		return {
			formData: {},
			profile_picture: "",
			isDealModalActive: false,
			preLoader: false,
			name: "",
			formatDateToLocal,
			onlyTime,
			onlyTimeFromTime,
			textTruncate,
			urlGenerator,
			noMoreNext: null,
			noMorePrevious: null,
			loader: false,
			isComponent: true,
			appTapShow: false,
			personActivitiesModal: false,
			activitiesList: [],
			isfileNoteFilter: true,
			// Tab Start
			todoActivityTab: [
				{
					name: "activity",
					icon: "activity",
					component: "common-activity-tab",
					props: "",
					permission: "",
				},
				{
					name: "file",
					icon: "paperclip",
					component: "common-file-tab",
					props: "",
					permission: "",
				},
				{
					name: "note",
					icon: "file-text",
					component: "common-note-tab",
					props: "",
					permission: "",
				},
			],

		};
	},
	computed: {
		peopleList() {
			return this.$store.getters.getPerson
		},
		organizationList() {
			return this.$store.getters.getOrganization
		},
		pipelineId() {
			let allPipeline = this.$store.getters.getPipeline;
			return collect(allPipeline).first().id;
		},
	},
	methods: {
		setTabProps() {
			this.todoActivityTab.forEach((el) => {
				el["props"] = {
					id: this.formData.id,
					person: "person",
					contextable_type: "App\\Models\\CRM\\Person\\Person",
					activitySyncUrl: `crm/person/activities/sync/${this.formData.id}`,
					fileSyncUrl: `crm/person/file/sync/${this.formData.id}`,
					noteSyncUrl: `crm/person/note/sync/${this.formData.id}`,
				};
			});
		},
		Navigation(parm) {
			this.loader = false;
			if (parm == "next" && !this.noMoreNext) {
				this.axiosGet(
					`crm/person/navigation-change/${
						this.formData.id
					}${"?parm="}${parm}`
				)
					.then(response => {
						this.formData = response.data.current;
						this.noMoreNext = response.data.noMoreNext;
						this.noMorePrevious = response.data.noMorePrevious;
						this.name = this.formData.name;
						this.loader = true;
						this.setTabProps();
					})
					.catch(({error}) => {
						this.loader = false;
					});
			} else if (!this.noMorePrevious) {
				this.axiosGet(
					`crm/person/navigation-change/${
						this.formData.id
					}${"?parm="}${parm}`
				).then(response => {
					this.formData = response.data.current;
					this.noMoreNext = response.data.noMoreNext;
					this.noMorePrevious = response.data.noMorePrevious;
					this.name = this.formData.name;
					this.loader = true;
					this.setTabProps();
				})
					.catch(({error}) => {
						this.loader = false;
					});
			}
		},
		submitData() {
			this.save(this.formData);
		},

		afterError(response) {
			this.$toastr.e(response.data.message);
		},

		afterSuccess(response) {
			this.$toastr.s(response.data.message);
		},

		afterFinalResponse() {
			this.closeModal();
		},

		afterSuccessFromGetEditData(response) {
			this.formData = response.data.current;
			this.name = this.formData.name;
			this.setTabProps();
			this.appTapShow = true;
			this.dataLoaded = true;
			this.noMorePrevious = response.data.noMorePrevious;
			this.noMoreNext = response.data.noMoreNext;
			this.loader = true;
		},
		editActivity(activity) {
			this.$emit("openActivityModal", activity);
		},
		updateRequest() {
			this.isComponent = false;
			this.axiosGet(this.selectedUrl).then((response) => {
				this.formData = response.data.current;
				this.name = this.formData.name;
				this.isComponent = true;
			}).catch((error) => console.log(error))
		},

		updateDealList() {
			this.$hub.$on('deal-update-list', (value = true) => {
				if (value) {
					this.updateRequest();
				}
			})
		},
		updateTagList() {
			this.$hub.$on('tag-list', (value = true) => {
				if (value) {
					this.updateRequest();
				}
			})

		},

		closeModal(value) {
			this.$emit("close-modal", value);
		},
		viewAllDeal(personId) {
			this.$emit("viewAllDeal", personId)
		},
		viewAllFollower(followerData) {
			this.$emit("viewAllFollower", followerData)
		},
		openDealModal() {
			this.isDealModalActive = true;
			setTimeout(function () {
				$("#deal-modal").modal("show");
			});
		},
		closeDealModal() {
			this.isDealModalActive = false;
			$("#deal-modal").modal("hide");
		},
		editNote(activity) {
			this.$emit("editNote", activity)
		},
	},
	mounted() {
		this.$store.dispatch("getPipeline");
		this.$store.dispatch('getPerson');
		this.updateTagList();
		this.updateDealList();
		$("#deal-modal").on("hidden.bs.modal", () => {
			this.isDealModalActive = false;
		});
	}
};
</script>
<style scoped>
a.disabled {
	opacity: 0.5;
}

.modal-body {

}
</style>
