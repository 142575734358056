<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 pl-0 back-image" :style="'background-image: url(' + urlGenerator(bannerUrl) + ')'">
      <div class="row">
        <div class="offset-md-4 col-sm-4 col-md-4 col-lg-4">
          <div class="login-form d-flex align-items-center">
            <form
              class="sign-in-sign-up-form w-100"
              ref="form"
              data-url="admin/users/login"
            >
              <div class="text-center mb-4">
                <img :src="urlGenerator(logoUrl)" alt="" class="img-fluid logo" />
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <h6 class="text-center mb-0">{{ $t("hi", { object: $t("there") }) }}</h6>
                  <label class="text-center d-block">{{
                    $t("login_to_your_dashboard")
                  }}</label>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>{{ $t("email") }}</label>
                  <app-input
                    type="email"
                    :placeholder="$t('your_email')"
                    :required="true"
                    v-model="login.email"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>{{ $t("password") }}</label>
                  <app-input
                    type="password"
                    v-on:keyup.enter="submitData"
                    :placeholder="$t('your_password')"
                    :required="true"
                    v-model="login.password"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12">
                  <button
                    type="button"
                    class="btn btn-primary btn-block text-center"
                    @click="submitData"
                  >
                    <span class="w-100">
                      <app-submit-button-loader v-if="loading"></app-submit-button-loader>
                    </span>
                    <template v-if="!loading">{{ $t("login") }}</template>
                  </button>
                </div>
              </div>
              <div class="form-row">
                <div class="col-6"></div>
                <div class="col-6 text-right">
                  <a href="#" class="bluish-text" @click="forgetPassword">
                    <i data-feather="lock" class="pr-2" />{{ $t("forgot_password") }}?
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeMixin from "../../../../core/mixins/global/ThemeMixin";
import { companyName, copyRightText, urlGenerator } from "../../../Helpers/helpers";
import { FormSubmitMixin } from "../../../Mixins/Global/FormSubmitMixin";

export default {
  name: "Login",
  props: {
    configData: {
      type: Object,
    },
    previousUrl: {
      type: String,
    },
  },
  mixins: [FormSubmitMixin, ThemeMixin],
  data() {
    return {
      companyName,
      copyRightText,
      urlGenerator,
      login: {},
    };
  },
  computed: {
    bannerUrl() {
      return this.configData.company_banner ?? "/images/banner.jpg";
    },
    logoUrl() {
      return this.configData.company_logo ?? "/images/logo.png";
    },
  },
  methods: {
    submitData() {
      this.save(this.login);
    },
    afterSuccess({ data }) {
      window.location.href = this.previousUrl;
    },
    afterError(response) {
      this.loading = false;
      this.$toastr.e(response.data.message);
    },
    forgetPassword() {
      location.replace(urlGenerator(`users/password-reset`));
    },
  },
};
</script>
