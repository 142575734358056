<template>
  <div class="content-wrapper calendar-position-modified">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <app-breadcrumb
            :page-title="$t('list_view')"
            :directory="$t('activities')"
            :icon="'activity'"
        />
      </div>
      <div class="col-sm-12 col-md-6" v-if="$can('create_activities')">
        <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
          <button
              type="button"
              class="btn btn-primary btn-with-shadow"
              data-toggle="modal"
              @click.prevent="openModal()"
          >
            {{ $t("add_activity") }}
          </button>
        </div>
      </div>
    </div>

    <app-table
        :id="activityModalId"
        @getFilteredValues="filteredValues"
        :options="options"
        @action="getAction"
    />

    <component
        :is="'app-activity-modal'"
        v-if="isActivityModalActive"
        :table-id="activityModalId"
        :selected-url="editedUrl"
        @close-modal="closeModal"
    />

    <app-confirmation-modal
        v-if="confirmationModalActive"
        modal-id="activity-delete-modal"
        @confirmed="confirmed"
        @cancelled="cancelled"
    />
  </div>
</template>

<script>
import {FormMixin} from "@core/mixins/form/FormMixin";
import CoreLibrary from "@core/helpers/CoreLibrary.js";
import {mapGetters} from "vuex";
import {owner} from "@app/Mixins/Global/FilterMixins";
import {formatDateToLocal} from "@app/Helpers/helpers";
import {urlGenerator} from "@app/Helpers/helpers";
import moment from 'moment';

export default {
  name: "ListView",
  mixins: [FormMixin, owner],
  extends: CoreLibrary,
  data() {
    return {
      isActivityModalActive: false,
      activityModalId: "activity-modal",
      confirmationModalActive: false,
      activity: "",
      editedUrl: "",
      activityTypeOption: [],
      dealOptions: [],
      orgOptions: [],
      personOptions: [],
      dealId: new Set(),
      personId: new Set(),
      orgId: new Set(),
      options: {
        name: this.$t("activity_table"),
        url: "crm/activities",
        showHeader: true,
        columns: [
          {
            title: this.$t("done"),
            type: "component",
            key: "done",
            isVisible: true,
            componentName: "app-activity-done",
          },
          {
          title: this.$t("uuid"),
          type: "custom-html",
          key: "uuid",
          modifier: (value,row) => {
            return `<span>${
              row.contextable.contact_person[0]?.uuid
            }</span>`;
          },
        },
        {
          title:  this.$t("person"),
          type: "custom-html",
          key: "customer_name",
          modifier: (value,row) => {
            return `<span>${
              row.contextable.contact_person[0]?.name
            } </span>`;
          },
        },
          {
            title: this.$t("activity"),
            type: "object",
            key: "activity_type",
            modifier: (value, row) => {
              return value.name ?? null;
            },
          },
          {
            title: this.$t("title"),
            type: "text",
            key: "title",
          },
          {
            title: this.$t("related_to"),
            type: "custom-html",
            key: "contextable",
            modifier: (value, row) => {
              let arr = row.contextable_type.split("\\");
              let url = value['title'] ? `crm/deal/${value.id}/details` : `crm/${arr[arr.length - 1].toLowerCase()}s/${value.id}/edit`;
              let text = value['title'] ? value.title : value.name;
              return `<span class="badge badge-pill badge-${
                  row.contextable_type == 'App\\Models\\CRM\\Person\\Person'
                            ? 'purple'
                            : row.contextable_type == 'App\\Models\\CRM\\Deal\\Deal'
                            ? 'success'
                            : 'warning'}"
                            >${arr[arr.length - 1].toLowerCase()}</span>
                    <a class="mb-1 d-flex"  href="${urlGenerator(url)}" target="_new">
                        <span class="link-list">${text}</span>

                            <span class="text-muted mt-1">
                            <i
                                data-feather="external-link"
                            /></i>
                            </span>
              </a>`;
            },
          },

          {
            title: this.$t("owner"),
            type: "object",
            key: "created_by",
            modifier: (value, row) => {
              return value ? value.full_name : " ";
            },
          },
          {
            title: this.$t("starting_schedule"),
            type: "custom-html",
            key: "started_at",
            modifier: (date, row) =>
            date ? `<span ${moment(date+'T'+row.start_time).isBetween(moment().subtract(1, 'hours'),moment())?'class="text-danger"': ' '}>${formatDateToLocal(date, true, row.start_time)}</span> ${(moment().isAfter(moment(row.started_at+'T'+row.start_time))&& row.status_id!== 10) ?'<span class="badge badge-pill badge-warning">Missed</span>':''}` : "-",
          },
          {
            title: this.$t("created_at"),
            type: "custom-html",
            key: "created_at",
            modifier: (date, row) => (date ? moment(date).format('YYYY-MM-DD') : "-"),
          },
          {
            title: "Action",
            type: "action",
            key: "invoice",
            isVisible: true,
          },
        ],
        filters: [
          {
            title: this.$t("deal"),
            type: "dropdown-menu-filter",
            key: "context",
            initValue: 1,
            listValueField: "name",
            option: [
              {
                id: 1,
                name: "Any",
              },
              {
                id: 2,
                name: "Deal",
              },
              {
                id: 3,
                name: "Person",
              },
              // {
              //   id: 4,
              //   name: "Organization",
              // },
            ],
          },
          {
            title: this.$t("done_activity"),
            type: "radio",
            key: "done_activity",
            header: {
              "title": "Do you want to see done activity?",
              "description": ""
            },
            option: [
              {
                id: 1,
                name: "Show done activity"
              },
            ],
            listValueField: 'name'
          },
          {
            title: this.$t("activity"),
            type: "checkbox",
            key: "activity",
            option: [],
          },
          {
            title: this.$t("schedule"),
            type: "range-picker",
            key: "schedule",
            option: ["today", "thisMonth", "last7Days", "thisYear"],
          },
          {
            title: this.$t("owner"),
            type: "checkbox",
            key: "owner_is",
            option: [],
          },
        ],
        showSearch: true,
        showFilter: true,
        paginationType: "pagination",
        responsive: true,
        rowLimit: 10,
        showAction: this.$can('update_activities') || this.$can('delete_activities') ? true : false,
        orderBy: "desc",
        actionType: "dropdown",
        actions: [
          {
            title: this.$t("edit"),
            icon: "edit",
            type: "modal",
            component: "app-activity-modal",
            modalId: "activity-modal",
            url: "",
            modifier: () => this.$can('update_activities')
          },
          {
            title: this.$t("delete"),
            icon: "trash",
            type: "modal",
            component: "app-confirmation-modal",
            modalId: "activity-delete-modal",
            url: "",
            modifier: () => this.$can('delete_activities')
          },
        ],
      },
    };
  },
  created() {
  },
  computed: {
    ...mapGetters({
      personList: "getPerson",
      ownerList: "getOwner",
      organizationList: "getOrganization",
      dealList: "getDeal",
    }),
  },
  mounted() {
    this.$store.dispatch("getOwner"),
        this.$store.dispatch("getPerson"),
        this.$store.dispatch("getOrganization"),
        this.$store.dispatch("getDeal");
    let instance = this;
    $("#activity-modal").on("hidden.bs.modal", function (e) {
      instance.isActivityModalActive = false;
      instance.editedUrl = "";
    });

    $("#activity-delete-modal").on("hidden.bs.modal", function (e) {
      instance.confirmationModalActive = false;
    });
    this.getAllActivities();
    //this.setFilterOption();
  },

  methods: {
    filteredValues(values) {
      this.options.url = "";
      let urlParam;
      if (values) {
        let context = "&context=";
        if (values["deal"]) {
          context += "deal:" + values.deal.replace(/\,/g, "+") + ",";
          delete values["deal"];
        }
        if (values["organization"]) {
          context +=
              "organization:" + values.organization.replace(/\,/g, "+") + ",";
          delete values["organization"];
        }
        if (values["person"]) {
          context += "person:" + values.person.replace(/\,/g, "+") + ",";
          delete values["person"];
        }
        if (!values['done_activity']) {
          values['done_activity'] = null
        }
        context = context.endsWith(",") ? context.slice(0, -1) : "";
        urlParam = new URLSearchParams(values).toString();
        urlParam = urlParam + context;
      }
      if (urlParam) {
        this.options.url = "crm/activities?" + urlParam;
      }
    },
    setFilterOption() {
      this.axiosGet("crm/activities").then((res) => {
        res.data.data.forEach((el) => {
          if (el.contextable_type) {
            let arr = el.contextable_type.split("\\");
            if (arr[arr.length - 1] == "Deal") {
              if (!this.dealId.has(el.contextable.id)) {
                this.dealId.add(el.contextable.id);
                this.dealOptions.push(el.contextable);
              }
            } else if (arr[arr.length - 1] == "Organization") {
              if (!this.orgId.has(el.contextable.id)) {
                this.orgId.add(el.contextable.id);
                this.orgOptions.push(el.contextable);
              }
            } else if (arr[arr.length - 1] == "Person") {
              if (!this.personId.has(el.contextable.id)) {
                this.personId.add(el.contextable.id);
                this.personOptions.push(el.contextable);
              }
            }
          }
        });
        this.setDealOptions(this.dealOptions);
        this.setOrgOptions(this.orgOptions);
        this.setPersonOptions(this.personOptions);
      });
    },
    setDealOptions(Value) {
      let deal = this.options.filters.find(
          (elsement) => elsement.title === this.$t("deal")
      );
      deal.option = Value.map((deals) => {
        return {
          id: deals.id,
          value: deals.title,
        };
      });
    },
    setOrgOptions(Value) {
      let organization = this.options.filters.find(
          (elsement) => elsement.title === this.$t("organizations")
      );
      organization.option = Value.map((organizations) => {
        return {
          id: organizations.id,
          name: organizations.name,
        };
      });
    },
    setPersonOptions(Value) {
      let person = this.options.filters.find(
          (elsement) => elsement.title === this.$t("person")
      );
      person.option = Value.map((persons) => {
        return {
          id: persons.id,
          name: persons.name,
        };
      });
    },
    getAllActivities() {
      this.axiosGet("crm/activity_types").then((response) => {
        let activityType = this.options.filters.find(
            (elsement) => elsement.title === this.$t("activity")
        );
        activityType.option = response.data.data.map((activities) => {
          return {
            id: activities.id,
            value: activities.name,
          };
        });
      });
    },
    getAction(rowData, actionObj, active) {
      let instance = this;
      if (actionObj.title == this.$t("edit")) {
        instance.editedUrl = `crm/activities/${rowData.id}`;
        instance.isActivityModalActive = true;
      } else if (actionObj.title == this.$t("delete")) {
        instance.activity = rowData.id;
        instance.confirmationModalActive = true;
      }
    },
    confirmed() {
      let instance = this,
          url = `crm/activities/${this.activity}`;
      this.axiosDelete(url)
          .then((response) => {
            instance.$toastr.s(response.data.message);
            instance.$hub.$emit("reload-" + this.activityModalId);
          })
          .catch(({error}) => {
            //trigger after error
          })
          .finally(() => {
            instance.cancelled();
          });
    },

    cancelled() {
      let instance = this;
      instance.confirmationModalActive = false;
    },
    openModal() {
      let instance = this;
      instance.isActivityModalActive = true;
      $("#activity-modal").modal("show");
    },

    closeModal() {
      let instance = this;
      instance.isActivityModalActive = false;
      this.editedUrl = "";
      $("#activity-modal").modal("hide");
    },
  },
};
</script>

<style>
.link-list {
  white-space: nowrap !important;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.purple {
  color: #ffffff;
  background-color: #964ed8;
}

.purple:hover {
  color: #ffffff;
  background-color: #964EED;
}
</style>


