import {collection,urlGenerator} from "../../Helpers/helpers";


export default {
  data() {
    return {
      commonColumn: [
        {
          title: this.$t("name"),
          type: "link",
          key: "name",
          modifier: (value, row) => {
            return `crm/organizations/${row.id}/edit`;
          },
        },
        {
          title: this.$t("lead_groups"),
          type: "custom-html",
          key: "contact_type",
          modifier: (value, row) => {
            return value
              ? `<span class="badge badge-pill badge-${value.class ?? "secondary"}">${
                value.name
              }</span>`
              : "-";
          },
        },
        {
          title: this.$t("person"),
          type: "custom-html",
          key: "persons",
          modifier: function (persons) {
            let personsList = persons.length ? collection(persons).get("name", "id") : [];
            let returnResult = ``;

            personsList.map((el, index) => {
              if (index < 2) {
                let personDetailsUrl = urlGenerator(`crm/persons/${el.id}/edit`);
                returnResult += `
                                <a class="mb-1 d-flex" href="${personDetailsUrl}" target="_blank">
                                <span class="link-list">${el.name}</span>

                                <span class="text-muted mt-1">
                                <i
                                    data-feather="external-link"
                                /></i>
                                </span>
                                </a>
                                `;
              }
            });
            if (personsList.length > 2) {
              let moreCount = personsList.length - 2;
              returnResult += `<small><b>+${moreCount} more</b><small>`;
            }
            return personsList.length ? returnResult : "-";
          },
        },
        {
          title: this.$t("address"),
          type: "component",
          isVisible: true,
          componentName: "app-common-address",
        },
        {
          title: this.$t("closed_deal"),
          type: "text",
          key: "closed_deals",
        },
        {
          title: this.$t("opened_deal"),
          type: "text",
          key: "open_deals",
        },
        {
          title: this.$t("owner"),
          type: "object",
          key: "owner",
          modifier: (value, row) => {
            return value ? value.full_name : "";
          },
        },
        {
          title: this.$t("tags"),
          type: "component",
          key: "tags",
          isVisible: true,
          componentName: "tags-type-column",
        },
        {
          title: "Action",
          type: "action",
          key: "invoice",
          isVisible: true,
        },
      ],

      options: {
        name: "OrganizationTable",
        url: "crm/organizations",
        showHeader: true,
        columns: [],
        filters: [
          {
            title: this.$t("created_date"),
            type: "range-picker",
            key: "date",
            option: ["today", "thisMonth", "last7Days", "thisYear"],
          },
          {
            title: this.$t("owner"),
            type: "checkbox",
            key: "owner_is",
            option: [],
          },

          {
            title: this.$t("lead_group"),
            type: "checkbox",
            key: "contact_type",
            option: [],
          },
          {
            title: this.$t("person"),
            type: "multi-select-filter",
            key: "person",
            option: [],
          },
          {
            title: this.$t("tag"),
            type: "multi-select-filter",
            key: "tags",
            option: [],
          },
        ],
        showSearch: true,
        showFilter: true,
        paginationType: "pagination",
        responsive: true,
        rowLimit: 10,
        showAction: true,
        orderBy: "desc",
        actionType: "dropdown",
        actions: [
          {
            title: this.$t("quick_view"),
            icon: "zap",
            type: "modal",
            component: "app-organization-quick-view",
            modalId: "quickViewModal",
            url: "",
          },
          {
            title: this.$t("edit"),
            icon: "edit",
            type: "modal",
            component: "app-organization-modal",
            modalId: "organization-modal",
            url: "",
          },
          {
            title: this.$t("delete"),
            icon: "trash",
            type: "modal",
            component: "app-confirmation-modal",
            modalId: "organization-delete-modal",
            url: "",
          },
        ],
      }
    }
  }
}
