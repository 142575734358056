<template>
  <app-modal modal-body-class="quick-view-modal" modal-id="quickViewModal" modal-size="fullscreen" modal-alignment="top"
             @close-modal="closeModal">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div>
          <a href=""
             @click.prevent="Navigation('previous')"
             :class="{ disabled: noMorePrevious }">
            <app-icon :name="'chevron-left'"></app-icon>
          </a>
          <a href=""
             @click.prevent="Navigation('next')"
             :class="{ disabled: noMoreNext }">
            <app-icon :name="'chevron-right'"></app-icon>
          </a>
        </div>

        <div class="d-flex align-items-center">
                    <span class="mr-3 text-secondary">
                      <app-icon :name="'briefcase'"></app-icon>
                    </span>
          <span class="mt-1">{{ name }}</span>
        </div>

        <button type="button"
                class="close outline-none m-0 p-0"
                data-dismiss="modal"
                aria-label="Close">
          <span><app-icon :name="'x'"></app-icon></span>
        </button>
      </div>
    </template>
    <app-overlay-loader v-if="!loader"/>
    <template v-else slot="body">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <app-breadcrumb
              :page-title="name"
              :directory="$t('contact')"
              :icon="'users'"/>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
            <button
                type="button"
                class="btn btn-primary btn-with-shadow"
                data-toggle="modal"
                @click="openDealModal()">
              {{ $t("add_deal") }}
            </button>
          </div>
        </div>
      </div>

      <component is="app-user-info-details"
                 :user-info-sync-url="`crm/organizations/profile-picture/${formData.id}`"
                 :user-info-data="formData"
                 component-type="org"
                 @update-request="updateRequest"/>

      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">

          <app-details-information
            class="mb-primary"
            :details-data="formData"
            :details-sync-url="`crm/organizations/${this.formData.id}`"
            @update-request="updateRequest"/>

	        <component is="app-details-job-title"
	                   class="mb-primary"
	                   v-if="isComponent"
	                   :job-title-data="formData.persons"
	                   :job-title-url-sync="`crm/organizations/sync/${this.formData.id}`"
	                   component-type="organization"
	                   :data-list="peopleList"
	                   @update-request="updateRequest"/>

	        <app-address-details
		        class="mb-primary"
		        :address-details="formData"
		        :address-update-url="`crm/organizations/${formData.id}`"
		        @update-request="updateRequest"
	        />

          <component is="app-details-deals"
                     :Deals="formData.deals"
                     :contact-list="peopleList"
                     :Id="formData.id"
                     :quick-view="true"
                     :total-deal="formData.total_deals"
                     component-type="org"
                     class="mb-primary"
                     @viewAllDeal="viewAllDeal"
          />

          <tags-organization class="mb-primary"
                             :tagData="formData.tags"
                             :organization="formData.id"
          />

	        <app-follower-details
		        class="mb-primary"
		        :follower-data="formData"
		        :follower-sync-url="`crm/organizations/followers/${formData.id}`"
		        :get-follower-url="`crm/organizations/${this.formData.id}/followers`"
		        :people-list="peopleList"
		        @viewAllFollower="viewAllFollower"
		        @update-request="updateRequest"/>


        </div>

        <div class="col-12 col-sm-6 col-md-8">
          <app-tab class="mb-primary" type="horizontal" :tabs="todoActivityTab" v-if="appTapShow"/>

          <app-common-activity-show
            :Data="formData"
            :participant-data="participants"
            :collaborator-data="collaborators"
            :edit-url="selectedUrl"
            :activity-filter-url="`crm/organization/activities/`"
            :file-note-url="`crm/organization`"
            :quick-view="true"
            component-type="org"
            v-if="isComponent"
            @open-activity="editActivity"
            @open-note-modal="editNote"
          />

        </div>
      </div>
    </template>
    <app-deal-modal
        v-if="isDealModalActive"
        :type="'organization'"
        :pre-selected-option="{'organization_id':formData.id}"
        :personList="peopleList"
        component-type="org"
        :selectedUrlId="formData.id"
        @close-modal="closeDealModal"
    />

  </app-modal>
</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin.js";
import {formatDateToLocal, onlyTimeFromTime, onlyTime, textTruncate,urlGenerator} from "@app/Helpers/helpers";
import ActivityMixin from "@app/Mixins/Global/ActivityMixin";
import {collect} from "@app/Helpers/Collection";

export default {
  props: ["selectedUrl"],
  name: "OrganizationQuickViewModal",
  mixins: [FormMixin, ActivityMixin],
  data() {
    return {
      urlGenerator,
      formData: {},
      profile_picture: "",
      isDealModalActive: false,
      preLoader: false,
      name: "",
      textTruncate,
      formatDateToLocal,
      onlyTime,
      onlyTimeFromTime,
      noMoreNext: null,
      noMorePrevious: null,
      loader: false,
      isComponent: true,
      isfileNoteFilter: true,
      collaborators: [],
      participants: [],

      appTapShow: false,
      todoActivityTab: [
        {
          name: "activity",
          icon: "activity",
          component: "common-activity-tab",
          props: "",
          permission: "",
        },
        {
          name: "file",
          icon: "paperclip",
          component: "common-file-tab",
          props: "",
          permission: "",
        },
        {
          name: "note",
          icon: "file-text",
          component: "common-note-tab",
          props: "",
          permission: "",
        },
      ],
      activitiesList: [],
      activitiesFilterUrl: "crm/organization/activities/",
    };
  },
  computed: {
    peopleList() {
      return this.$store.getters.getPerson
    },
  },
  methods: {
    Navigation(parm) {
      this.loader = false;
      if (parm == "next" && !this.noMoreNext) {
        this.axiosGet(
            `crm/navigation-change/${
                this.formData.id
            }${"?parm="}${parm}`
        )
            .then(response => {
              this.formData = response.data.current;
              this.noMoreNext = response.data.noMoreNext;
              this.noMorePrevious = response.data.noMorePrevious;
              this.name = this.formData.name;
              this.loader = true;
              this.setTabProps();
              this.collaboratorParticipant();
              this.filterActivities(this.activitiesFilterUrl, "status=9");
            })
            .catch(({error}) => {
              this.loader = false;
            });
      } else if (!this.noMorePrevious) {
        this.axiosGet(
            `crm/navigation-change/${
                this.formData.id
            }${"?parm="}${parm}`
        )
            .then(response => {
              this.formData = response.data.current;
              this.noMoreNext = response.data.noMoreNext;
              this.noMorePrevious = response.data.noMorePrevious;
              this.name = this.formData.name;
              this.loader = true;
              this.setTabProps();
              this.collaboratorParticipant();
              this.filterActivities(this.activitiesFilterUrl, "status=9");
            })
            .catch(({error}) => {
              this.loader = false;
            });
      }
    },
    submitData() {
      this.save(this.formData);
    },

    afterError(response) {
      this.$toastr.e(response.data.message);
    },

    afterSuccess(response) {
      this.$toastr.s(response.data.message);
    },

    afterFinalResponse() {
      this.closeModal();
    },

    afterSuccessFromGetEditData(response) {
      this.formData = response.data.current;
      this.name = this.formData.name;
      this.setTabProps();
      this.appTapShow = true;
      this.dataLoaded = true;
      this.noMorePrevious = response.data.noMorePrevious;
      this.noMoreNext = response.data.noMoreNext;
      this.loader = true;
    },
      setTabProps(){
          this.todoActivityTab.forEach((el) => {
              el["props"] = {
                  id: this.formData.id,
                  organization: "organization",
                  contextable_type: "App\\Models\\CRM\\Organization\\Organization",
                  activitySyncUrl: `crm/organization/activities/sync/${this.formData.id}`,
                  fileSyncUrl: `crm/organization/file/sync/${this.formData.id}`,
                  noteSyncUrl: `crm/organization/note/sync/${this.formData.id}`,
              };
          });
      },

    editActivity(activity) {
      this.$emit("openActivityModal", activity);
    },
    updateRequest() {
      this.isComponent = false
      this.axiosGet(this.selectedUrl).then((response) => {
        this.formData = response.data.current
        this.name = this.formData.name;
        this.isComponent = true
      }).catch((error) => console.log(error))
    },
    updateDealList() {
      this.$hub.$on('deal-update-list', (value = true) => {
        if (value) {
          this.updateRequest();
        }
      })
    },

    updateTagList() {
      this.$hub.$on('tag-list', (value = true) => {
        if (value) {
          this.updateRequest();
        }
      })
    },
    editNote(activity) {
      this.$emit("editNote", activity)
    },
    closeModal(value) {
      this.$emit("close-modal", value);
    },
    openDealModal() {
      this.isDealModalActive = true;
      setTimeout(function () {
        $("#deal-modal").modal("show");
      });
    },
    closeDealModal() {
      this.updateRequest();
      this.isDealModalActive = false;
      $("#deal-modal").modal("hide");
    },
    viewAllDeal(organizationId) {
      this.$emit("viewAllDeal", organizationId)
    },
    viewAllFollower(followerData) {
      this.$emit("viewAllFollower", followerData)
    },
  },
  mounted() {
    this.$store.dispatch('getPerson');
    this.$store.dispatch("getOrganization");
    this.updateTagList();
    this.updateDealList();
    $("#deal-modal").on("hidden.bs.modal", () => {
      this.isDealModalActive = false;
    });
  }
};
</script>

<style scoped>
a.disabled {
  opacity: 0.5;
}
</style>
