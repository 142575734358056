<template>
  <div class="content-wrapper calendar-position-modified">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <app-breadcrumb
          :page-title="$t('proposal_list')"
          :directory="[$t('proposals'), $t('list')]"
          :icon="'hexagon'"
        />
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
          <button
            type="button"
            class="btn btn-primary btn-with-shadow"
            data-toggle="modal"
            @click="sendProposal()"
          >
            {{ $t("new_proposal") }}
          </button>
        </div>
      </div>
    </div>

    <app-table :id="tableId" :options="options" @action="getAction" />

    <app-check-email-deliver
      v-if="isCheckMailModalActive"
      :header-title="$t('send_proposal')"
      @close-modal="closeModalCheckMail"
    />

    <app-send-proposal-modal
      v-if="isSendProposalModalActive"
      :table-id="tableId"
      :selected-url="selectUrl"
      @close-modal="closeModal"
    />

    <app-confirmation-modal
      v-if="confirmationModalActive"
      modal-id="proposal-delete-modal"
      @confirmed="confirmed"
      @cancelled="cancelled"
    />

    <app-proposal-preview-modal
      :content="selectedProposalContent"
      :title="selectProposalTitle"
      modalId="proposal-preview"
      @close="isPreviewModalActive = false"
      v-if="isPreviewModalActive"
    />
  </div>
</template>

<script>
import CoreLibrary from "@core/helpers/CoreLibrary.js";
import { formatDateToLocal, collection, urlGenerator } from "@app/Helpers/helpers";
import { owner, proposalStatus, tag } from "@app/Mixins/Global/FilterMixins";
import { api } from "@app/Helpers/api";
import { collect } from "@app/Helpers/Collection";
import { mapGetters } from "vuex";

export default {
  name: "ListView",
  extends: CoreLibrary,
  mixins: [proposalStatus, owner, tag],
  data() {
    return {
      urlGenerator,
      tableId: "send-proposal-modal",
      confirmationModalActive: false,
      isSendProposalModalActive: false,
      isPreviewModalActive: false,
      isCheckMailModalActive: false,
      selectedProposalContent: "",
      selectProposalTitle: "",
      proposalData: {},
      proposal: "",
      selectedUrl: "",
      selectUrl: "",
      copyUrl: "",
      sentStatusId: null,
      options: {
        name: this.$t("proposal_table"),
        url: "crm/proposals",
        showHeader: true,
        columns: [
          {
            title: this.$t("subject"),
            type: "button",
            key: "subject",
            className: "btn text-left text-primary pl-0",
            // icon: 'check',
            label: "",
            modifier: (value, row) => {
              return row.subject;
            },
          },
          {
            title: this.$t("status"),
            type: "custom-html",
            key: "status",
            isVisible: true,
            modifier: (status) => {
              return `<span class="badge badge-pill badge-${status.class}">
                                    ${status.translated_name}
                                </span>`;
            },
          },
          {
            title: this.$t("created_date"),
            type: "custom-html",
            key: "created_at",
            isVisible: true,
            modifier: (date) => formatDateToLocal(date),
          },
          {
            title: this.$t("deals"),
            type: "object",
            key: "deal",
            modifier: (value, row) => {
              return value ? value.title : "";
            },
          },
          {
            title: this.$t("accepted"),
            type: "component",
            componentName: "app-proposal-toggle-button",
          },
          {
            title: this.$t("owner"),
            type: "object",
            key: "owner",
            modifier: (value, row) => {
              return value?.full_name;
            },
          },
          {
            title: this.$t("tags"),
            type: "component",
            key: "tags",
            componentName: "tags-type-column",
          },
          {
            title: "Action",
            type: "action",
            key: "invoice",
            isVisible: true,
          },
        ],
        filters: [
          {
            title: this.$t("owner"),
            type: "checkbox",
            key: "owner_is",
            option: [],
          },
          {
            title: this.$t("status"),
            type: "checkbox",
            key: "status",
            option: [],
          },
          {
            title: "Created date",
            type: "range-picker",
            key: "date",
            option: ["today", "thisMonth", "last7Days", "thisYear"],
          },
          {
            title: "Proposal have deal",
            type: "radio",
            key: "proposal_with_deal",
            header: {
              title: "Want to filter your list?",
              description:
                "You can filter your data table which are created based on deal",
            },
            option: [
              {
                id: 1,
                name: "Have deal",
              },
              {
                id: 2,
                name: "Don't have deal",
              },
            ],
            listValueField: "name",
          },
          {
            title: this.$t("tag"),
            type: "multi-select-filter",
            key: "tags",
            option: [],
          },
        ],
        showSearch: true,
        showFilter: true,
        paginationType: "pagination",
        responsive: true,
        rowLimit: 10,
        showAction: true,
        orderBy: "desc",
        actionType: "dropdown",
        actions: [
          {
            title: this.$t("send"),
            icon: "send",
            type: "modal",
            component: "app-send-proposal-modal",
            modalId: "send-proposal-modal",
            modifier: (row) => {
              return row.status.name === "status_draft" ? true : false;
            },
          },
          {
            title: this.$t("duplicate"),
            icon: "copy",
            type: "page",
          },
          {
            title: this.$t("edit"),
            icon: "edit",
            type: "page",
            component: "app-send-proposals",
            modalId: "",
            url: "",
          },
          {
            title: this.$t("delete"),
            icon: "trash",
            type: "modal",
            component: "app-confirmation-modal",
            modalId: "proposal-delete-modal",
            url: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["checkEmailDelivery"]),
  },
  created() {
    this.getDealValue();
    this.$store.dispatch("checkEmailDelivery");
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    getAction(rowData, actionObj, active) {
      if (actionObj.title == this.$t("edit")) {
        this.selectedUrl = urlGenerator(`crm/proposals/${rowData.id}/edit`);
        window.open(this.selectedUrl, "_self");
      } else if (actionObj.title == this.$t("delete")) {
        this.proposal = rowData.id;
        this.confirmationModalActive = true;
      } else if (actionObj.title == this.$t("send")) {
        if (this.checkEmailDelivery != 1) {
          this.isCheckMailModalActive = true;
        } else {
          if (rowData.deal && rowData.deal.person && rowData.deal.person.email[0]) {
            this.directSendProposal(rowData);
          } else {
            this.selectUrl = `crm/proposals/${rowData.id}`;
            this.openModal();
          }
        }
      } else if (actionObj.title == this.$t("duplicate")) {
        this.copyUrl = urlGenerator(`crm/proposals/${rowData.id}/copy`);
        window.open(this.copyUrl, "_self");
      } else if (actionObj.title == this.$t("subject")) {
        this.selectedProposalContent = rowData.content;
        this.selectProposalTitle = rowData.subject;
        this.isPreviewModalActive = true;
      }
    },

    directSendProposal(proposal) {
      this.proposalData.url = "crm/send-proposal";
      this.proposalData.data = {
        custom_content: proposal.content,
        status_id: this.sentStatusId,
        deal_id: proposal.deal_id,
        id: proposal.id,
        subject: proposal.subject,
      };
      this.axiosPost(this.proposalData)
        .then((response) => {
          this.$toastr.s(response.data.message);
          this.$hub.$emit("reload-" + this.tableId);
        })
        .catch((error) => {
          this.$toastr.e(error.response.data.message);
        })
        .finally(() => {});
    },
    getStatus() {
      api
        .route("crm/statuses")
        .params({ name: "status_sent", type: "proposal" })
        .get("id")
        .then((res) => {
          this.sentStatusId = collect(res).first().id;
        });
    },
    confirmed() {
      let url = `crm/proposals/${this.proposal}`;
      this.axiosDelete(url)
        .then((response) => {
          this.$toastr.s(response.data.message);
          this.$hub.$emit("reload-" + this.tableId);
        })
        .catch(({ error }) => {
          //trigger after error
        })
        .finally(() => {
          this.cancelled();
        });
    },
    cancelled() {
      this.confirmationModalActive = false;
    },

    openModal() {
      this.isSendProposalModalActive = true;
    },

    closeModal() {
      this.isSendProposalModalActive = false;
      $("#send-proposal-modal").modal("hide");
    },

    sendProposal() {
      if (this.checkEmailDelivery != 1) this.isCheckMailModalActive = true;
      else window.open(urlGenerator(`crm/proposals/create`), "_self");
    },
    closeModalCheckMail() {
      this.isCheckMailModalActive = false;
      $("#check-email-modal").modal("hide");
    },

    getDealValue() {
      this.axiosGet("crm/deal-value").then((response) => {
        this.options.filters.push({
          title: this.$t("deal_value"),
          type: "range-filter",
          key: "deal-value",
          minTitle: this.$t("minimum_value"),
          maxTitle: this.$t("maximum_value"),
          maxRange: response.data.max_deal_value,
          minRange:
            response.data.min_deal_value < response.data.max_deal_value
              ? response.data.min_deal_value
              : 0,
        });
      });
    },
  },
};
</script>
