<template>
    <div class="card border-0 card-with-shadow">
        <div class="card-header d-flex align-items-center justify-content-between p-primary bg-transparent">
            <h5 class="card-title text-muted m-0">{{ $t('tags') }}</h5>
            <div v-if="dealStatus">
                <span v-if="isTagsEditable">
                    <a  href="#" class="text-muted" @click.prevent="showEditableTags">
                        <app-icon stroke-width="1" name="x-square"/>
                    </a>
                    <a href="#" class="text-muted" @click.prevent="showEditableTags">
                        <app-icon stroke-width="1" name="check-square"/>
                    </a>
                </span>
                <a v-else href="#" class="text-muted" @click.prevent="showEditableTags">
                    <app-icon stroke-width="1" name="edit"/>
                </a>
            </div>
        </div>
        <div class="card-body">
            <app-tags 
                :tag-list="tagData.tags"
                :editable="isTagsEditable"
                :taggable-id="dealId"
                post-url='crm/deal/tags/'
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "DealTags",
        props:['tagData', 'dealId'],
        data() {
            return {
                isTagsEditable: false,
            }
        },
        computed:{
            dealStatus(){
                return (this.tagData.status.name == 'status_open' && this.tagData.status.type == 'deal');
            },
        },
        methods: {
            showEditableTags() {
                this.isTagsEditable = !this.isTagsEditable;
            },

        }
    }
</script>
