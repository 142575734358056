<template>
  <form class="mb-0" ref="form" :data-url="props.activitySyncUrl">
    <div class="mb-primary">
      <div class="form-group">
        <div class="form-row">
          <label class="mb-0 col-sm-2 d-flex align-items-center">
            {{ $t('activity') }}
          </label>
          <div class="col-sm-10">
            <app-input type="radio-buttons"
                       :list="activityTypeList"
                       v-model="activity_type_id"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-row">
          <label class="mb-0 col-sm-2 d-flex align-items-center">
            {{ $t('title') }}
          </label>
          <div class="col-sm-10">
            <app-input type="text"
                       :placeholder="$t('enter_title')"
                       :required="true"
                       v-model="formData.title"/>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-row">
          <label class="mb-0 col-sm-2 d-flex mt-2">{{ $t('description') }}</label>
          <div class="col-sm-10">
            <app-input
                type="textarea"
                :text-area-rows="5"
                :placeholder="$t('description_here')"
                v-model="formData.description"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-row">
          <label class="mb-0 col-sm-2 d-flex align-items-center">
            {{ $t('set_schedule') }}
          </label>
          <div class="col-sm-10">
              <div class="row mb-1">
                <div class="col-lg-7 pr-lg-0">
                  <app-input type="date"
                             :placeholder="$t('start_date')"
                             v-model="formData.started_at"
                             @input="setEndDateAsStartDate"
                             />
                </div>
                <div class="col-lg-5 pl-lg-0 time-picker-dd-pos">
                  <app-input type="time"
                             v-model="startTime"
                             @input="setStart($event)"
                  />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-lg-7 pr-lg-0">
                  <app-input type="date"
                             :min-date="formData.started_at"
                             :placeholder="$t('end_date')"
                             v-model="formData.ended_at"/>
                </div>
                <div class="col-lg-5 pl-lg-0 time-picker-dd-pos">
                  <app-input type="time"
                             v-model="endTime"
                  />
                </div>
              </div> -->
          </div>
        </div>
      </div>


      <!-- Participants input -->
      <!-- <div class="form-group">
        <div class="form-row">
          <label class="mb-0 col-sm-2 d-flex align-items-center">{{ $t('participants') }}</label>
          <div class="col-sm-10">
            <app-input
                type="multi-select"
                :list="personList"
                list-value-field="name"
                v-model="formData.person_id"
                :is-animated-dropdown="true"
            />
          </div>
        </div>
      </div> -->
      <!-- end of Participants input -->

      <!-- Collaborators input -->
      <div class="form-group">
        <div class="form-row">
          <label class="mb-0 col-sm-2 d-flex align-items-center text-break">{{ $t('collaborators') }}</label>
          <div class="col-sm-10">
            <app-input
                type="multi-select"
                :list="ownerList"
                list-value-field="full_name"
                v-model="formData.owner_id"
                :is-animated-dropdown="true"
            />
          </div>
        </div>
      </div>
      <!-- end of Collaborators input -->
      <div class="form-group d-none">
        <div class="form-row">
          <label class="mb-0 col-sm-2 d-flex align-items-center">{{
            $t("save_as_done")
            }}</label>
          <div class="mt-2 col-sm-10">
            <app-input
                    type="single-checkbox"
                    :list-value-field="$t(' ')"
                    v-model="formData.activity_done"
            />
          </div>
        </div>
      </div>

    </div>
    <div class="pt-primary px-primary border-top mx-minus-primary">
      <button type="button" class="btn btn-primary" @click.prevent="submitData">
                        <span class="w-100">
                            <app-submit-button-loader v-if="loading"></app-submit-button-loader>
                        </span>
        <template v-if="!loading">{{ $t('save') }}</template>
      </button>
    </div>
  </form>
</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin";
import {mapGetters} from 'vuex'
import { api } from "@app/Helpers/api";
import { collect } from "@app/Helpers/Collection";
import {
    formatted_time,
    time_format,
} from "@app/Helpers/helpers";

export default {
  name: "ActivityTab",
  props: ['props'],
  mixins: [FormMixin],
  data() {
    return {
      formatted_time,
      activityTypeList: [],
      loading: false,
      activity_type_id: null,
      doneActivityId: null,
      endTime: moment(new Date()).format(`${time_format()}`),
      startTime: moment(new Date())
          .subtract("15", "minutes")
          .format(`${time_format()}`),
      formData: {
        title: '',
        started_at: new Date(),
        ended_at: new Date(),
        person_id: [],
        owner_id: [],
      }
    }
  },
  computed: {
    ...mapGetters({
      ownerList: 'getOwner',
      organizationList: 'getOrganization',
      personList: 'getPerson',
    }),
  },
  created() {

  },
  methods: {
  setStart(v) {
      this.startTime = v;
      this.formData.start_time = v;
      if (
          this.formData.started_at.toDateString() ==
          this.formData.ended_at.toDateString()
      ) {
          this.endTime =
              this.convertTime12to24(v) > this.convertTime12to24(this.endTime)
                  ? this.startTime
                  : this.endTime;
      }
  },
  convertTime12to24(time12h) {
      if (formatted_time() == 24) {
          return time12h;
      }
      const [time, modifier] = time12h.split(" ");

      let [hours, minutes] = time.split(":");

      if (hours === "12") {
          hours = "00";
      }

      if (modifier === "PM") {
          hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
  },
    setEndDateAsStartDate() {

      // please no need to change this formation of date,
      // it is just use for check logic

      let s = moment(moment(this.formData.started_at).format("YYYY-MM-DD")),
        e = moment(moment(this.formData.ended_at).format("YYYY-MM-DD")),
        diff = e.diff(s, "days");

      if (diff < 0){
        this.formData.ended_at = this.formData.started_at;
      }
    },
    beforeSubmit() {
      this.loading = true;
    },
    submitData() {

      // let selectedStartDate = this.formData.started_at;
      // let selectedEndDate = this.formData.ended_at;

      let activity = this.formData;
      activity.activity_type_id = this.activity_type_id;
      activity.title = this.formData.title;
      activity.description = this.formData.description;
      activity.started_at = moment(this.formData.started_at).format('YYYY-MM-DD');
      activity.ended_at = moment(this.formData.ended_at).format('YYYY-MM-DD');
      activity.start_time = this.convertTime12to24(this.startTime);
      activity.end_time = this.convertTime12to24(this.endTime);
      activity.contextable_id = this.props.id;
      activity.contextable_type = this.props.contextable_type;
      activity.person_id = this.formData.person_id;
      activity.owner_id = this.formData.owner_id;

      if (this.formData.activity_done) {
          activity.status_id = this.doneActivityId;
      }

      this.save(activity);
      //
      // this.formData.started_at = selectedStartDate;
      // this.formData.ended_at = selectedEndDate;

    },
    afterError(response) {
      this.loading = false;
      this.$toastr.e(response.data.message);
    },

            afterSuccess(response) {
              let status = this.formData.activity_done ? this.doneActivityId : 9;
                this.input = ''
                this.formData = {
                    title: '',
                    started_at: new Date(),
                    ended_at: new Date(),
                    person_id: [],
                    owner_id: [],
                };
              this.activity_type_id = this.activityTypeList.length ? collect(this.activityTypeList).first().id : null;
                this.$toastr.s(response.data.message);
              this.$hub.$emit('activity-list', `status=${status}`);
            },
            afterFinalResponse() {
                this.loading = false;
            },

    getActivityType() {
      return this.axiosGet("crm/activity_types")
          .then((response) => {
            this.activityTypeList = this.collection(response.data.data).shaper(
                "name"
            );
              this.activity_type_id = this.activityTypeList.length ? collect(this.activityTypeList).first().id : null;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    api
        .route("crm/statuses")
        .params({ name: "status_done", type: "activity" })
        .get()
        .then((res) => {
            this.doneActivityId =
                collect(res).first().id ;
        });
    this.getActivityType()

  }
}
</script>
