<template>
  <div class="content-wrapper kanban-view">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <app-breadcrumb
          :page-title="currentPipeline"
          :directory="[$t('deals'), $t('pipeline_view'), currentPipeline]"
          :icon="'clipboard'"
        />
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="text-sm-right mb-primary mb-sm-0 mb-md-0">
          <div class="dropdown d-inline-block btn-dropdown">
            <button
              type="button"
              class="btn btn-success dropdown-toggle ml-0 mr-2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("actions") }}
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/pipelines/create`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'plus-square'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("add_pipeline") }}
              </a>
              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/pipelines/${localPipelineId}/edit`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'edit'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("edit_pipeline") }}
              </a>
              <a
                class="dropdown-item d-flex align-items-center p-3"
                @click.prevent="deletePipeline"
                href="#"
              >
                <app-icon
                  stroke-width="1"
                  :name="'trash'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("delete_pipeline") }}
              </a>
              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/deal/import`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'download'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("import_deals") }}
              </a>

              <a
                class="dropdown-item d-flex align-items-center p-3"
                href=""
                @click.prevent="openLostreasonModal"
              >
                <app-icon
                  stroke-width="1"
                  :name="'plus-square'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("add_lost_reasons") }}
              </a>

              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/lost/reasons/list/view`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'list'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("manage_lost_reasons") }}
              </a>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-primary btn-with-shadow"
            data-toggle="modal"
            @click="openModal()"
          >
            {{ $t("add_deal") }}
          </button>
        </div>
      </div>
    </div>
    <!--filter-->
    <div class="row">
      <div class="col-9 col-sm-8 col-md-9 col-lg-10 col-xl-10">
        <div
          class="filters-wrapper calendar-position-modified d-flex justify-content-start flex-wrap"
        >
          <!--Open Filters Button For Mobile-->
          <button
            class="btn d-block d-sm-none btn-toggle-filters"
            type="button"
            @click.prevent="toggleFilters"
          >
            <app-icon :name="'filter'" />
            {{ $t("filters") }}
          </button>

          <span v-show="isFiltersOpen" class="mobile-filters-wrapper">
            <app-filter
              :filters="options.filters"
              @get-values="getFilterValues"
              v-if="filterDataLoaded"
            />
            <template v-if="dataLoaded">
              <div
                class="selected-btn single-filter ml-2"
                v-if="filterValues.highlights.length > 0"
              >
                {{ filterValues.highlights.length }} {{ $t("marked", "Marked") }}
              </div>
              <button class="btn btn-sm mb-2 text-muted">
                {{ filterDealCount }} {{ $t("deals", "Deals") }}
              </button>
            </template>
            <button
              type="button"
              class="btn btn-primary btn-with-shadow d-sm-none btn-close-filter-wrapper d-flex justify-content-center align-items-center"
              @click="toggleFilters"
            >
              {{ $t("close") }}
            </button>
          </span>
        </div>
      </div>
      <div class="col-3 col-sm-4 col-md-3 col-lg-2 col-xl-2">
        <div class="mr-0 single-filter single-search-wrapper">
          <div
            class="form-group form-group-with-search d-flex align-items-center justify-content-end"
          >
            <app-search @input="getSearchValue" />
          </div>
        </div>
      </div>
    </div>

    <!--kanban view-->
    <div class="card card-with-shadow border-0">
      <div class="card-body" style="padding: 0.5rem">
        <div class="kanban-wrapper custom-scrollbar overflow-auto pl-0" v-if="dataLoaded">
          <div
            class="kanban-column"
            v-for="(stage, index) in stagesProperty"
            :key="index"
          >
            <template v-if="stagesData[stage.id]">
              <div class="py-3 stage-header rounded-top row">
                <div class="col-10">
                  <h6 class="text-truncate">{{ stage.name }}</h6>
                  <div
                    class="text-muted d-flex flex-wrap align-items-center stage-information"
                  >
                    <span>
                      {{
                        numberWithCurrencySymbol(
                          collect(totalDealsValues).find(stage.id).value
                        )
                      }}
                    </span>
                    <span>{{ stagesData[stage.id].length }} {{ $t("deal") }}</span>
                    <span>{{ stage.probability }}%</span>
                  </div>
                </div>
                <div class="col-2 collapse-btn" @click="pipelineCollapse(index)">
                  <app-icon
                    stroke-width="1"
                    name="chevrons-left"
                    width="16"
                    height="16"
                  />
                </div>
              </div>
              <draggable
                :data-stage="stage.id"
                @end="dragEnd"
                :move="checkMove"
                @change="posChanged"
                ghostClass="crm-sortable-ghost"
                chosenClass="crm-sortable-chosen"
                dragClass="crm-sortable-drag"
                forceFallback="true"
                animation="150"
                easing="cubic-bezier(1, 0, 0, 1)"
                class="kanban-draggable-column"
                :list="stagesData[stage.id]"
                group="deals"
              >
                <div
                  class="card card-with-shadow border-0 draggable-item"
                  :data-id="element.id"
                  v-for="(element, ix) in stagesData[stage.id]"
                  :key="ix"
                  :class="[
                    ix == stagesData[stage.id].length - 1 ? '' : 'mb-2',
                    ix == 0 ? 'mt-2' : '',
                    computedHighlights.indexOf(element.id) > -1 ? 'highlights' : '',
                  ]"
                >
                  <div class="card-body font-size-90">
                    <div class="row py-2">
                      <div class="col-10">
                        <div class="media d-flex align-items-start mb-3">
                          <app-avatar
                            class="mr-2"
                            :title="element.contextable ? element.contextable.name : ''"
                            :img="
                              element.contextable
                                ? element.contextable.profile_picture
                                  ? urlGenerator(element.contextable.profile_picture.path)
                                  : element.contextable.profile_picture
                                : ''
                            "
                            :avatar-class="
                              'avatars-w-24 ' + element.title.substring(0, 1)
                            "
                            :alt-text="$t('not_found')"
                          />
                          <a :href="urlGenerator(`crm/deal/${element.id}/details`)"
                            ><div class="media-body">
                              {{ element.title }}
                            </div></a
                          >
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="dropdown options-dropdown d-inline-block">
                          <button
                            type="button"
                            class="btn-option btn"
                            data-toggle="dropdown"
                            :title="$t('actions')"
                          >
                            <app-icon name="more-vertical" />
                          </button>

                          <div class="dropdown-menu dropdown-menu-right py-2 mt-1">
                            <a
                              v-if="true"
                              class="dropdown-item px-4 py-2 font-size-90"
                              @click.prevent="openActivityModal(element)"
                              href="#"
                            >
                              {{ $t("add_activity") }}
                            </a>
                            <a
                              v-if="true"
                              class="dropdown-item px-4 py-2 font-size-90"
                              @click.prevent="openDealModal(element.id)"
                              href="#"
                            >
                              {{ $t("edit") }}
                            </a>
                            <a
                              v-if="true"
                              class="dropdown-item px-4 py-2 font-size-90"
                              @click.prevent="deleteDeal(element.id)"
                              href="#"
                            >
                              {{ $t("delete") }}
                            </a>
                            <a
                              v-if="true"
                              class="dropdown-item px-4 py-2 font-size-90"
                              @click.prevent="selectDeal(element.id)"
                              href="#"
                            >
                              {{
                                computedHighlights.indexOf(element.id) > -1
                                  ? $t("unmark_deal")
                                  : $t("mark_deal")
                              }}
                            </a>
                            <a
                              v-if="true"
                              class="dropdown-item px-4 py-2 font-size-90"
                              @click.prevent="openTagModal(element)"
                              href="#"
                            >
                              {{ $t("manage_tag") }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="element.contextable"
                      class="text-muted d-flex-inline justify-content-between py-2 px-1"
                      :key="
                        element.contextable_type ===
                        'App\\Models\\CRM\\Organization\\Organization'
                          ? 'briefcase'
                          : 'user'
                      "
                    >
                      <app-icon
                        :name="
                          element.contextable_type ===
                          'App\\Models\\CRM\\Organization\\Organization'
                            ? 'briefcase'
                            : 'user'
                        "
                        class="size-16 mb-1 mr-1"
                      />
                      <a
                        class="mr-1"
                        :href="
                          urlGenerator(
                            `crm/${
                              element.contextable_type ==
                              'App\\Models\\CRM\\Organization\\Organization'
                                ? 'organizations'
                                : 'persons'
                            }/${element.contextable.id}/edit`
                          )
                        "
                      >
                        {{ element.contextable.name }}
                      </a>
                      <template
                        v-if="
                          element.contextable_type ===
                          'App\\Models\\CRM\\Organization\\Organization'
                        "
                      >
                        <!-- <b class="mr-1 ml-1">|</b> -->
                        <template v-if="element['contact_person'][0]">
                          <app-icon name="user" class="size-16 mb-1" />
                          <a
                            :href="
                              urlGenerator(
                                `crm/persons/${element['contact_person'][0]['id']}/edit`
                              )
                            "
                          >
                            {{ element["contact_person"][0]["name"] }}
                          </a>
                        </template>
                        <template v-else>
                          <app-icon name="user" class="size-16 mb-1" />
                          {{ "No contact" }}
                        </template>
                      </template>
                    </div>
                    <div
                      v-else
                      class="text-muted d-flex-inline justify-content-between py-2 px-1"
                    >
                      <p class="text-muted font-size-90 mb-2">
                        {{ $t("no_lead_added") }}
                      </p>
                    </div>
                    <div
                      class="media d-flex align-items-start mb-1 p-1"
                      data-toggle="tooltip"
                      title="Owner"
                      v-if="element.owner"
                    >
                      <app-avatar
                        class="mr-2"
                        :title="element.owner.full_name"
                        avatar-class="avatars-w-20"
                        :img="
                          element.owner.profile_picture
                            ? urlGenerator(element.owner.profile_picture.path)
                            : element.owner.profile_picture
                        "
                        :alt-text="$t('not_found')"
                      />
                      <div class="media-body">
                        {{ element.owner.full_name }}
                      </div>
                      <span class="font-size-90 deal-value">{{
                        numberWithCurrencySymbol(element.value)
                      }}</span>
                    </div>
                    <template>
                      <hr
                        v-if="
                          element.tags.length ||
                          element.activity.length ||
                          element.proposals.length
                        "
                      />
                      <div class="row">
                        <div
                          v-if="element.tags.length > 0"
                          class="col-12 d-flex tags-background flex-wrap justify-content-start"
                        >
                          <span
                            class="badge badge-sm badge-pill text-capitalize mr-1 mb-1 badge-text-truncate"
                            v-for="(tag, index) in element.tags"
                            :style="{ 'background-color': tag.color_code }"
                            :key="index"
                            >{{ tag.name }}
                          </span>
                        </div>

                        <div class="col-12 d-flex">
                          <template v-if="element.activity.length > 0">
                            <span
                              class="badge badge-pill deal-activity py-1 mr-2"
                              data-toggle="tooltip"
                              title="Total Todo Activity"
                              v-if="
                                collect(element.activity)
                                  .where('status_id', '=', 9)
                                  .count() > 0
                              "
                            >
                              Todo
                              <span class="badge badge-round ml-2 activity">{{
                                collect(element.activity)
                                  .where("status_id", "=", 9)
                                  .count()
                              }}</span>
                            </span>
                            <span
                              class="badge badge-pill deal-activity py-1 mr-2"
                              data-toggle="tooltip"
                              title="Total Done Activity"
                              v-if="
                                collect(element.activity)
                                  .where('status_id', '!=', 9)
                                  .count() > 0
                              "
                            >
                              Done
                              <span class="badge badge-round activity">{{
                                collect(element.activity)
                                  .where("status_id", "!=", 9)
                                  .count()
                              }}</span>
                            </span>
                          </template>
                          <span
                            class="badge badge-pill deal-activity py-1 mr-2"
                            data-toggle="tooltip"
                            title="Total proposal"
                            v-if="element.proposals.length > 0"
                          >
                            Proposal
                            <span class="badge badge-round ml-2 deal-activity">{{
                              element.proposals.length
                            }}</span>
                          </span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </draggable>
              <div class="draggable-action-wrapper">
                <button
                  class="btn btn-stage-action shadow"
                  slot="footer"
                  data-toggle="modal"
                  @click="openModal(stage.id)"
                >
                  <app-icon name="plus" class="mr-1" />
                  {{ $t("add") }}
                </button>
              </div>
            </template>
            <template v-else>
              <div class="p-3 mb-2 stage-header rounded-top">
                <h6 class="text-truncate">{{ stage.name }}</h6>
              </div>
              <draggable
                :data-stage="stage.id"
                @end="dragEnd"
                :move="checkMove"
                @change="posChanged"
                class="kanban-draggable-column"
                :list="stagesData[stage.id]"
                group="deals"
              >
                <div
                  class="card card-with-shadow mb-2 border-0 draggable-item"
                  :data-id="element.id"
                  v-for="(element, ix) in stagesData[stage.id]"
                  :key="ix"
                  :class="[
                    computedHighlights.indexOf(element.id) > -1 ? 'highlights' : '',
                  ]"
                >
                  <a :href="urlGenerator(`crm/deal/${element.id}/details`)">
                    <div class="card-body font-size-90">
                      <div class="media d-flex align-items-start mb-3">
                        <app-avatar
                          :title="element.title"
                          class="mr-2"
                          :avatar-class="'avatars-w-20 ' + element.title.substring(0, 1)"
                          :img="''"
                          :alt-text="$t('not_found')"
                        />
                        <div class="media-body">{{ element.title }}</div>
                      </div>
                      <div
                        class="text-muted d-flex align-items-center justify-content-between"
                      >
                        <div class="media d-flex align-items-center" v-if="element.owner">
                          <app-avatar
                            class="mr-2"
                            :title="element.owner.full_name"
                            avatar-class="avatars-w-20"
                            :img="
                              element.owner.profile_picture
                                ? element.owner.profile_picture.path
                                : element.owner.profile_picture
                            "
                            :alt-text="$t('not_found')"
                          />
                          <div class="media-body">
                            {{ element.owner.full_name }}
                          </div>
                        </div>
                        <span class="font-size-90 deal-value">{{
                          numberWithCurrencySymbol(element.value)
                        }}</span>
                      </div>
                    </div>
                  </a>
                </div>
              </draggable>
            </template>
          </div>
        </div>
        <div class="p-3" v-else>
          <app-overlay-loader />
        </div>
      </div>
    </div>
    <!-- Activity Modal-->
    <component
      :is="'app-activity-modal'"
      v-if="isActivityModalActive"
      :table-id="tableId"
      :previousData="true"
      :selectData="activityData"
      @close-modal="closeActivityModal()"
      @save="refreshState()"
    />
    <!-- End of Activity Modal -->
    <!--Deal Add Edit Modal-->
    <app-deal-modal
      v-if="isDealModalActive"
      :table-id="tableId"
      :selected-url="dealUrl"
      :selected-stage-index="selectedStageId"
      :pipeline-id="localPipelineId"
      @close-modal="closeModal()"
      @saved="refreshState()"
    />
    <pipeline-delete-modal
      v-if="isDeletePipelineModal"
      :modalId="'pipeline-delete-modal'"
      :pipelineId="localPipelineId"
      :deletePipelineUrl="`crm/pipelines/${localPipelineId}`"
      @close-modal="closePipelineDeleteModal"
    />

    <app-confirmation-modal
      v-if="dealDeleteModal"
      modal-id="deal-delete-modal"
      @confirmed="confirmed"
      @cancelled="cancelled"
    />
    <app-lost-reason-modal
      v-if="isModalActive"
      :table-id="tableId"
      :selected-url="selectedUrlReason"
      @close-modal="closeLostReasonModal"
    />
    <app-modal v-if="isTagModalActive" modal-id="tag-modal" @close-modal="closeTagModal">
      <template slot="header">
        <h5 class="modal-title">{{ $t("manage_tag") }}</h5>
        <button
          type="button"
          class="close outline-none"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span>
            <app-icon :name="'x'"></app-icon>
          </span>
        </button>
      </template>
      <template slot="body">
        <app-tags
          :tag-list="tagList"
          :editable="true"
          :taggable-id="taggableId"
          post-url="crm/deal/tags/"
          :table-id="tableId"
          @tag-updated="tagUpdated"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import queryString from "query-string";
import { FormMixin } from "@core/mixins/form/FormMixin";
import {
  getCurrencySymbol,
  numberWithCurrencySymbol,
  urlGenerator,
} from "@app/Helpers/helpers";
import { collect } from "@app/Helpers/Collection";
import { contactType, dealStatus, owner, tag } from "@app/Mixins/Global/FilterMixins";
import { getCustomFileds } from "@app/Mixins/Global/CustomFieldMixin";
import PipelineDeleteModal from "./Pipeline/PipelineDeleteModal";

export default {
  name: "KanbanView",
  components: { PipelineDeleteModal, draggable },

  mixins: [FormMixin, getCustomFileds, dealStatus, tag, owner, contactType],
  props: ["pipeline", "selectedUrl", "highlights"],
  data() {
    return {
      numberWithCurrencySymbol,
      getCurrencySymbol,
      urlGenerator,
      collect,
      isDealModalActive: false,
      dealUrl: "",
      tableId: "deal-modal",
      isDeletePipelineModal: false,
      dealDeleteModal: false,
      dealDeleteId: "",
      isModalActive: false,
      isTagModalActive: false,
      taggableId: null,
      tagList: [],
      selectedUrlReason: "",
      stagesProperty: [],
      stagesData: [],
      activityData: {
        type_of_activity: 1,
        contextable_id: null,
      },
      formData: {},
      isActivityModalActive: false,
      dealCardMoved: false,
      dataLoaded: false,
      filterDataLoaded: false,
      totalDealsValues: [],
      localPipelineId: null,
      selectedStageId: null,
      // for filter and search
      isFiltersOpen: true,
      filterValues: {
        highlights: [],
      },
      searchValue: "",
      currentPipeline: "",
      currentDeal: {},
      pipelines: [],
      selectedDealId: null,
      options: {
        filters: [
          {
            title: this.$t("owner"),
            type: "checkbox",
            key: "owner_is",
            option: [],
          },
          {
            title: this.$t("lead_group"),
            type: "checkbox",
            key: "contact_type",
            option: [],
          },
          {
            title: "Created date",
            type: "range-picker",
            key: "created-date",
            option: ["today", "thisMonth", "last7Days", "thisYear"],
          },
          {
            title: "Deal have proposal",
            type: "radio",
            key: "deal_with_proposal",
            header: {
              title: "Want to filter your deal?",
              description: "You can filter your deal list which don't have any proposal",
            },
            option: [
              {
                id: 1,
                name: "Have proposal",
              },
              {
                id: 2,
                name: "Don't have proposal",
              },
            ],
            listValueField: "name",
          },
          {
            title: this.$t("tag"),
            type: "multi-select-filter",
            key: "tags",
            option: [],
          },
        ],
      },
    };
  },
  computed: {
    computedHighlights() {
      if (this.selectedDealId) {
        let dealId = this.selectedDealId;
        let idx = this.filterValues.highlights.indexOf(dealId);
        if (idx !== -1) {
          this.filterValues.highlights.splice(idx, 1);
        } else {
          this.filterValues.highlights.push(dealId);
        }
        this.selectedDealId = null;
      }

      this.setUrlSearhParams();
      return this.filterValues.highlights;
    },
    filterDealCount() {
      let dealCount = 0;
      for (let key in this.stagesData) {
        dealCount += this.stagesData[key].length;
      }

      return dealCount;
    },
  },
  created() {
    this.filterValues.highlights = this.$props.highlights
      ? this.$props.highlights
          .trim()
          .split(",")
          .map((e) => Number(e))
      : [];
  },
  methods: {
    pipelineCollapse(index) {
      let kanbans = document.getElementsByClassName("kanban-column");
      let headers = document.getElementsByClassName("stage-header");
      kanbans[index].classList.toggle("pipeline-collapse");
      headers[index].classList.toggle("row");
      // console.log(kanbans[index]);
    },
    tagUpdated() {
      this.closeTagModal();
      this.refreshState();
    },
    openTagModal(deal) {
      this.isTagModalActive = true;
      this.taggableId = deal.id;
      this.tagList = deal.tags;
      $("#tag-modal").modal("show");
    },
    closeTagModal() {
      this.isTagModalActive = false;
      this.taggableId = null;
      this.tagList = [];
      $("#tag-modal").modal("hide");
    },
    selectDeal(dealId) {
      this.selectedDealId = dealId;
    },
    setUrlSearhParams() {
      let params = queryString.stringify(this.filterValues, {
        arrayFormat: "comma",
      });
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params;
      window.history.pushState({ path: newurl }, "", newurl);
    },
    openActivityModal(deal) {
      this.activityData.contextable_id = deal.id;
      this.isActivityModalActive = true;
    },
    closeActivityModal(v) {
      this.isActivityModalActive = false;
      $("#activity-modal").modal("hide");
    },
    add() {
      if (this.newTask) {
        this.arrBackLog.push({ name: this.newTask });
        this.newTask = "";
      }
    },
    openDealModal(id) {
      this.dealUrl = `crm/deals/${id}`;
      this.isDealModalActive = true;
    },
    openModal(stageId) {
      this.isDealModalActive = true;
      this.selectedStageId = stageId;
      setTimeout(function () {
        $("#deal-modal").modal("show");
      });
    },
    refreshState() {
      this.getFilterValues(this.filterValues);
      this.closeModal();
    },
    closeModal() {
      this.isDealModalActive = false;
      this.dealUrl = undefined;
      $("#deal-modal").modal("hide");
    },
    dragEnd(evt) {
      if (this.dealCardMoved) {
        this.formData.stage_id = evt.to.dataset.stage;
        this.currentDeal = this.stagesData[this.formData.stage_id].find(
          (el) => el.id == this.formData.id
        );
        this.formData.title = this.currentDeal.title;
        this.axiosPatch({
          url: "crm/deals/" + this.formData.id,
          data: this.formData,
        })
          .then((res) => {
            this.dealCardMoved = false;
          })
          .catch((er) => console.log(er));
        setTimeout(() => {
          this.setTotalDealsValues();
        }, 100);
      }
    },
    posChanged(evt) {
      if (evt.removed) {
        this.formData.id = evt.removed.element.id;
        this.dealCardMoved = true;
        // console.log(evt.removed.element.stage_id, "removed");
      }
    },
    checkMove(evt) {
      //   console.log(evt.draggedContext.element.id, "dragged id");
    },
    setData(data) {
      this.stagesData = data;
    },
    setProps(data) {
      this.setStagesProperty(data).then(() => {
        this.stagesProperty = collect(this.stagesProperty).sortBy("priority").get();
        this.setTotalDealsValues();
      });
    },
    async setStagesProperty(data) {
      this.stagesProperty = [];
      let pipelineIds = new Set();
      data.forEach((el) => {
        if (Number(el.pipeline_id) == this.localPipelineId) {
          this.stagesProperty.push(el);
          if (this.stagesData[el.id] == undefined) this.stagesData[el.id] = [];

          this.currentPipeline = el.pipeline.name;
        }
        // collect all available pipeline
        if (
          el.pipeline != null &&
          !pipelineIds.has(el.pipeline.id) &&
          !this.filterDataLoaded
        ) {
          pipelineIds.add(el.pipeline.id);
          this.pipelines.push({ id: el.pipeline.id, name: el.pipeline.name });
        }
      });
    },
    setTotalDealsValues() {
      let totalDealsValues = [];
      let totalDealsValue = 0;
      for (let key in this.stagesData) {
        if (this.stagesData[key].length > 0) {
          this.stagesData[key].forEach((element, i) => {
            totalDealsValue = Number(totalDealsValue) + Number(element.value);
            if (i + 1 == this.stagesData[key].length) {
              let totalValue = {
                id: Number(key),
                value: Number(totalDealsValue),
              };
              totalDealsValues.push(totalValue);
              totalDealsValue = 0;
            }
          });
        } else {
          totalDealsValue = 0;
          let totalValue = {
            id: Number(key),
            value: Number(totalDealsValue),
          };
          totalDealsValues.push(totalValue);
        }
      }
      this.totalDealsValues = totalDealsValues;
    },
    getStages() {
      return this.axiosGet("crm/stages?all")
        .then((res) => {
          this.setProps(res.data);
        })
        .catch((er) => console.log(er));
    },
    getData() {
      if (this.localPipelineId == "") {
        this.axiosGet("crm/pipelines?all").then((res) => {
          if (!res.data[0]) {
            this.redirect("/crm/pipelines/create");
          } else {
            this.localPipelineId = Number(res.data[0].id);
          }
        });
      }

      let pipelineId = this.localPipelineId;
      this.stagesData = [];
      let reqData = {};
      reqData.params = this.filterValues;
      reqData.params.date = reqData.params.created_date;
      delete reqData.params.created_date;

      return this.axiosGet("crm/deal/pipeline-view/" + pipelineId, reqData)
        .then((res) => {
          this.setData(res.data);
          // this.setTotalDealsValues();
        })
        .catch((er) => {
          if (er.response?.status == 404) {
            this.redirect("/crm/pipelines/create");
          }
        });
    },
    redirect(url = "/") {
      window.location = urlGenerator(url);
    },
    // for filter and search

    getFilterValues(value) {
      value.highlights = this.filterValues.highlights;
      this.filterValues = value;

      //pipeline dropdown navigation
      if (value["pipeline"]) {
        this.localPipelineId = Number(value.pipeline);
        this.dataLoaded = false;
        this.getData()
          .then(() => {
            this.getStages();
          })
          .then(() => {
            setTimeout(() => {
              this.dataLoaded = true;
              this.setUrlSearhParams();
            }, 200);
          });
      }
    },
    toggleFilters() {
      this.isFiltersOpen = !this.isFiltersOpen;
    },

    getSearchValue(value) {
      this.searchValue = value;
      this.filterValues.search = this.searchValue;
      this.getData();
    },
    isFiltersActive() {
      this.isFiltersOpen = window.innerWidth > 575;
    },
    deleteDeal(id) {
      this.dealDeleteId = id;
      this.dealDeleteModal = true;
    },
    confirmed() {
      let url = `crm/deals/${this.dealDeleteId}`;
      this.axiosDelete(url)
        .then((response) => {
          this.$toastr.s(response.data.message);
          this.getFilterValues(this.filterValues);
        })
        .catch(({ error }) => {
          this.cancelled();
        })
        .finally(() => {});
    },
    pipelineDeleteSuccess() {
      this.$hub.$on("pipeline-delete-success", (value = true) => {
        if (value) {
          window.location.replace(urlGenerator("/crm/deals/pipeline/view"));
        }
      });
    },
    closePipelineDeleteModal() {
      this.isDeletePipelineModal = false;
      $("#pipeline-delete-modal").modal("hide");
    },
    cancelled() {
      this.dealDeleteModal = false;
      $("#deal-delete-modal").modal("hide");
    },
    deletePipeline() {
      this.isDeletePipelineModal = true;
    },
    openLostreasonModal() {
      this.isModalActive = true;
      $("#lost-reason-modal").modal("show");
    },
    closeLostReasonModal() {
      this.isModalActive = false;
      this.selectedUrlReason = "";
      $("#lost-reason-modal").modal("hide");
    },
    getDealValueRange() {
      this.axiosGet("crm/deal-value").then((response) => {
        this.options.filters.push({
          title: this.$t("deal_value"),
          type: "range-filter",
          key: "deal-value",
          minTitle: this.$t("minimum_value"),
          maxTitle: this.$t("maximum_value"),
          maxRange: Number(response.data.max_deal_value),
          minRange:
            Number(response.data.min_deal_value) < Number(response.data.max_deal_value)
              ? Number(response.data.min_deal_value)
              : 0,
        });
      });
    },
  },
  mounted() {
    this.pipelineDeleteSuccess();
    setTimeout(() => {
      // this.$store.dispatch("getOwner");
      this.$store.dispatch("getOrganization");
      this.$store.dispatch("getPerson");
      this.$store.dispatch("getDeal");
      // this.$store.dispatch("getAllTags");
      this.getDealValueRange();
    }, 1000);
    this.localPipelineId = this.$props.pipeline ? this.$props.pipeline : "";

    //if props.pipelineId exist pipeline navigation filter would be added
    this.options.filters.unshift({
      title: "Select a pipeline",
      type: "dropdown-menu-filter",
      key: "pipeline",
      initValue: null,
      option: [],
      listValueField: "name",
    });

    Promise.all([this.getData(), this.getStages()]).then(() => {
      this.dataLoaded = true;
      this.options.filters[0].initValue = Number(this.localPipelineId);
      this.options.filters[0].option = this.pipelines;
      this.filterDataLoaded = true;
    });

    // for filter and search
    this.isFiltersActive();
    window.onresize = () => {
      this.isFiltersActive();
    };

    // dynamic height for kanban view.
    window.addEventListener("resize", () => {
      $(".kanban-view .kanban-draggable-column").css({
        height: window.innerHeight - 290 + "px",
      });
    });
  },
  watch: {
    // dynamic height for kanban view.
    stagesProperty: function () {
      $(".main-panel").css({
        minHeight: window.innerHeight + "px",
      });
      $(".kanban-view").css({
        paddingBottom: 0,
      });
      $(".kanban-view .kanban-draggable-column").css({
        height: window.innerHeight - 290 + "px",
      });
    },
  },
};
</script>
<style>
.crm-sortable-ghost {
  opacity: 0 !important;
}

.crm-sortable-chosen {
  /* opacity:0 !important; */
}
.crm-sortable-drag {
  opacity: 1 !important;
}
.avatars-w-24 .no-img {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--avatar-no-image-font);
  background-color: var(--avatar-no-image-bg);
}
.avatars-w-24 .no-img,
.avatars-w-24 img {
  height: 24px !important;
  width: 24px !important;
}
.T .no-img {
  background: olive;
}
.A .no-img {
  background: #9581f0;
}
.B .no-img {
  background: rgb(41, 207, 199);
}
.C .no-img {
  background: rgb(207, 84, 166);
}
.D .no-img {
  background: rgb(234, 236, 72);
}
.I .no-img {
  background: rgb(235, 83, 90);
}
.M .no-img {
  background-color: #9581f0 !important;
}
.R .no-img {
  background: rgb(41, 207, 199);
}
.Q .no-img {
  background: rgb(207, 84, 166);
}

.U .no-img {
  background: rgb(238, 160, 59);
}
.G .no-img {
  background: rgb(62, 160, 206);
}

.P .no-img {
  background: rgb(223, 66, 189);
}
.S .no-img {
  background: rgb(95, 209, 72);
}

.L .no-img {
  background: rgb(234, 236, 72);
}
.O .no-img {
  background: rgb(235, 83, 90);
}
</style>
<style scoped lang="scss">
.highlights {
  border: 1px solid #38a4f8 !important;
}
.badge.activity {
  cursor: pointer;
}
.tags-background {
  background: #fbfbfb;
  padding: 1rem;
}
.kanban-wrapper .kanban-column {
  width: 320px;
  min-width: 320px;
  // background: #f9f9f9;
  background: #e8e8e8;
}
html[theme="light"] .draggable-item:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
html[theme="dark"] .draggable-item:hover {
  box-shadow: 0px 0px 5px black, inset 1px 1px 0px #3e3e6d, inset -1px -1px 0px #3e3e6d;
}
h5,
h6 {
  font-weight: 600;
}
hr {
  margin: 0.5rem -1rem 0.1rem;
}
a {
  font-size: 0.85rem;
  color: #25384a;
}
.badge {
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0.65em 0.95em;
}
.deal-value {
  font-size: 90% !important;
  color: black;
}
.deal-activity {
  background: var(--default-card-bg);
  color: var(--default-font-color);
  cursor: pointer;
  .badge {
    background: #fbfbfb;
    color: var(--default-font-color);
  }
}

[theme="dark"] {
  .kanban-wrapper .kanban-column {
    background: #{darken(#2b303b, 7%)};
  }
  a {
    color: #{lighten(#4466f2, 20%)};
  }
  .deal-value {
    color: #6cabb9;
  }
  .tags-background {
    background: #2b303b;
  }
  .deal-activity {
    .badge {
      background: #2b303b;
    }
  }
}
.selected-btn {
  font-size: 95%;
  cursor: pointer;
  border-radius: 20px;
  color: #8a8a8a;
  padding: 0.5rem 1.5rem;
  background: var(--btn-filter-bg);
  box-shadow: var(--default-box-shadow);
  border: 1px solid var(--btn-filter-bg) !important;
}
.pipeline-collapse .stage-header {
  width: 580px;
  left: 0px;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  transform-origin: calc(1em + 0.5rem) center;
  transform: rotate(90deg);
  padding-left: 0.5rem !important;
  padding-bottom: 1.5rem !important;
  border-bottom: 0px;
}
.pipeline-collapse .stage-header > div {
  display: inline-flex;
  align-items: baseline;
  padding-left: 0px;
}
.pipeline-collapse .stage-header > div.col-10 {
  left: -50px;
}
.pipeline-collapse .stage-information {
  margin-left: 1rem !important;
  flex: 0 0 60%;
}
.pipeline-collapse .stage-header > div > svg {
  transform: rotate(90deg);
}
.kanban-wrapper .pipeline-collapse.kanban-column {
  width: 60px;
  min-width: 60px;
  opacity: 0.7;
}
.pipeline-collapse .draggable-item {
  display: none;
}
.pipeline-collapse .kanban-draggable-column {
  margin-bottom: 18px;
}
.collapse-btn {
  cursor: pointer;
}
.kanban-wrapper .stage-header {
  margin-left: 0rem;
}
</style>
