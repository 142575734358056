import {axiosGet} from "../../../Helpers/AxiosHelper";
import * as dateTimeFunc from '../../../Helpers/DateTimeHelper';
import {urlGenerator} from  "@app/Helpers/helpers";

const state = {
    typeList: [],
    defaulStagetList: [],
    appTypeList: [],
    users: [],
    customFieldTypeList: [],
    topActivityList: [],
    topNotificationList: [],
    templateList: [],
    customFiledList: [],
    roleList:[]

};
const getters = {
    getPhoneEmailType: state => state.typeList,
    getDefaultStage: state => state.defaulStagetList,
    getAllAppTypeList: state => state.appTypeList,
    getUsers: state => state.users,
    customFieldTypeList: state => state.customFieldTypeList,
    getAllActivity: state => state.topActivityList,
    getAllNotification: state => state.topNotificationList,
    getTemplate: state => state.templateList,
    getCustomFiled: state => state.customFiledList,
    getRole: state => state.roleList
};

const mutations = {
    PHONE_EMAIL_TYPE(state, data) {
        state.typeList = data
    },
    DEFAULT_STAGE_INFO(state, data) {
        state.defaulStagetList = data
    },
    APP_TYPE_INFO(state, data) {
        state.appTypeList = data
    },
    SET_USERS(state, data) {
        state.users = data
    },
    SET_CUSTOM_FIELD_INFO(state, data) {
        state.customFieldTypeList = data
    },
    TOP_ACTIVITY_LIST(state, data) {

        state.topActivityList = data.map(item => {
            const {id, title,description,created_by,started_at,start_time} = item,
                profile_picture = created_by?.profile_picture;

            return {
                id: id,
                img: !_.isEmpty(profile_picture) ? urlGenerator(profile_picture.path) : '',
                name: data.name,
                title: title,
                description: '',
                time: start_time,
                date: dateTimeFunc.getDateFromNow(started_at, settings.dateFormat),
                url: urlGenerator('/crm/activities/calendar/view')
            }
        })
    },
    TOP_NOTIFICATION_LIST(state, data) {

        state.topNotificationList = data.map(item => {
            const {id, data, notifier, created_at, read_at} = item,
                profile_picture = notifier?.profile_picture;

            return {
                id: id,
                img: !_.isEmpty(profile_picture) ? urlGenerator(profile_picture.path) : '',
                name: data.name,
                title: data.message,
                description: '',
                time: dateTimeFunc.getTimeFromDateTime(created_at, settings.timeFormat),
                //time: dateTimeFunc.getDateFromNow(created_at, settings.timeFormat),
                date: dateTimeFunc.getDateFromNow(created_at, settings.dateFormat),
                //status: read_at ? settings['old'] : settings['new'],
                status: read_at ? 'old' : 'new',
                url: urlGenerator(data.url)
            }
        })
    },
    TEMPLATE_LIST(state, data) {
        state.templateList = data
    },
    CUSTOM_FILED_LIST(state, data){
        state.customFiledList = data
    },
    SET_ROLE_INFO(state, data){
        state.roleList = data
    }
};
const actions = {
    getPhoneEmailType(context) {
        axiosGet(`crm/phone/email/type`).then(response => {
            context.commit('PHONE_EMAIL_TYPE', response.data)
        }).catch((error) => console.log(error));
    },
    getDefaultStage(context) {
        axiosGet(`crm/stages-default`).then(response => {
            context.commit('DEFAULT_STAGE_INFO', response.data)
        }).catch((error) => console.log(error));
    },
    getAllAppTypeList({commit}) {
        axiosGet(`admin/app/types`).then(({data}) => {
            commit('APP_TYPE_INFO', data)
        }).catch((error) => console.log(error));
    },
    getUsers({commit, state}, payload = {}) {
        const url = payload.users ? `${`crm/users`}?existing=${payload.users}` : 'crm/users';
        axiosGet(url).then(({data}) => {
            commit('SET_USERS', data)
        }).catch((error) => console.log(error));
    },
    addUser({commit, state}, user) {
        commit('SET_USERS', state.users.concat([user]));
    },
    getCustomFieldType({commit}) {
        axiosGet(`admin/app/custom-field-types`).then(({data}) => {
            commit('SET_CUSTOM_FIELD_INFO', data)
        }).catch((error) => console.log(error));
    },
    getAllActivity({commit}) {
        axiosGet(`admin/user/activities`).then(({data}) => {
            commit('TOP_ACTIVITY_LIST', data)
        })
    },
    getAllNotification({commit}) {
        axiosGet(`admin/user/notifications`).then(({data}) => {
            commit('TOP_NOTIFICATION_LIST', data.data)
        })
    },
    getTemplate({commit}) {
        axiosGet(`crm/templates`).then(({data}) => {
            commit('TEMPLATE_LIST', data.data)
        })
    },
    getCustomFiled({commit}, {type: type}) {
        axiosGet(`crm/custom-filed?type=${type}`).then((response) => {
           commit('CUSTOM_FILED_LIST', response.data)
        })
    },
    getRole({commit}){
        axiosGet(`admin/auth/roles`).then(({data}) => {
            commit('SET_ROLE_INFO', data.data)
        })
    }

};


export default {
    state,
    getters,
    mutations,
    actions
}
