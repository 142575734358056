<template>
    <li class="nav-item dropdown">
        <a :href="allActivityUrl"
           id="activityDropdown"
           class="d-flex align-items-center nav-link count-indicator dropdown-toggle"
           title="Click To View Activity List"
           >
            <app-icon :name="'bell'"/>
              <span v-if="data.length !== 0" class="badge badge-danger badge-sm badge-circle float-right">
                    {{ data.length }}
              </span>
            <span class="count-symbol bg-primary spinner-grow" v-if="showIdentifier"/>
        </a>
        <!-- <div v-if="data.length !== 0" class="dropdown-menu dropdown-menu-right navbar-dropdown notification-dropdown"
             aria-labelledby="activityDropdown">
            <h6 class="p-primary mb-0 primary-text-color">
                <a :href="allActivityUrl">All Task & Activities</a>
                <span class="badge badge-primary badge-sm badge-circle float-right">
                    {{ data.length }}
                </span>
            </h6>
            <div class="dropdown-divider"/>
            <div class="dropdown-items-wrapper custom-scrollbar">
                <a class="dropdown-item"
                   v-for="(item, index) in data"
                   :href="item.url"
                   :key="index"
                   @click.prevent="$emit('clicked', item)">
                    <div class="media align-items-center">
                        <div class="avatars-w-50 mr-3">
                            <app-avatar :shadow="false"
                                        :img="item.img"
                                        :title="item.name"/>
                        </div>
                        <div class="media-body">
                            <p class="my-0 media-heading">{{item.title}}</p>
                            <span class="text-muted">
                                {{ textTruncate(item.description, 25) }}
                            </span>
                            <span class="primary-text-color link">
                                <span class="mr-3">{{ item.date }}</span>
                                <span>{{ item.time }}</span>
                            </span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div v-else
             class="dropdown-menu dropdown-menu-right navbar-dropdown notification-dropdown no-notification-dropdown p-primary"
             aria-labelledby="activityDropdown">
            <div class="d-flex justify-content-center">
                <img :src="noActivityImg"
                     class="no-notification-img"
                     alt="">
            </div>
            <p class="text-center font-size-80 m-0 pt-2 pb-0">
                {{ noActivityTitle }}
            </p>
        </div> -->
    </li>
</template>

<script>
    import AppFunction from "../../../helpers/app/AppFunction";

    export default {
        name: "ActivityDropdown",
        props: {
            data: {
                type: Array,
                required: true
            },
            allActivityUrl: {
                type: String,
                require: true
            },
            showIdentifier: {

            }
        },
        data() {
            return {
                noActivityData: [

                    {
                        img: 'images/no-notifications/IceCream',
                        title: this.$t('no_notification_two')
                    },

                ],
                noActivityImg: '',
                noActivityTitle: '',
            }
        },
        methods: {
            textTruncate(str, length, ending) {
                if (length == null) {
                    length = 50;
                }
                if (ending == null) {
                    ending = '...';
                }
                if (str.length > length) {
                    return str.substring(0, length - ending.length) + ending;
                } else {
                    return str;
                }
            },
            randomDataGenerate() {
                if (this.data.length === 0) {
                    let index = Math.floor(Math.random() * this.noActivityData.length);
                    if (this.$store.state.theme.darkMode) {
                        this.noActivityImg = AppFunction.getAppUrl(this.noActivityData[index].img + '-Dark.png');
                    } else {
                        this.noActivityImg = AppFunction.getAppUrl(this.noActivityData[index].img + '-Light.png');
                    }
                    this.noActivityTitle = this.noActivityData[index].title;
                }
            }
        },
        mounted(){
        },
    }
</script>
