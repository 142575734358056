<template>
    <div class="content-wrapper">
        <app-breadcrumb :page-title="$t('settings')" :directory="$t('settings')" :icon="'settings'"/>
        <app-tab :tabs="tabs" :icon="tabIcon"/>
    </div>
</template>

<script>

    import {FormMixin} from "../../../../core/mixins/form/FormMixin";

    export default {
        name: "Setting",
        mixins: [FormMixin],

        data() {
            return {

                loggedInUser: {},
                tabIcon: 'settings',
                tabs: [
                    {
                        'name': 'General',
                        'title': this.$t('general'),
                        'component': 'app-general-settings',
                        'permission': ''
                    },
                    {
                        'name': 'Email setup',
                        'title': this.$t('email_setup'),
                        'component': 'app-email-setup',
                        'permission': ''
                    },
                  {
                    'name': 'Notification',
                    'title': this.$t('notification'),
                    'component': 'app-notification-settings',
                    'permission': ''
                  },


                ],
            }
        },

    }
</script>
