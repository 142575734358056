<template>
    <span class="spinner-bounce">
        <span class="bounce1 mr-1"/>
        <span class="bounce2 mr-1"/>
        <span class="bounce3 mr-1"/>
    </span>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>
