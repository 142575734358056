<template>
    <div class="card border-0 card-with-shadow">
        <div class="card-header d-flex align-items-center justify-content-between p-primary bg-transparent">
            <h5 class="card-title text-muted m-0">{{ $t('tags') }}</h5>
            <div>
                <span v-if="isTagsEditable">
                    <a  href="#" class="text-muted" @click.prevent="showEditableTags">
                        <app-icon stroke-width="1" name="x-square"/>
                    </a>
                    <a href="#" class="text-muted" @click.prevent="showEditableTags">
                        <app-icon stroke-width="1" name="check-square"/>
                    </a>
                </span>
                <a v-else href="#" class="text-muted" @click.prevent="showEditableTags">
                    <app-icon stroke-width="1" name="edit"/>
                </a>
            </div>
        </div>
        <div class="card-body">
            <app-tags :tag-list="tagData"
                                :editable="isTagsEditable"
                                 :taggable-id="organization"
                            post-url='crm/organizations/tags/'
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "TagsOrganization",
        props:['tagData', 'organization'],
        data() {
            return {
                isTagsEditable: false,
            }
        },
        methods: {
            showEditableTags() {
                this.isTagsEditable = !this.isTagsEditable;
            },

        }
    }
</script>
