<template>
    <div class="content-wrapper calendar-position-modified">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <app-breadcrumb
                    :page-title="$t('lost_reasons')"
                    :directory="[$t('deals'), $t('lost_reason')]"
                    :icon="'clipboard'"/>
            </div>
            <div class="col-sm-12 col-md-6" v-if="$can('create_lost_reasons')">
                <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
                    <button
                        type="button"
                        class="btn btn-primary btn-with-shadow"
                        @click.prevent="openModal">
                        {{ $t('add_lost_reason') }}
                    </button>
                </div>
            </div>
        </div>

        <app-table :id="'lost-reason-table'" :options="options" @action="getAction"/>

        <app-lost-reason-modal v-if="isModalActive"
                          :table-id="tableId"
                          :selected-url="selectedUrlReason"
                          @close-modal="closeModal"/>

        <app-confirmation-modal v-if="confirmationModalActive"
                                modal-id="lost-reason-delete-modal"
                                @confirmed="confirmed"
                                @cancelled="cancelled"/>

    </div>
</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin";
import {owner} from "@app/Mixins/Global/FilterMixins";
import {DeleteMixin} from "../../../../Mixins/Global/DeleteMixin";

export default {
    name: "LostReasonList",
    mixins: [FormMixin, owner, DeleteMixin],
    data() {
        return {
            isModalActive: false,
            tableId: 'lost-reason-table',
            selectedUrlReason:'',
            options: {
                name: this.$t('pipeline_table'),
                url: 'crm/deal/lost-reasons',
                showHeader: true,
                columns: [
                    {
                        title: this.$t('name'),
                        type: 'text',
                        key: 'lost_reason',
                    },
                    {
                        title: this.$t('created_by'),
                        type: 'object',
                        key: 'created_by',
                        modifier: (value, row) => {
                            return value.full_name
                        }
                    },
                    {
                        title: 'Action',
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                filters: [
                    {
                        "title": this.$t('created_by'),
                        "type": "checkbox",
                        "key": "owner_is",
                        "option": [],
                    },
                    {
                        "title": this.$t('created_date'),
                        "type": "range-picker",
                        "key": "date",
                        "option": ["today", "thisMonth", "last7Days", "thisYear"]
                    },

                ],
                showSearch: true,
                showFilter: true,
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-lost-reason-modal',
                        modalId: 'lost-reason-modal',
                        modifier: () => this.$can('update_lost_reasons')

                    }, {
                        title: this.$t('delete'),
                        icon: 'trash',
                        type: 'modal',
                        component: 'app-confirmation-modal',
                        modalId: 'lost-reason-delete-modal',
                        url: '',
                        modifier: () => this.$can('delete_lost_reasons')
                    },
                ],
            }
        }
    },
    methods: {
        getAction(row, action, active) {
            if (action.title === this.$t('edit')) {
              this.selectedUrlReason = `crm/deal/lost-reasons/${row.id}`;
                this.isModalActive = true;
            } else if (action.title == this.$t('delete')) {
               this.deleteUrl = `crm/deal/lost-reasons/${row.id}`;
                this.confirmationModalActive = true;
            }
        },
        openModal() {
            this.isModalActive = true;
            $('#lost-reason-modal').modal('show');
        },
        closeModal() {
            this.isModalActive = false;
            this.selectedUrlReason = '';
            $('#lost-reason-modal').modal('hide');
        }
    }
}
</script>
