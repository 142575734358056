<template>
   
</template>

<script>
    import AppFunction from "../../../helpers/app/AppFunction";

    export default {
        name: "NotificationDropdown",
        props: {
            data: {
                type: Array,
                required: true
            },
            allNotificationUrl: {
                type: String,
                require: true
            },
            showIdentifier: {

            }
        },
        data() {
            return {
                noNotificationData: [
                    {
                        img: 'images/no-notifications/Cheers',
                        title: this.$t('no_notification_one')
                    },

                ],
                noNotificationImg: '',
                noNotificationTitle: '',
            }
        },
        methods: {
            textTruncate(str, length, ending) {
                if (length == null) {
                    length = 50;
                }
                if (ending == null) {
                    ending = '...';
                }
                if (str.length > length) {
                    return str.substring(0, length - ending.length) + ending;
                } else {
                    return str;
                }
            },
            randomDataGenerate() {
                if (this.data.length === 0) {
                    let index = Math.floor(Math.random() * this.noNotificationData.length);
                    if (this.$store.state.theme.darkMode) {
                        this.noNotificationImg = AppFunction.getAppUrl(this.noNotificationData[index].img + '-Dark.png');
                    } else {
                        this.noNotificationImg = AppFunction.getAppUrl(this.noNotificationData[index].img + '-Light.png');
                    }
                    this.noNotificationTitle = this.noNotificationData[index].title;
                }
            }
        }
    }
</script>
