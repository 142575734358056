<template>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-8">
                <div class="back-image"
                     :style="'background-image: url('+urlGenerator(configData.company_banner)+')'">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 pl-0">
                <div class="login-form d-flex align-items-center">
                    <form class="sign-in-sign-up-form w-100" ref="form" data-url="users/confirm" >
                        <div class="text-center mb-4">
                            <img :src="`${configData.company_logo ? urlGenerator(configData.company_logo) : urlGenerator('/images/core.png')}`" alt="" class="img-fluid logo">
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <h6 class="text-center mb-0">{{ $t('sign_up') }}</h6>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label >{{ $t('first_name') }}</label>
                                <app-input type="text"
                                           :placeholder="$t('first_name')"
                                           :required="true"
                                           v-model="userData.first_name"
                                />
                                <small class="text-danger" v-if="errors.first_name">{{ errors.first_name[0] }}</small>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label >{{ $t('last_name') }}</label>
                                <app-input type="text"
                                           :placeholder="$t('last_name')"
                                           :required="true"
                                           v-model="userData.last_name"
                                />
                                <small class="text-danger" v-if="errors.last_name">{{ errors.last_name[0] }}</small>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label >{{ $t('password') }}</label>
                                <app-input type="password"
                                           :placeholder="$t('your_password')"
                                           :required="true"
                                           v-model="userData.password"
                                />
                                <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}</small>
                                <app-note class="mt-2"
                                          note-type="note-warning"
                                          :show-title="false"
                                          :notes="[$t('password_hint_note')]"/>
                            </div>
                            <div class="form-group col-12">
                                <label >{{ $t('confirm_password') }}</label>
                                <app-input type="password"
                                           :placeholder="$t('confirm_password')"
                                           :required="true"
                                           v-model="userData.password_confirmation"
                                />
                                <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}</small>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-12">
                                <button type="button"
                                        class="btn btn-primary btn-block text-center"
                                        @click="submitData">
                                    <span class="w-100">
                                        <app-submit-button-loader v-if="loading"></app-submit-button-loader>
                                    </span>
                                    <template v-if="!loading">{{ $t('sign_up') }}</template>
                                </button>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-12">
                                <p class="text-center mt-5 footer-copy">
                                    {{ copyRightText() }}
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import ThemeMixin from '../../../../core/mixins/global/ThemeMixin';
import {FormMixin} from '../../../../core/mixins/form/FormMixin';
import {companyName, copyRightText, urlGenerator} from "../../../Helpers/helpers";

export default {
    name: "CompleteInvitation",
    props:['configData', 'token'],
    mixins: [FormMixin, ThemeMixin],

    data() {
        return {
            companyName, copyRightText, urlGenerator,
            loading: false,
            userData: {
                invitation_token : this.token
            },
            errors: []
        }
    },
    created() {
        let url_string = window.location.href,
            url = new URL(url_string),
            params = url.searchParams.get('name');
    },

    methods: {
        beforeSubmit(){
            this.loading = true;
        },
        submitData() {
            this.message = '';
            this.save(this.userData);
        },

        afterError(response) {
            this.loading = false;
            this.errors = response.data.errors;
        },

        afterSuccess({ data }) {
            location.replace(urlGenerator(`/`));
        },
        afterFinalResponse() {
            this.loading = false;
        },
        forgetPassword(){
            location.replace(urlGenerator(`users/password-reset`));
        }
    }
}
</script>