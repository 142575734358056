<template>
	<div class="card border-0 card-with-shadow">
		<div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
			<h5 v-show="isDetailsEditable" class="card-title text-muted mb-0">Passport details</h5>
			<h5 v-show="!isDetailsEditable" class="card-title text-muted mb-0">Edit Passport details</h5>

			<div>
				<a href="#" v-show="!isDetailsEditable" class="text-muted" @click.prevent="showDetailsClose">
					<app-icon stroke-width="1" name="x-square"/>
				</a>
				<a href="#" v-show="!isDetailsEditable" class="text-muted" @click.prevent="showDetailsSave">
					<app-icon stroke-width="1" name="check-square"/>
				</a>
				<a href="#" v-show="isDetailsEditable" class="text-muted" @click.prevent="showDetailsEditable">
					<app-icon stroke-width="1" name="edit"/>
				</a>
			</div>
		</div>

		<div class="card-body">
			<div v-show="isDetailsEditable">
        <template v-if="(passportDetails.passport_no)">
          <div class="form-group mb-3">
            <div class="form-row" v-if="passportDetails.passport_no">
              <label class="mb-0 text-muted col-5 d-flex align-items-center">Passport No.</label>
              <div class="col-7">
                {{ passportDetails.passport_no }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="passportDetails.place_of_issue">
              <label class="mb-0 text-muted col-5 d-flex align-items-center">Place Of Issue</label>
              <div class="col-7">
                {{passportDetails.place_of_issue}}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="passportDetails.issue_date">
              <label class="mb-0 text-muted col-5 d-flex align-items-center">Issue Date</label>
              <div class="col-7">
                {{ passportDetails.issue_date }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="passportDetails.expiry_date">
              <label class="mb-0 text-muted col-5 d-flex align-items-center">Expiry Date</label>
              <div class="col-7">
                {{ passportDetails.expiry_date }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="text-muted">{{ $t('not_added_yet') }}</p>
          <a href="#" class="font-size-90" @click.prevent="showDetailsEditable">
            + Add Details
          </a>
        </template>
			</div>

			<div v-show="!isDetailsEditable">

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Passport No.</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Passport No.'"
						           v-model="passportDetails.passport_no"/>
					</div>
				</div>


				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Place Of Issue</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Place Of Issue'"
						           v-model="passportDetails.place_of_issue"/>
					</div>
				</div>

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Issue Date</label>
					<div class="col-9">
            <app-input type="date"
               :placeholder="'Issue Date'"
               v-model="passportDetails.issue_date"/>
                </div>
				</div>
        <div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Expiry Date</label>
					<div class="col-9">
						<app-input type="date"
                       :placeholder="'Expiry Date'"
						           v-model="passportDetails.expiry_date"/>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {FormMixin} from "../../../core/mixins/form/FormMixin";

export default {
	name: "PassportDetails",
	mixins: [FormMixin],
	props: {
		passportDetails: {
			type: Object,
			required: true
		},
		passportUpdateUrl: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			isDetailsEditable: true,
      formData: {},
		}
	},

	methods: {
		showDetailsSave() {
      this.formData.name = this.passportDetails.name;
      this.formData.contact_type_id = this.passportDetails.contact_type_id;
      this.formData.owner_id = this.passportDetails.owner_id;
      this.formData.country_id = this.passportDetails.country_id;
      this.formData.area = this.passportDetails.area;
      this.formData.city = this.passportDetails.city;
      this.formData.state = this.passportDetails.state;
      this.formData.zip_code = this.passportDetails.zip_code;
      this.formData.address = this.passportDetails.address;
      this.formData.passport_no = this.passportDetails.passport_no;
      this.formData.place_of_issue = this.passportDetails.place_of_issue;
      this.formData.issue_date =moment(this.passportDetails.issue_date).format("YYYY-MM-DD");
      this.formData.expiry_date = moment(this.passportDetails.expiry_date).format("YYYY-MM-DD");
			this.submitFromFixin('patch', this.passportUpdateUrl, this.formData)
		},
		afterSuccess(response) {
			this.$toastr.s(response.data.message);
			this.isDetailsEditable = true
			this.$emit('update-request');
		},
		showDetailsClose() {
			this.isDetailsEditable = true;
		},
		showDetailsEditable() {
			this.isDetailsEditable = false
		},
	},

	created() {
    this.passportDetails.issue_date = moment(this.passportDetails.issue_date).format("YYYY-MM-DD");
     this.passportDetails.expiry_date = moment(this.passportDetails.expiry_date).format("YYYY-MM-DD");
	}
}
</script>
