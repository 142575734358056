<template>
    <div class="tags-organizer">
        <div class="d-flex flex-wrap">
            <template v-if="tagList.length">
            <span class="badge badge-sm badge-pill text-capitalize mr-1 mb-1 d-flex align-items-center justify-content-between"
                  v-for="(tag, index) in tagList"
                  :key="index"
                  :style="{'background-color': tag.color_code}">
                <span class="badge-text-truncate">{{ tag.name }}</span>
                <span v-if="editable && tag.type===0" 
                      class="btn-remove-tag d-flex align-items-center justify-content-center"
                      @click="detachTag(tag.id)">
                    <app-icon stroke-width="1" name="x" width="10" height="10"/>
                </span>
            </span>
            </template>
            <!-- <template v-else>
                <p class="text-muted">{{ $t('no_tag_available') }}</p>
            </template> -->
        </div>
        <template v-if="editable">
            <div class="form-group form-group-with-search d-flex align-items-center mt-1">
            <span class="form-control-feedback">
                <app-icon name="search"/>
            </span>

                <input type="text"
                       class="form-control"
                       :placeholder="$t('type_to_create_or_search_tag')"
                       v-model="search"
                       @click="getAllTags"
                       @keyup="getAllTags"
                       @keydown.enter.prevent="storeTag"
                       :autofocus="startNavigation"/>
                <input type="color" v-model="color" class="ml-2" data-tooltip="toggle" title="color picker"/>

            </div>
            <div class="dropdown-divider mt-2 mb-2"></div>
            <div v-if="startNavigation" class="search-tag mt-1 pt-2 pb-2 " style="position:relative">
                <div v-if="dataloaded" class="dropdown-search-result-wrapper custom-scrollbar mt-2">
                    <template v-if="suggestTag.length > 0">
                      <a v-for="(tag, index) in suggestTag"
                        :key="index"
                        class="dropdown-item"
                        :class="{'selected' : (search == index)}"
                        @click.prevent="itemClicked(tag.name)" v-html="tag.name.replace(search,'<b>'+search+'</b>')">

                    </a>
                    </template>
                    <template v-else>
                        <b>No matched found</b>
                    </template>
                </div>
                <app-overlay-loader v-else/>
            </div>
        </template>
    </div>
</template>

<script>
    import {FormMixin} from "@core/mixins/form/FormMixin";

    export default {
        name: "TagCreateOrSearch",
        mixins: [FormMixin],
        props: {
            tagList: {
                type: Array
            },
            allTags:{
              required: true
            },
            editable: {
                type: Boolean,
                default: true
            },
            taggableId: {
                type: Number,
                required:true
            },
            tableId: String,
            postUrl: String,
        },
        data() {
            return {
                search: '',
                color:'#a5a5a5',
                suggestTag: [],
                startNavigation: false,
                selected: 0,
                selectedItem: null,
                dataloaded:false

            }
        },

        methods: {
            detachTag(id) {
                this.axiosPut({
                    url: this.postUrl+this.taggableId,
                    data: {tag_id: id}
                })
                    .then(response => {
                        this.$toastr.s(response.data.message);
                        this.$hub.$emit('reload-' + this.tableId);
                        this.$hub.$emit('tag-list');
                    }).catch(({error}) => {
                });
            },

            getAllTags() {

                if (this.search.length > 0) {
                    this.startNavigation = true;
                    this.dataloaded=false;
                    this.suggestTag =[];
                    this.axiosGet(`crm/tags?tag=${this.search}`).then(response => {
                        this.suggestTag = response.data.filter(tag => tag.type === 0); 
                        this.dataloaded=true;
                    }).catch(({error}) => {
                    });
                }else{
                  this.startNavigation = true;
                    this.dataloaded=false;
                    this.suggestTag =[];
                    this.axiosGet(`crm/tags`).then(response => {
                        this.suggestTag = response.data.filter(tag => tag.type === 0);
                        this.dataloaded=true;
                    }).catch(({error}) => {
                    });
                }
            },
            storeTag() {
              if(!this.tagList.find(e => e.name == this.search)){
                        this.attachTag(this.allTags.find(e => e.name == this.search).id);
                    }else{
                        this.$toastr.i('Already exist');
                        this.search = '';
                        this.startNavigation = false;
                    }

            },
            attachTag(id){
                this.axiosPost({
                    url: this.postUrl+this.taggableId,
                    data: {tag_id: id}
                }).then(response => {
                    this.search = '';
                    this.startNavigation = false;
                    this.$toastr.s(response.data.message);
                    this.$hub.$emit('reload-' + this.tableId);
                    this.$hub.$emit('tag-list');
                    this.$store.dispatch('getAllTags')
                }).catch(({error}) => {
                });
            },
            itemClicked(name) {
                this.search = name;
                this.storeTag();
            },

        }
    }
</script>
<style>
    input[type="color"] {
        -webkit-appearance: none;
        border: none;
        width: 24px;
        height: 24px;
        padding:0px;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
        border: none;
    }
</style>
