<template>
  <div class="content-wrapper calendar-position-modified">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <app-breadcrumb
          :page-title="currentPipeline"
          :directory="['tags', 'tags', currentPipeline]"
          :icon="'clipboard'"
        />
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="float-md-right mb-3 mb-sm-3 mb-md-0">


          <button
            type="button"
            class="btn btn-primary btn-with-shadow"
            data-toggle="modal"
            @click.prevent="openModal()"
          >
             Add Tag
          </button>
        </div>
      </div>
    </div>

    <table class="table">
      <tr>
          <th>Title</th>
          <th>Action</th>
      </tr>
      <tr v-for="tag in allTags">
        <td><span class="badge" :style="{'background-color':tag.color_code}"> {{ tag.name }}</span> </td>
        <td>
          <!-- <button class="btn btn-info btn-sm">Edit</button> -->
           <button  v-if="tag.type===0" class="btn btn-danger btn-sm"  @click="deleteTag(tag.id)">Delete</button>
        </td>
      </tr>
    </table>

    <app-modal
        v-if="showModal"
    modal-id="tag-modal"
    modal-size="small"
    modal-alignment="middle"

    @close-modal="closeModal">
    <template slot="header">
      <h5 class="modal-title">Add Tag</h5>
      <button
        type="button"
        class="close outline-none"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span>
          <app-icon :name="'x'"></app-icon>
        </span>
      </button>
    </template>
    <template slot="body">
        <div class="row">
          <div class="col-md-8">
            <input type="text"
                   class="form-control"
                   placeholder="Add Tag Name"
                   v-model="tagName"/>
          </div>
          <div class="col-md-4">

        <input type="color" v-model="color" class="ml-2" data-tooltip="toggle" title="color picker"/>
          </div>
        </div>
        <button class="btn btn-primary mt-2" @click.prevent="addTag()">Add Tag</button>
        </span>
    </template>
  </app-modal>


  </div>
</template>

<script>
import moment from 'moment';
import CoreLibrary from "@core/helpers/CoreLibrary.js";
import { urlGenerator } from "@app/Helpers/helpers";

export default {
  name: "TagListView",
  extends: CoreLibrary,
  data() {
    return {
      urlGenerator,
      tableId: "tag-modal",
      selectedUrl: "",
      showModal:false,
      currentPipeline: "All Tags",
      allTags:[],
      tagName:"",
      color:'#a5a5a5',
    };
  },
  methods: {
    getTags(){
                this.axiosGet('crm/tags').then(response => {
                        this.allTags = response.data;
                    }).catch(({error}) => {
                    });
            },
    addTag(){
      this.axiosPost({
          url: 'crm/tags',
          data: {name: this.tagName,color_code:this.color}
      }).then(response => {
        this.allTags.unshift(response.data);
        this.tagName='';
        this.closeModal();
        $('.modal-backdrop').remove();
        $('html').css('overflow-y','auto');
      })
          .catch(({error}) => {
          });
    },
    deleteTag(id){
      this.axiosDelete(`crm/tags/${id}`).then(response => {
        this.allTags =this.allTags.filter((obj) => obj.id !== id);
        this.$toastr.s('Deleted Tag');
      }).catch(({error}) => {
      });
    },
    openModal() {
            this.showModal = true;
            $('tag-modal').modal("show");
          },
          closeModal() {
            $('tag-modal').modal("hide");
            this.showModal = false;
          },
  },
  mounted() {
    this.getTags();
  },
  created() {
  },
};
</script>
