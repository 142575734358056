<template>
  <div class="card border-0 card-with-shadow mb-primary">
    <div
      class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center"
    >
      <h5 v-show="isDetailsEditable" class="card-title text-muted mb-0">
        {{ $t("details") }}
      </h5>
      <h5 v-show="!isDetailsEditable" class="card-title text-muted mb-0">
        {{ $t("edit_details") }}
      </h5>
      <div>
        <a
          href="#"
          v-show="!isDetailsEditable"
          class="text-muted"
          @click.prevent="showDetailsHide"
        >
          <app-icon stroke-width="1" name="x-square" />
        </a>
        <a
          href="#"
          v-show="!isDetailsEditable"
          class="text-muted"
          @click.prevent="showDetailsSave"
        >
          <app-icon stroke-width="1" name="check-square" />
        </a>

        <a
          href="#"
          v-if="dealOpen"
          v-show="isDetailsEditable"
          class="text-muted"
          @click.prevent="showDetailsEditable"
        >
          <app-icon stroke-width="1" name="edit" />
        </a>
      </div>
    </div>
    <div class="card-body">
      <div v-show="isDetailsEditable">
        <div class="form-group">
          <div class="form-row">
            <label class="mb-0 text-muted col-4 d-flex align-items-center">
              {{ $t("title") }}
            </label>
            <div class="col-8">
              {{ dealDetails.title }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-row">
            <label class="mb-0 text-muted col-4 d-flex align-items-center">
              No of Pax
            </label>
            <div class="col-8">
              {{ dealDetails.no_of_pax }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="form-row" v-if="dealDetails.status">
            <label class="mb-0 text-muted col-4 d-flex align-items-center">
              {{ $t("status") }}
            </label>
            <div class="col-8">
              <app-badge
                :label="dealDetails.status.translated_name"
                :className="'badge-sm badge-pill badge-' + dealDetails.status.class"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-row">
            <label class="mb-0 text-muted col-4 d-flex align-items-center">
              {{ $t("expected_closing_date") }}
            </label>
            <div class="col-8 text-muted">
              {{
                dealDetails.expired_at == null
                  ? $t("not_added_yet")
                  : formatDateToLocal(dealDetails.expired_at)
              }}
            </div>
          </div>
        </div>
        <div class="form-group mb-0">
          <div class="form-row">
            <label class="mb-0 text-muted col-5 d-flex align-items-center">
              {{ $t("owner") }}
            </label>
            <div class="col-7" v-if="dealDetails.owner">
              <div class="d-flex align-items-center">
                <app-avatar
                  :title="dealDetails.owner.full_name"
                  class="mr-2"
                  :avatar-class="'avatars-w-30'"
                  :img="
                    dealDetails.owner.profile_picture
                      ? urlGenerator(dealDetails.owner.profile_picture.path)
                      : dealDetails.owner.profile_picture
                  "
                />
                <span>{{ dealDetails.owner.full_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="!isDetailsEditable">
        <form ref="form">
          <div class="form-group">
            <div class="form-row">
              <label class="col-4 mb-0 d-flex align-items-center">{{
                $t("title")
              }}</label>
              <div class="col-8">
                <app-input type="text" v-model="dealDetails.title" />
              </div>
            </div>
          </div>
          <div class="form-group">
          <div class="form-row">
            <label class="mb-0 text-muted col-4 d-flex align-items-center">
              No of Pax
            </label>
            <div class="col-8">
              <app-input type="text" v-model="dealDetails.no_of_pax" />
            </div>
          </div>
        </div>
          <div class="form-group">
            <div class="form-row">
              <label class="col-4 mb-0 d-flex align-items-center">{{
                $t("expected_closing_date")
              }}</label>
              <div class="col-8">
                <app-input type="date" v-model="formData.expired_at" />
              </div>
            </div>
          </div>
          <div class="form-group mb-0">
            <div class="form-row">
              <label class="col-5 mb-0 d-flex align-items-center">{{
                $t("owner")
              }}</label>
              <div class="col-7" v-if="dealDetails.owner">
                <app-input
                  type="select"
                  list-value-field="full_name"
                  :list="ownerList"
                  v-model="dealDetails.owner_id"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { FormMixin } from "@core/mixins/form/FormMixin.js";
import { formatDateToLocal, urlGenerator } from "@app/Helpers/helpers";

export default {
  name: "DealDetails",
  props: ["dealDetails", "statusList", "ownerList"],
  mixins: [FormMixin],
  data() {
    return {
      urlGenerator,
      isDetailsEditable: true,
      formData: {},
      formatDateToLocal,
    };
  },
  computed: {
    dealOpen() {
      return this.dealDetails.status.name == "status_open";
    }
  },
  methods: {
    showDetailsHide() {
      this.isDetailsEditable = true;
    },
    showDetailsSave() {
      this.formData.title = this.dealDetails.title;
      this.formData.no_of_pax = this.dealDetails.no_of_pax;
      this.formData.expired_at = this.formData.expired_at ?
        moment(this.formData.expired_at).format("YYYY-MM-DD") :
        null;
      this.formData.owner_id = this.dealDetails.owner_id;
      this.submitFromFixin("PATCH", `crm/deals/${this.dealDetails.id}`, this.formData);
    },

    afterSuccess(response) {
      this.$toastr.s(response.data.message);
      this.isDetailsEditable = true;
      this.$emit("update-request");
    },

    showDetailsEditable() {
      this.isDetailsEditable = false;
    },
  },
  mounted() {
    this.formData.expired_at = this.dealDetails.expired_at
      ? new Date(this.dealDetails.expired_at)
      : null;
  },
};
</script>
