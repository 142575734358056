<template>
  <app-modal modal-id="manage-user-role" :modal-scroll="modalScroll" modal-size="default" modal-alignment="top"
             @close-modal="closeModal">
    <template slot="header">
      <h5 class="modal-title">{{ $t('manage_users') }}</h5>
      <button type="button" class="close outline-none" data-dismiss="modal" aria-label="Close">
                <span>
                    <app-icon :name="'x'"></app-icon>
                </span>
      </button>
    </template>
    <template slot="body">
      <app-overlay-loader v-if="preloader"/>
      <template v-else>
          <app-input type="multi-select"
                     :list="roleList"
                     list-value-field="name"
                     v-model="formData.roles"
                     :required="true"
                     :is-animated-dropdown="true"/>
      </template>
    </template>
    <template slot="footer">
      <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal"
              @click.prevent="closeModal">
        {{ $t('cancel') }}
      </button>
      <button type="button" class="btn btn-primary" @click.prevent="submitData">
                        <span class="w-100">
                            <app-submit-button-loader v-if="loading"></app-submit-button-loader>
                        </span>
        <template v-if="!loading">{{ $t('save') }}</template>
      </button>
    </template>

  </app-modal>
</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin.js";

export default {
  name: "ManageUserRoleModal",
  mixins: [FormMixin],
  props: {
    user: {
      required: true
    },
    tableId: String,
    modalScroll: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      roleList: [],
      formData: {
        roles: []
      },
      loading: false,
      preloader: false,
    }
  },
  methods: {
    beforeSubmit() {
      this.loading = true;
      this.preloader = true;
    },
    submitData() {
      this.submitFromFixin('post', `admin/auth/users/attach-roles/${this.user}`, this.formData)
    },
    afterError(response){
      this.$toastr.e(response.data.message)
    },
    afterSuccess(response) {
      this.$toastr.s(response.data.message)
      this.$hub.$emit('reload-' + this.tableId)
      this.$hub.$emit('reload-role-modal')
    },

    afterFinalResponse() {
      this.loading = false;
      this.preloader = false;
      this.closeModal();
    },

    afterSuccessFromGetEditData({data}) {
      this.formData.roles = this.collection(data.roles).pluck();
      this.preloader = false;
    },
    closeModal(value) {
      this.$emit('close-modal', value);
    },

    getAllRoles() {
      this.axiosGet('admin/auth/roles').then(({data}) => {
        this.roleList = data.data
      })
    },
  },
  mounted() {
    this.getAllRoles();
    if (this.user) {
      this.getEditData(`admin/auth/users/${this.user}`);
    }
  }
}
</script>

