<template>

    <div class="content-wrapper">
        <app-breadcrumb page-title="Page title" :directory="['Directory','Sub-directory']" :icon="'grid'"
                        :button="{label:'Home',url:'/'}"/>
        <div class="card card-with-shadow border-0">
            <div class="card-body">
                <form ref="form" data-url="test-component" enctype="multipart/form-data">
                    <!--<div class="form-group">
                      <app-input type="time"
                                 v-model="testFields.timeValue"/>
                  </div>

                                   Disabled inputs here
                  <h3 class="text-center">All field's are disabled!</h3>


                   <div class="form-group">
                      <app-input label="smart-select"
                                 type="smart-select"
                                 :list="multiSelectOptionList"
                                 v-model="smartSelect"/>
                  </div>

                  <div class="form-group">
                      <app-input type="text"
                                 @focusin="focusInShow"
                                 @focusout="focusOutShow"
                                 @click="showClickEvent"
                                 @input="showInputEventValue"
                                 v-model="testFields.textValue"/>
                  </div>

                  <div class="form-group">
                      <app-input type="date"
                                 v-model="dateValue"/>
                  </div>-->

                    <!--<div class="form-group">
                        <app-input type="checkbox"
                                   v-model="checkboxValue"
                                   :list="multiSelectOptionList"
                        />
                    </div>

                    <div class="form-group">
                        <app-input type="currency"
                                   @focusin="focusInShow"
                                   @mousemove="mouseMoveShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="currency"/>
                    </div>

                    <div class="form-group">
                        <app-input type="decimal"
                                   @focusin="focusInShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="decimalNumber"/>
                    </div>

                    <div class="form-group">
                        <app-input type="email"
                                   @focusin="focusInShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="testFields.emailValue"/>
                    </div>

                    <div class="form-group">
                        <app-input type="number"
                                   @focusin="focusInShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="testFields.phone"/>
                    </div>

                    <div class="form-group">
                        <app-input type="password"
                                   @focusin="focusInShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="testFields.password"/>
                    </div>

                    <div class="form-group">
                        <app-input type="radio"
                                   radio-checkbox-name="test"
                                   @focusin="focusInShow"
                                   @mousemove="mouseMoveShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="radioBtn"/>
                    </div>

                    <div class="form-group">
                        <app-input type="radio-buttons"
                                   @focusin="focusInShow"
                                   @mousemove="mouseMoveShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="radioButtons"/>
                    </div>

                    <div class="form-group">
                        <app-input type="select"
                                   @focusin="focusInShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="selectValue"/>
                    </div>

                    <div class="form-group">
                        <app-input type="textarea"
                                   @focusin="focusInShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="textarea"/>
                    </div>

                    <div class="form-group">
                        <app-input type="file"
                                   @focusin="focusInShow"
                                   @mousemove="mouseMoveShow"
                                   @focusout="focusOutShow"
                                   @click="showClickEvent"
                                   @input="showInputEventValue"
                                   v-model="fileValue"
                                   :label="$t('choose_file')"/>
                    </div>


                    <div class="form-group">
                        <app-input type="date"
                                   :disabled="true"
                                   date-mode="range"
                                   v-model="dateValue"/>
                    </div>

                    <div class="form-group">
                        <app-input type="time"
                                   v-model="testFields.timeValue"/>
                    </div>

                    <div class="form-group">
                        <app-input type="multi-select"
                                   v-model="multiSelect"
                                   :list="multiSelectOptionList"
                        />
                    </div>

                    <div class="form-group">
                        <app-input type="text-editor"
                                   v-model="textEditor"/>
                    </div>
                    <div class="form-group">
                        <app-input type="custom-file-upload"
                                   :label="$t('change_image')"
                                   v-model="customFile"/>
                    </div>

                    <div class="form-group">
                        <app-input type="dropzone"
                                   v-model="dropzoneValue"/>
                    </div>
                    <div class="form-group">
                        <app-input type="custom-file-upload"
                                   :label="$t('change_image')"
                                   v-model="customFile"/>
                    </div>-->
                    <div class="form-group">
                        <app-input type="checkbox"
                                   v-model="checkboxValue"
                                   :list="multiSelectOptionList"
                        />
                    </div>
                    <div class="form-group">
                        <app-input type="radio"
                                   :list="multiSelectOptionList"
                                   radio-checkbox-name="test"
                                   v-model="radioBtn"/>
                    </div>
                    <div class="form-group">
                        <app-input type="select"
                                   v-model="selectValue"/>
                    </div>
                    <div class="form-group">
                        <app-input type="tel-input" v-model="testNumber"
                                   placeholder="Enter phone number"
                                   :required="true"/>
                    </div>
                    <div class="form-group">
                        <app-input type="search-select" v-model="searchSelectValue"
                                   placeholder="Search and select"
                                   :required="true"/>
                    </div>
                    <div class="form-group">
                        <app-input type="custom-file-upload"
                                   :label="$t('change_image')"
                                   v-model="customFile"/>
                    </div>
                    <div class="form-group">
                        <app-input type="date"
                                   date-mode="dateTime"
                                   v-model="dateTimeValue"/>
                    </div>
                    <div class="form-group">
                        <app-input type="date"
                                   date-mode="date"
                                   v-model="dateValue"/>
                    </div>

                    <div class="form-group">
                        <app-input type="switch"
                                   :label="$t('Switch')"
                                   v-model="switchValue"/>
                    </div>
                    <div class="form-group">
                        <app-input type="multi-select"
                                   v-model="multiSelect"
                                   :list="multiSelectOptionList"
                        />
                    </div>
                    <button class="btn btn-primary" type="submit" @click.prevent="submitData">submit</button>
                    <button class="btn btn-secondary" type="button" @click.prevent="changeData">Change Value(s)</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {FormMixin} from "../../mixins/form/FormMixin.js";

export default {
    name: "AddEditForm",
    mixins: [FormMixin],
    data() {
        return {
            switchValue: false,
            searchSelectValue: '',
            testNumber: '',
            test: "",
            testName: "",
            testPass: "",
            testPass1: "",
            decimalNumber: 300,
            emailErrorMessage: "",
            errorMessage: "",
            imageUrl: "/images/core.png",
            showError: false,
            dataLoaded: false,
            isRange: false,
            dateValue: new Date(),
            dateTimeValue: new Date(),
            rangeValue: {
                start: new Date(),
                end: new Date()
            },
            timeValue: "12:00",
            smartSelect: 1,
            selectValue: 2,
            multiSelect: [1, 3],
            textarea: "This is fardin",
            currency: 5000,
            radioButtons: 3,
            radioBtn: 1,
            checkboxValue: [1, 2],
            textEditor: "<h1>hi there </h1>",
            fileValue: "",
            customFile: '',
            dropzoneValue: [
                '/images/error-401.png',
                '/images/error-403.png',
                '/images/error-404.png',
            ],
            testFields: {
                timeValue: "12:00",
                phone: 1796426502,
                textValue: "Sani",
                emailValue: 'sani@gain.media',
                confirmedPassword: "",
                password: "",
                password2: "",
                date1: new Date("2020-04-08"),
                date2: null
            },
            testArray: [
                {
                    name: "fardin",
                },
                {
                    name: "ahsan",
                },
            ],
            passwordArr: [
                {
                    password: "",
                },
                {
                    password: "",
                },
            ],
            multiSelectOptionList: [
                {id: 1, value: 'Cricket'},
                {id: 2, value: 'Football', disabled: true},
                {id: 3, value: 'Badminton'},
                {id: 4, value: 'Option 4'},
                {id: 5, value: 'Option 5'},
                {id: 6, value: 'Option 6'},
                {id: 7, value: 'Option 6'},
                {id: 8, value: 'Option 6'},
                {id: 9, value: 'Option 6'},
                {id: 10, value: 'Option 6'},
            ]
        };
    },
    created() {
        // this.dateValue = new Date("2020-04-08");
        this.getData();
        // console.log(this.$store.settings, "store");
    },
    methods: {
        changeData() {
            this.searchSelectValue = 3;
            this.isRange = true;
        },
        focusInShow() {
            console.log('Focused In');
        },
        mouseMoveShow() {
            console.log('mouseMoveShow text');
        },
        focusOutShow() {
            console.log('Focused Out');
        },
        showClickEvent() {
            console.log('This is click event');
        },
        showInputEventValue(value) {
            console.log('Value is : ' + value);
        },
        submitData() {
            //     this.fieldStatus.isSubmit = true;
            //     if (this.test.length > 6) {
            let formData = new FormData();
            formData.append("custom_file", this.customFile);
            formData.append("dropzoneValue", this.dropzoneValue);

            console.log(this.customFile, 'custom');
            console.log(formData, 'custom');

            this.save(formData);

            // } else {
            //     this.fieldStatus['testFields_textValue'] = {
            //         isValid: false,
            //         message: "Length should more then 6"
            //     }
            // }
        },
        //hooks
        beforeSubmit(fields) {
            let formData = new FormData();

            // fields.dropzoneValue.map((value, index) => {
            //   formData.append("dropzoneValue[]", fields.dropzoneValue[index]);
            // });
            // // console.log(fields.checkbox, typeof fields.checkbox);
            // fields.checkbox.map((value, index) => {
            //   formData.append("checkbox[]", fields.checkbox[index]);
            // });
            // formData.append("radio", fields.radio);
            // formData.append("currency", fields.currency);
            // formData.append("custom-file-upload", fields["custom-file-upload"]);
            // formData.append("date", fields.date);
            // formData.append("decimal", fields.decimal);
            // formData.append("email", fields.email);
            // formData.append("multi-select", fields["multi-select"]);
            // formData.append("number", fields.number);
            // formData.append("password", fields.password);
            // formData.append("radio-buttons", fields["radio-buttons"]);
            // formData.append("selectValue", fields.selectValue);
            // formData.append("smart-select", fields["smart-select"]);
            // formData.append("text", fields.text);
            // formData.append("text-editor", fields["text-editor"]);
            // formData.append("textarea", fields.textarea);
            // formData.append("timeValue", fields.timeValue);

            // formData.append("checkbox[]", fields.checkbox);
            //   console.log(formData);
            // return formData;
        },
        afterSubmit() {
        },

        afterSuccess(res) {

            console.log(res, "success");
            this.customFile = ''
        },

        afterError(res) {
            this.testName = 3;
            this.showError = true;
            this.errorMessage = "Given email is invalid";
        },
        getData() {
            let instance = this,
                url = "test-component";
            instance
                .axiosGet(url)
                .then(response => {
                    instance.dataLoaded = true;
                    this.fields = response.data;

                    this.customFile = response.data.customFile;
                    console.log(this.customFile);
                })
                .catch(({response}) => {
                    if (instance.isFunction(instance.afterErrorFromGetEditData))
                        instance.afterErrorFromGetEditData(response);
                });
        }
    }
};
</script>
