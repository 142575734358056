<template>
  <div>
    <app-overlay-loader v-if="!dataLoaded"/>
    <div class="content-wrapper" v-else>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <app-breadcrumb
              :page-title="name"
              :directory="[$t('contacts'), name]"
              :icon="'users'"
              :button="{label: $t('back'), url:urlGenerator(`crm/org/list`)}"
          />
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
            <button
                type="button"
                class="btn btn-primary btn-with-shadow"
                data-toggle="modal"
                @click="openDealModal()"
            >{{ $t('add_deal') }}
            </button>
          </div>
        </div>
      </div>

      <component is="app-user-info-details"
                 :user-info-sync-url="`crm/organizations/profile-picture/${formData.id}`"
                 :user-info-data="formData"
                 component-type="org"
                 @update-request="updateRequest"/>

      <div class="row">

        <div class="col-xl-4">

          <app-details-information
            class="mb-primary"
            :details-data="formData"
            :details-sync-url="`crm/organizations/${formData.id}`"
            @update-request="updateRequest"/>

	        <component is="app-details-job-title"
		        class="mb-primary"
		        v-if="isComponent"
		        :job-title-data="formData.persons"
		        :job-title-url-sync="`crm/organizations/sync/${formData.id}`"
		         component-type="organization"
		        :data-list="peopleList"
		        @update-request="updateRequest"/>

	        <app-address-details
		        class="mb-primary"
		        :address-details="formData"
		        :address-update-url="`crm/organizations/${formData.id}`"
		        @update-request="updateRequest"
	        />

          <component is="app-details-deals"
                     :Deals="formData.deals"
                     :contact-list="peopleList"
                     :Id="formData.id"
                     :total-deal="formData.total_deals"
                     component-type="org"
                     class="mb-primary"
          />

          <tags-organization
              class="mb-primary"
              :tagData="formData.tags"
              :organization="formData.id"
          />

	        <app-follower-details
		        class="mb-primary"
		        :follower-data="formData"
		        :follower-sync-url="`crm/organizations/followers/${formData.id}`"
		        :get-follower-url="`crm/organizations/${formData.id}/followers`"
		        :people-list="peopleList"
		        @update-request="updateRequest"/>

        </div>

        <div class="col-xl-8">
          <app-tab class="mb-primary" type="horizontal" :tabs="todoActivityTab" v-if="appTapShow"/>
          <app-common-activity-show
            :Data="formData"
            :edit-url="selectedUrl"
            :activity-filter-url="`crm/organization/activities/`"
            :file-note-url="`crm/organization`"
            component-type="org"
            v-if="isComponent"
            @open-activity="editActivity"
            @open-note-modal="editNote"
          />
        </div>
      </div>
      <app-deal-modal
          v-if="isDealModalActive"
          :pre-selected-option="{'organization_id':formData.id}"
          component-type="org"
          :selectedUrlId="formData.id"
          @close-modal="closeDealModal"
      />

      <app-common-activity-modal
          v-if="orgActivitiesModal"
          :activity="rowData"
          :selected-url="editedUrl"
          @close-modal="closeActivityModal"
      />

      <app-common-note-modal v-if="isNoteModal" :note="noteRowData" @close-modal="closeNoteModal"/>
    </div>
  </div>
</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin.js";
import {formatDateToLocal, textTruncate, onlyTime, onlyTimeFromTime, urlGenerator} from "@app/Helpers/helpers";
import {collect} from "@app/Helpers/Collection";


export default {
  props: ["selectedUrl"],
  name: "OrganizationView",
  mixins: [FormMixin],
  data() {
    return {
        urlGenerator,
      dataLoaded: false,
      isDealModalActive: false,
      isComponent: true,
      formData: {},
      editedUrl: "",
      name: "",
      appTapShow: false,
      isfileNoteFilter: true,
      todoActivityTab: [
        {
          name: "activity",
          icon: "activity",
          component: "common-activity-tab",
          props: "",
          permission: "",
        },
        {
          name: "file",
          icon: "paperclip",
          component: "common-file-tab",
          props: "",
          permission: "",
        },
        {
          name: "note",
          icon: "file-text",
          component: "common-note-tab",
          props: "",
          permission: "",
        },
      ],

      activitiesList: [],
      rowData: null,
      orgActivitiesModal: false,
      dealNoteModal: false,
      isNoteModal: false,
      noteRowData: null,
      value: "",
      formatDateToLocal,
      onlyTimeFromTime,
      onlyTime,
      textTruncate
    };
  },
  computed: {
      peopleList() {
        return this.$store.getters.getPerson
      },
    },
  methods: {
    afterSuccessFromGetEditData(response) {
      this.formData = response.data;
      this.name = this.formData.name;
      this.todoActivityTab.forEach((el) => {
        el["props"] = {
          id: this.formData.id,
          organization: "organization",
          contextable_type: "App\\Models\\CRM\\Organization\\Organization",
          activitySyncUrl: `crm/organization/activities/sync/${this.formData.id}`,
          fileSyncUrl: `crm/organization/file/sync/${this.formData.id}`,
          noteSyncUrl: `crm/organization/note/sync/${this.formData.id}`,
        };
      });

      this.appTapShow = true;
      this.dataLoaded = true;
    },
    updateRequest() {
      this.isComponent = false;
      this.axiosGet(this.selectedUrl)
          .then((response) => {
            this.formData = response.data;
            this.name = this.formData.name;
            this.isComponent = true;
          })
          .catch((error) => console.log(error));
    },
    editActivity(activity) {
      this.editedUrl = `crm/activities/${activity.id}`;
      this.rowData = activity;
      this.orgActivitiesModal = true;
    },
    openDealModal() {
      this.isDealModalActive = true;
      setTimeout(() => {
        $("#deal-modal").modal("show");
      });
    },
    closeDealModal() {
      this.updateRequest();
      this.isDealModalActive = false;
      $("#deal-modal").modal("hide");
    },
    closeActivityModal() {
      this.orgActivitiesModal = false;
      $("#common-activity-modal").modal("hide");
    },

    updateTagList() {
      this.$hub.$on("tag-list", (value = true) => {
        if (value) {
          this.updateRequest();
        }
      });
    },
    updateDealList() {
      this.$hub.$on('deal-update-list', (value = true) => {
        if (value) {
          this.updateRequest();
        }
      })
    },
    editNote(activity) {
      this.isNoteModal = true;
      this.noteRowData = activity;
      setTimeout(() => {
        $("#note-modal").modal("show");
      });
    },
    closeNoteModal() {
      this.isNoteModal = false;
      $("#note-modal").modal("hide");
    },
  },

  mounted() {
    this.$store.dispatch("getPerson");
    this.$store.dispatch("getOrganization");
    this.updateTagList();
    this.updateDealList();
  },
};
</script>
