<template>
  <div class="content-wrapper">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <app-breadcrumb
          :page-title="$t('people')"
          :directory="[$t('contacts'), $t('people')]"
          :icon="'users'"
        />
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="text-sm-right mb-primary mb-sm-0 mb-md-0">
          <div class="dropdown d-inline-block btn-dropdown">
            <button
              type="button"
              class="btn btn-success dropdown-toggle ml-0 mr-2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("actions") }}
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/person/import`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'download'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("import_people") }}
              </a>
              <a
                class="dropdown-item d-flex align-items-center p-3"
                href=""
                @click.prevent="openContactModal()"
              >
                <app-icon
                  stroke-width="1"
                  :name="'plus-circle'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("add_leads_group") }}
              </a>
              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/contact/type/list`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'settings'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("manage_lead_group") }}
              </a>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-primary btn-with-shadow"
            data-toggle="modal"
            @click="openModal()"
          >
            {{ $t("add_person") }}
          </button>
        </div>
      </div>
    </div>
    <app-table :id="tableId" :options="options" @action="getAction" />

    <app-person-modal
      v-if="isPersonModalActive"
      :table-id="tableId"
      :selected-url="selectedUrl"
      @openOrgModal="openOrganizationModal"
      @close-modal="closeModal"
    />
    <app-contact-type-modal
      v-if="isContactModalActive"
      :table-id="contacttableId"
      @close-modal="closeContactModal"
    />
    <app-confirmation-modal
      v-if="confirmationModalActive"
      modal-id="person-delete-modal"
      :dealCount="dealCount"
      @confirmed="confirmed"
      @cancelled="cancelled"
    />


    <app-person-quick-view
      v-if="isQuickViewModalActive"
      dal-id="quickViewModal"
      :selected-url="selectedUrl"
      @openActivityModal="commonActivityModal"
      @viewAllDeal="openViewAllDeal"
      @viewAllFollower="openViewAllFollower"
      @editNote="editNote"
      @close-modal="closeQuickViewModal"
    />

    <app-common-activity-modal
      v-if="personActivitiesModal"
      :activity="rowData"
      :selected-url="editedUrl"
      @close-modal="closeActivityModal"
    />

    <app-common-all-deals
      v-if="viewAllDeal"
      :id="personId"
      :context-type="'person'"
      :table-id="'details-view-modal'"
      @close-modal="closeViewAllModal"
    />
    <app-common-all-follower
      v-if="viewAllFollower"
      :follower-data="followerUrl"
      @close-modal="closedFollowerModal"
    />
    <app-common-note-modal
      v-if="isNoteModal"
      :note="noteRowData"
      @close-modal="closeNoteModal"
    />
  </div>
</template>
<script>
import CoreLibrary from "@core/helpers/CoreLibrary.js";
import { collection, urlGenerator } from "@app/Helpers/helpers";
import { mapGetters } from "vuex";
import { owner, contactType, organization, tag } from "@app/Mixins/Global/FilterMixins";
import { getCustomFileds } from "@app/Mixins/Global/CustomFieldMixin";
import { DeleteMixin } from "@app/Mixins/Global/DeleteMixin";
export default {
  name: "Person",
  extends: CoreLibrary,
  mixins: [owner, contactType, organization, tag, getCustomFileds, DeleteMixin],
  data() {
    return {
      urlGenerator,
      isPersonModalActive: false,
      isContactModalActive: false,
      tableId: "person-modal",
      contacttableId: "contact-modal",
      confirmationModalActive: false,
      isQuickViewModalActive: false,
      organizationModal: false,
      personActivitiesModal: false,
      editedUrl: "",
      viewAllDeal: false,
      viewAllFollower: false,
      isNoteModal: false,
      noteRowData: null,
      personId: null,
      selectedUrl: "",
      activityData: {},
      followerUrl: "",
      commonColumn: [
        {
          title: this.$t("name"),
          type: "custom-html",
          key: "name",
          modifier: (value, row) => {
            let url =urlGenerator(`crm/persons/${row.id}/edit`);
             if(row.seen_status === 0){
              return `<a href="${url}">
                      ${row.name} <span class="badge badge-pill badge-warning"> New</span>
                    </a>`;
            }else{
                 return `<a href="${url}">
                      ${row.name}
                    </a>`;
            }
          },
        },
        {
          title: this.$t("lead_groups"),
          type: "custom-html",
          key: "contact_type",
          modifier: (value, row) => {
            return value
              ? `<span class="badge badge-pill badge-${value.class ?? "secondary"}">${
                  value.name
                }</span>`
              : "-";
          },
        },
        {
          title: this.$t("emails"),
          type: "custom-html",
          key: "email",
          modifier: (email) => {
            let mailList = email.length ? collection(email).get("value", "type") : [];
            let returnResult = ``;
            mailList.map((el, index) => {
              if (index < 2) {
                returnResult += `<span class="mb-1 badge badge-round badge-light">${el.value}`;
                returnResult += el.type
                  ? `
                                    <span class="ml-1 py-1 px-2 badge badge-round badge-${el.type.class}">
                                        ${el.type.name}
                                    </span>`
                  : ``;
                returnResult += `</span><br>`;
              }
            });
            if (mailList.length > 2) {
              let moreCount = mailList.length - 2;
              returnResult += `<small><b>+${moreCount} more</b></samll>`;
            }
            return mailList.length ? returnResult : "-";
          },
        },
        // {
        //   title: this.$t("phones"),
        //   type: "custom-html",
        //   key: "phone",
        //   modifier: (phone) => {
        //     let phoneList = phone.length ? collection(phone).get("value", "type") : [];
        //     let returnResult = ``;
        //     phoneList.map((el, index) => {
        //       if (index < 2) {
        //         returnResult += `<span class="mb-1 badge badge-round badge-light">${el.value}`;
        //         returnResult += el.type
        //           ? `
        //                             <span class="ml-1 py-1 px-2 badge badge-round badge-${el.type.class}">
        //                                 ${el.type.name}
        //                             </span>`
        //           : ``;
        //         returnResult += `</span><br>`;
        //       }
        //     });
        //     if (phoneList.length > 2) {
        //       let moreCount = phoneList.length - 2;
        //       returnResult += `<small><b>+${moreCount} more</b></samll>`;
        //     }
        //     return phoneList.length ? returnResult : "-";
        //   },
        // },
        // {
        //   title: this.$t("address"),
        //   type: "component",
        //   isVisible: true,
        //   componentName: "app-common-address",
        // },
        {
          title: this.$t("closed_deal"),
          type: "text",
          key: "closed_deals",
        },
        {
          title: this.$t("opened_deal"),
          type: "text",
          key: "open_deals",
        },
        {
          title: this.$t("owner"),
          type: "object",
          key: "owner",
          modifier: (value, row) => {
            return value ? value.full_name : "";
          },
        },
        {
          title: this.$t("tags"),
          type: "component",
          key: "tags",
          isVisible: true,
          componentName: "tags-type-column",
        },
        {
          title: "Action",
          type: "action",
          key: "invoice",
          isVisible: true,
        },
      ],
      options: {
        name: this.$t("person_table"),
        url: "crm/persons",
        showHeader: true,
        columns: [],
        filters: [
          {
            title: this.$t("created_date"),
            type: "range-picker",
            key: "date",
            option: ["today", "thisMonth", "last7Days", "thisYear"],
          },
          {
            title: this.$t("owner"),
            type: "checkbox",
            key: "owner_is",
            option: [],
          },
          {
            title: this.$t("lead_group"),
            type: "checkbox",
            key: "contact_type",
            option: [],
          },
          {
            title: this.$t("tag"),
            type: "multi-select-filter",
            key: "tags",
            option: [],
          },
        ],
        showSearch: true,
        showFilter: true,
        paginationType: "pagination",
        responsive: true,
        rowLimit: 20,
        showAction: true,
        orderBy: "desc",
        actionType: "dropdown",
        actions: [
          {
            title: this.$t("quick_view"),
            icon: "zap",
            type: "modal",
            component: "app-person-quick-view",
            modalId: "quickViewModal",
            url: "",
          },
          {
            title: this.$t("edit"),
            icon: "edit",
            type: "modal",
            component: "app-person-modal",
            modalId: "person-modal",
            url: "",
          },
          {
            title: this.$t("delete"),
            icon: "trash",
            type: "modal",
            component: "app-confirmation-modal",
            modalId: "person-delete-modal",
            dealCount: "0",
            url: "",
          },
        ],
      },
    };
  },
  methods: {
    getAction(rowData, actionObj, active) {
      if (actionObj.title == this.$t("edit")) {
        this.selectedUrl = `crm/persons/${rowData.id}`;
        this.isPersonModalActive = true;
      } else if (actionObj.title == this.$t("delete")) {
        this.deleteUrl = `crm/persons/${rowData.id}`;
        this.dealCount = `${rowData.total_deals}`;
        this.confirmationModalActive = true;
      } else if (actionObj.title == this.$t("quick_view")) {
        this.selectedUrl = `crm/person/navigation-change/${rowData.id + 1}?parm=previous`;
        this.isQuickViewModalActive = true;
      }
    },
    openModal() {
      this.isPersonModalActive = true;
    },
    openContactModal() {
      this.isContactModalActive = true;
    },
    closeContactModal() {
      this.isContactModalActive = false;
      this.selectedUrl = "";
      $("#contact-type-modal").modal("hide");
    },
    closeModal() {
      this.isPersonModalActive = false;
      this.selectedUrl = "";
      $("#person-modal").modal("hide");
    },
    closeQuickViewModal() {
      this.isQuickViewModalActive = false;
      this.selectedUrl = "";
      $("#quickViewModal").modal("hide");
    },
    commonActivityModal(activity) {
      this.editedUrl = `crm/activities/${activity.id}`;
      this.rowData = activity;
      this.personActivitiesModal = true;
    },
    openViewAllDeal(personId) {
      this.personId = personId;
      this.viewAllDeal = true;
    },
    openViewAllFollower(followerUrl) {
      this.followerUrl = followerUrl;
      this.viewAllFollower = true;
    },
    editNote(activity) {
      this.noteRowData = activity;
      this.isNoteModal = true;
    },
    closeNoteModal() {
      this.isNoteModal = false;
      $("#note-modal").modal("hide");
    },
    closeViewAllModal() {
      this.viewAllDeal = false;
      $("#details-view-modal").modal("hide");
    },
    closedFollowerModal() {
      this.viewAllFollower = false;
      $("#details-view-modal").modal("hide");
    },

    closeActivityModal() {
      this.personActivitiesModal = false;
      $("#common-activity-modal").modal("hide");
    },
    openOrganizationModal() {
      this.organizationModal = true;
      setTimeout(() => {
        $("#person-modal").css({
          //"backdrop-filter": "blur(4px)"
          opacity: "0.5",
        });
      });
    },
    closeOrgModal() {
      this.$store.dispatch("getOrganization");
      $("#organization-modal").modal("hide");
      this.organizationModal = false;
      $("#person-modal").css({
        opacity: "1",
        //"backdrop-filter": "blur(0px)"
      });
    },
  },
  computed: {
    ...mapGetters({
      organizationList: "getOrganization",
    }),
  },
  mounted() {
    this.getCustomFiled("person");
  },
};
</script>
<style>
.link-list {
  white-space: nowrap !important;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
