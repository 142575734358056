<template>
  <app-modal modal-id="manage-user" :modal-scroll="modalScroll" modal-size="default" modal-alignment="top"
             @close-modal="closeManageUserModal">
    <template slot="header">
      <h5 class="modal-title">{{ $t('manage_users') }}</h5>
      <button type="button" class="close outline-none" data-dismiss="modal" aria-label="Close">
                <span>
                    <app-icon :name="'x'"></app-icon>
                </span>
      </button>
    </template>
    <template slot="body">
      <app-overlay-loader v-if="preloader"/>
      <template v-else>
        <div v-for="(user, index) in user_list" :key="index"
             class="d-flex align-items-center justify-content-between"
             :class="{'pb-3 mb-3 border-bottom': index !== user_list.length - 1}">
          <div class="media d-flex align-items-center">
            <div class="avatars-w-50 ml-2">
              <app-avatar
                  :title="user.full_name"
                  status="success"
                  :shadow="true"
                  :img="user.profile_picture ?
                                        user.profile_picture.path :
                                        user.profile_picture"
                  :alt-text="user.full_name"
              />
            </div>
            <div class="media-body ml-3">
              {{ user.full_name }}
              <p class="text-muted font-size-90 mb-0">{{ user.email }}</p>
            </div>
          </div>
          <div>
            <a href="#" @click.prevent="removeUser(user, index)"
               class="d-flex align-items-center text-muted font-size-90">
              <app-icon name="trash" class="mr-1" width="19"/>
            </a>
          </div>
        </div>
        <form ref="form" :data-url="`admin/auth/roles/${role.id}/attach-users`">
          <div class="mt-primary">
            <app-input type="multi-select"
                       :list="attachableUsers"
                       list-value-field="full_name"
                       v-model="formData.users"
                       :required="true"
                       :is-animated-dropdown="true"
            />
          </div>
        </form>
      </template>
    </template>
    <template slot="footer">
      <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal"
              @click.prevent="closeManageUserModal">
        {{ $t('cancel') }}
      </button>
      <button type="button" class="btn btn-primary" @click.prevent="submitData">
                        <span class="w-100">
                            <app-submit-button-loader v-if="loading"></app-submit-button-loader>
                        </span>
        <template v-if="!loading">{{ $t('save') }}</template>
      </button>
    </template>

  </app-modal>
</template>

<script>

import {FormMixin} from "@core/mixins/form/FormMixin.js";

export default {

  name: "ManageUserModal",
  mixins: [FormMixin],
  props: {
    role: {
      required: true
    },
    tableId: String,
    modalScroll: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      user_list: [],
      formData: {
        users: []
      },
      loading: false,
      preloader: false,
    }
  },
  computed: {
    attachableUsers() {
      return this.$store.getters.getUsers
    },
  },

  methods: {
    submitData() {
      this.save(this.formData);
    },
    afterSuccess(response) {
      this.user_list = this.attachableUsers.filter(user => {
        return this.formData.users.includes(user.id);
      }).concat(this.user_list);

      this.$store.dispatch('getUsers', {
        users: this.role.users.map((item) =>{
          return item.id
        }).concat(this.formData.users)
      });
      this.$toastr.s(response.data.message);
      this.$hub.$emit('reload-' + this.tableId);
    },

    afterFinalResponse() {
      this.closeManageUserModal();
    },

    removeUser(user, index) {
      this.preloader = true;
      this.axiosPost({
        url: `${'admin/auth/users/detach-roles'}/${user.id}`,
        data: {roles: this.role.id}
      }).then(response => {
        this.$hub.$emit('reload-' + this.tableId);
        this.$toastr.s(response.data.message);
        this.user_list = this.collection(this.user_list).removeObject(user.id);
        this.$store.dispatch('addUser', user);
      }).catch(error => {
      }).finally(response => {
        this.preloader = false;
      });
    },

    closeManageUserModal(value) {
      this.$emit('close-modal', value);
    },

  },
  watch: {
    'role.users.length': {
      handler: function (users) {
        this.user_list = this.role.users;
        this.$store.dispatch(
            'getUsers', {
              users: this.role.users.map((item) => {
                return item.id
              })
            });
      },
      immediate: true
    },

  },


}
</script>
