<template>
  <div class="content-wrapper calendar-position-modified">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <app-breadcrumb
          :page-title="currentPipeline"
          :directory="[$t('deals'), $t('all_deals'), currentPipeline]"
          :icon="'clipboard'"
        />
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="float-md-right mb-3 mb-sm-3 mb-md-0">
          <div class="dropdown d-inline-block btn-dropdown">
            <button
              type="button"
              class="btn btn-success dropdown-toggle ml-0 mr-2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t("actions") }}
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/deal/import`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'download'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("import_deals") }}
              </a>

              <a
                class="dropdown-item d-flex align-items-center p-3"
                href=""
                @click.prevent="openLostreasonModal"
              >
                <app-icon
                  stroke-width="1"
                  :name="'plus-square'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("add_lost_reasons") }}
              </a>

              <a
                class="dropdown-item d-flex align-items-center p-3"
                :href="urlGenerator(`crm/lost/reasons/list/view`)"
              >
                <app-icon
                  stroke-width="1"
                  :name="'list'"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                {{ $t("manage_lost_reasons") }}
              </a>
            </div>
          </div>

          <button
            type="button"
            v-if="$can('create_deals')"
            class="btn btn-primary btn-with-shadow"
            data-toggle="modal"
            @click="openModal()"
          >
            {{ $t("add_deal") }}
          </button>
        </div>
      </div>
    </div>

    <app-table
      :id="tableId"
      @getFilteredValues="filteredValues"
      :options="options"
      @action="getAction"
    />

    <app-deal-modal
      v-if="isDealModalActive"
      :table-id="tableId"
      :selected-url="selectedUrl"
      :stage-id="selectedPipeline"
      @close-modal="closeModal"
    />

    <app-confirmation-modal
      v-if="confirmationModalActive"
      modal-id="deal-delete-modal"
      @confirmed="confirmed"
      @cancelled="cancelled"
    />

    <app-lost-reason-modal
      v-if="isModalActive"
      :table-id="tableId"
      :selected-url="selectedUrlReason"
      @close-modal="closeLostReasonModal"
    />
  </div>
</template>

<script>
import moment from 'moment';
import CoreLibrary from "@core/helpers/CoreLibrary.js";
import { dealStatus, tag, owner, contactType } from "@app/Mixins/Global/FilterMixins";
import { numberWithCurrencySymbol, urlGenerator } from "@app/Helpers/helpers";
import { getCustomFileds } from "@app/Mixins/Global/CustomFieldMixin";

export default {
  name: "ListView",
  extends: CoreLibrary,
  mixins: [dealStatus, tag, owner, contactType, getCustomFileds],
  data() {
    return {
      urlGenerator,
      isDealModalActive: false,
      tableId: "deal-modal",
      confirmationModalActive: false,
      isModalActive: false,
      selectedUrlReason: "",
      deal: "",
      value: 0,
      selectedUrl: "",
      currentPipeline: "",
      localPipelineId: "",
      selectedPipeline: "",
      pipelineValue: "",
      pipelineInitValue: 1,
      isPipelineEmpty: false,
      commonColumn: [
        {
          title: this.$t("title"),
          type: "link",
          key: "title",
          sortAble: true,
          isVisible: true,
          modifier: (value, row) => {
            return `crm/deal/${row.id}/details`;
          },
        },
        {
          title: this.$t("status"),
          type: "custom-html",
          key: "status",
          modifier: (value) => {
            return `<span class="badge badge-pill badge-${value.class ?? "secondary"}">${
              value.translated_name
            }</span>`;
          },
        },
        {
          title: this.$t("uuid"),
          type: "custom-html",
          key: "uuid",
          modifier: (value,row) => {
            return `<span>${
              row.contextable?.uuid
            }</span>`;
          },
        },
         {
          title: 'Priority',
          type: "custom-html",
          key: "priority_id",
          modifier: (value,row) => {
            let priority='';
            switch(value){
              case 1:
                priority='Low';
              break;
              case 2:
                priority='Medium';
              break;
              case 3:
                priority='High';
              break;
              case 4:
                priority='Very High';
              break;
            }
            return `<span>${ priority}</span>`;
          },
        },
        {
          title: this.$t("deal_value"),
          type: "custom-html",
          key: "value",
          modifier: (value) => {
            return `<p class="mb-0 d-flex align-items-center text-nowrap">${numberWithCurrencySymbol(
              value
            )}</p>`;
          },
        },
        {
          title: this.$t("lead"),
          type: "component",
          componentName: 'icon-with-text',
          key: "contextable"
        },
        {
          title: this.$t("created_date"),
          type: "custom-html",
          componentName: 'icon-with-text',
          key: "created_at",
          modifier: (value) => {
            return `<span>${moment(value).format('YYYY-MM-DD')}</span>`;
          },
        },
        {
          title: "Progress",
          type: "custom-html",
          componentName: 'icon-with-text',
          key: "stage",
          modifier: (value,row) => {
            return `<span>${
              value.name
            }</span>`;
          },
        },
        // {
        //   title: this.$t("contact_person"),
        //   type: "custom-html",
        //   key: "contact_person",
        //   modifier: (value, row) => {
        //     return value.length
        //       ? value[0].name
        //       : `<p class="m-0 font-size-90 text-secondary">` +
        //           this.$t("deal_has_no_contact_person") +
        //           `</p>`;
        //   },
        // },
        {
          title: this.$t("next_activity"),
          type: "text",
          key: "next_activity",
        },
        {
          title: this.$t("owner"),
          type: "custom-html",
          key: "owner",
          modifier: (value, row) => {
            return value
              ? value.full_name
              : `<p class="font-size-80 text-secondary">` +
                  this.$t("deal_has_no_owner") +
                  `</p>`;
          },
        },
        {
          title: this.$t("tags"),
          type: "component",
          key: "tags",
          isVisible: true,
          componentName: "tags-type-column",
        },
        {
          title: "Action",
          type: "action",
          key: "invoice",
          isVisible: true,
        },
      ],
      options: {
        name: this.$t("deal_table"),
        url: "crm/deals",
        showHeader: true,
        columns: [],
        filters: [
          {
            title: this.$t("owner"),
            type: "checkbox",
            key: "owner_is",
            option: [],
          },
          {
            title: this.$t("lead_group"),
            type: "checkbox",
            key: "contact_type",
            option: [],
          },
          {
            title: "Created date",
            type: "range-picker",
            key: "date",
            option: ["today", "thisMonth", "last7Days", "thisYear"],
          },
          // {
          //   title: "Deal have proposal",
          //   type: "radio",
          //   key: "deal_with_proposal",
          //   header: {
          //     title: "Want to filter your deal?",
          //     description: "You can filter your deal list which don't have any proposal",
          //   },
          //   option: [
          //     {
          //       id: 1,
          //       name: "Have proposal",
          //     },
          //     {
          //       id: 2,
          //       name: "Don't have proposal",
          //     },
          //   ],
          //   listValueField: "name",
          // },
          {
            title: this.$t("status"),
            type: "checkbox",
            key: "status",
            option: [],
          },
          {
            title: this.$t("tag"),
            type: "multi-select-filter",
            key: "tags",
            option: [],
          },
        ],
        showSearch: true,
        showFilter: true,
        paginationType: "pagination",
        responsive: true,
        rowLimit: 10,
        showAction: true,
        orderBy: "desc",
        actionType: "default",
        actions: [
          {
            title: this.$t("edit"),
            icon: "edit",
            type: "modal",
            component: "app-deal-modal",
            modalId: "deal-modal",
            url: "",
            modifier: () => this.$can("update_deals"),
          },
          {
            title: this.$t("delete"),
            icon: "trash",
            type: "modal",
            component: "app-confirmation-modal",
            modalId: "deal-delete-modal",
            url: "",
            modifier: () => this.$can("delete_deals"),
          },
        ],
      },
    };
  },
  methods: {
    filteredValues(value) {
      if (value["pipeline"]) {
        this.selectedPipeline = value.pipeline;
        this.localPipelineId = value.pipeline;
        this.currentPipeline = this.options.filters
          .find((el) => el.title === this.$t("pipelines"))
          .option.find((el) => el.id === this.localPipelineId).value;
      } else {
        this.currentPipeline = "All Deals";
      }

      if (value["pipeline"] == 0) {
        this.selectedPipeline = 1;
      }
    },
    getDealValue() {
      this.axiosGet("crm/deal-value").then((response) => {
        this.options.filters.push({
          title: this.$t("deal_value"),
          type: "range-filter",
          key: "deal-value",
          minTitle: this.$t("minimum_value"),
          maxTitle: this.$t("maximum_value"),
          maxRange: response.data.max_deal_value,
          minRange:
            response.data.min_deal_value < response.data.max_deal_value
              ? response.data.min_deal_value
              : 0,
        });
      });
    },
    getAllPipeline() {
      this.axiosGet("crm/stages").then((response) => {
        this.pipelineValue = response.data.data;
        this.isPipelineEmpty = this.pipelineValue.length > 0 ? false : true;
        this.pipelineInitValue = 0;
        this.options.filters.unshift({
          title: this.$t("pipelines"),
          type: "dropdown-menu-filter",
          key: "stage",
          initValue: this.pipelineInitValue,
          option: ([] = response.data.data.map((pipelines) => {
            return {
              id: pipelines.id,
              value: pipelines.name,
            };
          })),
        });
        if (this.isPipelineEmpty) {
          let pipeline = this.options.filters.find(
            (elsement) => elsement.title === this.$t("pipelines")
          );
          pipeline.option = [
            {
              id: 0,
              value: "Select a Progress",
            },
          ];
        } else {
          let pipeline = this.options.filters.find(
            (elsement) => elsement.title === this.$t("pipelines")
          );
          pipeline.option.unshift({
            id: 0,
            value: "Progress",
          });
        }
      });
    },
    getAction(rowData, actionObj, active) {
      let instance = this;
      if (actionObj.title == this.$t("edit")) {
        instance.selectedUrl = `crm/deals/${rowData.id}`;
        instance.isDealModalActive = true;
      } else if (actionObj.title == this.$t("delete")) {
        instance.deal = rowData.id;
        instance.confirmationModalActive = true;
      }
    },
    confirmed() {
      let instance = this,
        url = `crm/deals/${this.deal}`;
      this.axiosDelete(url)
        .then((response) => {
          instance.$toastr.s(response.data.message);
          instance.$hub.$emit("reload-" + this.tableId);
        })
        .catch(({ error }) => {
          //trigger after error
        })
        .finally(() => {
          instance.cancelled();
        });
    },
    cancelled() {
      let instance = this;
      instance.confirmationModalActive = false;
    },
    openModal() {
      let instance = this;
      instance.isDealModalActive = true;
      setTimeout(function () {
        $("#deal-modal").modal("show");
      });
    },
    closeModal() {
      let instance = this;
      this.isDealModalActive = false;
      instance.selectedUrl = "";
      $("#deal-modal").modal("hide");
    },
    openLostreasonModal() {
      this.isModalActive = true;
      $("#lost-reason-modal").modal("show");
    },
    closeLostReasonModal() {
      this.isModalActive = false;
      this.selectedUrlReason = "";
      $("#lost-reason-modal").modal("hide");
    },
  },
  mounted() {
    setTimeout(() => {
      // this.$store.dispatch("getOwner"),
      this.$store.dispatch("getOrganization"), this.$store.dispatch("getPerson");
      this.getDealValue();
    }, 1000);

    $("#deal-modal").on("hidden.bs.modal", () => {
      this.isDealModalActive = false;
    });

    $("#deal-delete-modal").on("hidden.bs.modal", () => {
      this.confirmationModalActive = false;
    });

    this.getCustomFiled("deal");
  },
  created() {
    // this.getDealValue();
    this.getAllPipeline();
  },
};
</script>
