<template>
	<div>
		<app-overlay-loader v-if="!dataLoaded"/>
		<div v-else class="content-wrapper">
			<div class="row">
				<div class="col-sm-12 col-md-6">
					<app-breadcrumb
						:button="{label: $t('back'), url:urlGenerator(`crm/person/list`)}"
						:directory="[$t('contacts'), name]"
						:icon="'users'"
						:page-title="name"
					/>
				</div>
				<div class="col-sm-12 col-md-6">
					<div class="float-md-right mb-3 mb-sm-3 mb-md-0">
						<button
							class="btn btn-primary btn-with-shadow"
							data-toggle="modal"
							type="button"
							@click="openDealModal()"
						>{{ $t('add_deal') }}
						</button>
					</div>
				</div>
			</div>

      <component is="app-user-info-details"
                 :user-info-sync-url="`crm/persons/profile-picture/${formData.id}`"
                 :user-info-data="formData"
                 component-type="person"
                 @update-request="updateRequest"/>

			<div class="row">
				<div class="col-xl-4">
            <component is="app-details-deals"
                            :Deals="formData.deals"
                            :contact-list="peopleList"
                            :Id="formData.id"
                            :total-deal="formData.total_deals"
                            component-type="person"
                            class="mb-primary"
                  />


  <app-details-contact-info
                :contact-info-data="formData"
                :contact-info-sync-url="`crm/persons/contact/sync/${formData.id}`"
                :person-id="formData.id"
                @update-request="updateRequest"
              />


		<tags-person :person="formData.id" :tagData="formData.tags" class="mb-primary"/>
    <!-- Passport Block-->
          <app-passport-details
						:passport-details="formData"
						:passport-update-url="`crm/persons/${formData.id}`"
						class="mb-primary"
						@update-request="updateRequest"
					/>

    <!-- insurance Block-->
          <app-insurance-details
						:insurance-details="formData"
						:insurance-update-url="`crm/persons/${formData.id}`"
						class="mb-primary"
						@update-request="updateRequest"
					/>


				</div>
				<div class="col-xl-8">
          <div class="row">
            <div class="col-md-6">
              <app-details-information
                class="mb-primary"
                :details-data="formData"
                :details-sync-url="`crm/persons/${formData.id}`"
                @update-request="updateRequest"/>
            </div>
            <div class="col-md-6">
              	<app-address-details
                :address-details="formData"
                :address-update-url="`crm/persons/${formData.id}`"
                class="mb-primary"
                @update-request="updateRequest"
              />
            </div>
          </div>
          <app-common-note-show
            :Data="formData"
            :edit-url="selectedUrl"
            :activity-filter-url="`crm/person/activities/`"
            :file-note-url="`crm/person`"
            component-type="person"
            v-if="isComponent"
            @open-activity="editActivity"
            @open-note-modal="editNote"
          />
				</div>
			</div>

			<app-deal-modal
				v-if="isDealModalActive"
				:pre-selected-option="{'person_id':formData.id}"
        component-type="person"
        :selectedUrlId="formData.id"
				@close-modal="closeDealModal"
			/>


			<app-common-activity-modal

				v-if="personActivitiesModal"
				:activity="rowData"
				:selected-url="editedUrl"
				@close-modal="closeActivityModal"
			/>

			<app-common-note-modal v-if="isNoteModal" :note="noteRowData" @close-modal="closeNoteModal"/>

		</div>
	</div>
</template>

<script>
import {FormMixin} from "@core/mixins/form/FormMixin.js";
import {formatDateToLocal, onlyTime, onlyTimeFromTime, textTruncate, urlGenerator} from "@app/Helpers/helpers";

export default {
	props: ["selectedUrl"],
	name: "PersonDetails",
	mixins: [FormMixin],

	components: {
		"tags-person": require("./Details/TagsPerson").default,
	},
	data() {
		return {
			urlGenerator,
			dataLoaded: false,
			preLoader: false,
			isDealModalActive: false,
			isNoteModal: false,
			personActivitiesModal: false,
			formData: {},
			appTapShow: false,
			isComponent: true,
			name: "",
			editedUrl: "",
			activitiesList: [],
			isfileNoteFilter: true,

			// Tab Start
			todoActivityTab: [
				{
					name: "note",
					icon: "file-text",
					component: "common-note-tab",
					props: "",
					permission: "",
				},
			],


			rowData: null,
			dealNoteModal: false,
			noteRowData: null,
			value: "",
			formatDateToLocal,
			onlyTimeFromTime,
			onlyTime,
			textTruncate,
		};
	},
	computed: {
		peopleList() {
			return this.$store.getters.getPerson
		},

		organizationList() {
			return this.$store.getters.getOrganization
		},
	},
	methods: {
		afterSuccessFromGetEditData(response) {
			this.formData = response.data;
			this.name = this.formData.name;
			this.todoActivityTab.forEach((el) => {
				el["props"] = {
					id: this.formData.id,
					person: "person",
					contextable_type: "App\\Models\\CRM\\Person\\Person",
					activitySyncUrl: `crm/person/activities/sync/${this.formData.id}`,
					fileSyncUrl: `crm/person/file/sync/${this.formData.id}`,
					noteSyncUrl: `crm/person/note/sync/${this.formData.id}`,
				};
			});

			this.appTapShow = true;
      this.dataLoaded = true;
      this.updateSeenStatus();
		},

		updateRequest() {
			this.isComponent = false;
			this.axiosGet(this.selectedUrl).then((response) => {
				this.formData = response.data;
				this.name = this.formData.name;
				this.isComponent = true;
			}).catch((error) => console.log(error));
		},

		updateDealList() {
			this.$hub.$on("deal-update-list", (value = true) => {
				if (value) {
					this.updateRequest();
				}
			});
		},
		updateTagList() {
			this.$hub.$on("tag-list", (value = true) => {
				if (value) {
					this.updateRequest();
				}
			});
		},
    	updateSeenStatus() {
      this.axiosGet( `crm/person/status-change/${this.formData.id}`).then((response) => {
        }).catch((error) => console.log(error));
		},

		editActivity(activity) {
			this.editedUrl = `crm/activities/${activity.id}`;
			this.rowData = activity;
			this.personActivitiesModal = true;
		},
		openDealModal() {
			this.isDealModalActive = true;
			setTimeout(function () {
				$("#deal-modal").modal("show");
			});
		},
		closeDealModal() {
			this.isDealModalActive = false;
			$("#deal-modal").modal("hide");
		},
		closeActivityModal() {
			this.personActivitiesModal = false;
			$("#common-activity-modal").modal("hide");
		},

		editNote(activity) {
			this.isNoteModal = true;
			this.noteRowData = activity;
			setTimeout(() => {
				$("#note-modal").modal("show");
			});
		},
		closeNoteModal() {
			this.isNoteModal = false;
			$("#note-modal").modal("hide");
		},
	},

	mounted() {
		this.updateDealList();
		this.updateTagList();
		this.$store.dispatch("getPerson");
		this.$store.dispatch('getOrganization');
	},
};
</script>
