<template>
  <div class="content-wrapper">
    <app-breadcrumb :page-title="$t('dashboard')"
                    :directory="$t('dashboard')"
                    :icon="'pie-chart'"/>
    <div class="row">

    <div class="col-xl-4">
        <div class="card card-with-shadow border-0">
          <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0"><a :href="urlGenerator(`crm/person/list`)">{{ $t('total_contacts') }}</a> </h5>
            <div class="badge dashboard-badge badge-pill text-capitalize">{{ totalContact }}</div>
          </div>
          <div class="card-body p-primary">
            <div v-for="(item , index) in contactList" :key="index"
                 :class="index==contactList.length-1?'':'pb-primary'"
                 class="dashboard-widgets dashboard-icon-widget">
              <div class="icon-wrapper">
                <app-icon :key="item.icon" :name="item.icon"/>
              </div>
              <div class="widget-data">
                <h6>{{ item.value }}</h6>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
      <div class="col-xl-4">
       <div class="card card-with-shadow border-0">
          <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0"><a :href="urlGenerator(`crm/activities/list/view`)" >{{ $t('total_activities') }}</a></h5>
            <div class="badge dashboard-badge badge-pill text-capitalize">{{ totalActivity }}</div>
          </div>
          <div class="card-body p-primary">
            <div v-for="(item , index) in activityList" :key="index"
                 :class="index==activityList.length-1?'':'pb-primary'"
                 class="dashboard-widgets dashboard-icon-widget">
              <div class="icon-wrapper">
                <app-icon :key="item.icon" :name="item.icon"/>
              </div>
              <div class="widget-data">
                <h6>{{ item.value }}</h6>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-xl-4 mb-primary">
        <div class="card card-with-shadow border-0 h-100">
          <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0"><a  :href="urlGenerator(`crm/deals/list/view`)" >{{ $t('total_deals') }}</a></h5>
            <div class="badge dashboard-badge badge-pill text-capitalize">{{totalDeal}}</div>
          </div>
          <app-overlay-loader v-if="dataload"/>
          <div class="card-body" v-else>
            <app-chart class="mb-primary"
                       type="dough-chart"
                       :height="230"
                       :labels="totalDealsLabels"
                       :data-sets="totalDealsDataSet"/>
            <div class="chart-data-list">
              <div class="d-flex justify-content-center">
                <div v-for="(item, index) in totalDealsChartElement" :key="index">
                  <div class="data-group-item" :style="item.color">
                    <span class="square" :style="item.background_color"/>
                    {{ item.key }}
                    <span class="value">{{ item.value }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-5 mb-primary">
                  <div class="card card-with-shadow border-0">
          <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">{{ $t('top_five_owners') }}</h5>
          </div>
          <app-overlay-loader v-if="dataload"/>
          <div class="card-body" v-else>
            <app-chart type="bar-chart"
                       :height="340"
                       :labels="barChartLabel"
                       :data-sets="barChartData"/>
          </div>
        </div>
      </div>
        <div class="col-xl-7 mb-4">
        <div class="card card-with-shadow border-0 h-100">
          <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">{{ $t('deals_on_pipeline') }}</h5>
            <ul class="nav tab-filter-menu justify-content-flex-end">
              <li class="nav-item" v-for="(item, index) in lineChartFilterOption"
                  :key="index">
                <a href="#"
                   class="nav-link py-0"
                   :class="[lineChartFilterValue == item.id ? 'active' : index === 0 && lineChartFilterValue === '' ? 'active': '']"
                   @click.prevent="getLineChartFilterValue(item.id)">
                  {{ item.value }}
                </a>
              </li>
            </ul>
          </div>
          <app-overlay-loader v-if="pipelineDataload"/>
          <div class="card-body" v-else>
            <app-chart type="horizontal-line-chart"
                       :height="340"
                       :labels="HorizontalLineChartLabel"
                       :data-sets="HorizontalLineChartData"/>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 mb-primary">
        <div class="card card-with-shadow border-0 h-100">
          <div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">{{ $t('deals_overview') }}</h5>
            <ul class="nav tab-filter-menu justify-content-flex-end">
              <li class="nav-item" v-for="(item, index) in chartFilterOptions"
                  :key="index">
                <a href="#"
                   class="nav-link py-0"
                   :class="[dealsFilter == item.id ? 'active' : index === 0 && dealsFilter === '' ? 'active': '']"
                   @click.prevent="dealsFilterValue(item.id)">
                  {{ item.value }}
                </a>
              </li>
            </ul>
          </div>

          <div class="card-body">
            <app-overlay-loader v-if="lineChartLoad"/>
            <template v-else>
            <app-chart class="mb-primary"
                       type="custom-line-chart"
                       :height="230"
                       :labels="lineChartLabels"
                       :data-sets="lineChartData"/>

            <div class="chart-data-list d-flex flex-wrap justify-content-center">
              <div class="data-group-item" style="color: #4466F2;">
                <span class="square" style="background-color: #4466F2;"/>
                {{ $t('open') }}

                <span class="value">{{ openDeal }}</span>
              </div>
              <div class="data-group-item" style="color: #27AE60;">
                <span class="square" style="background-color: #27AE60;"/>
                {{ $t('won') }}

                <span class="value">{{ wonDeal }}</span>
              </div>
              <div class="data-group-item" style="color: #FC5710;">
                <span class="square" style="background-color: #FC5710;"/>
                {{ $t('lost') }}

                <span class="value">{{ lostDeal }}</span>
              </div>
              <div class="data-group-item" style="color: #A45FFD;">
                <span class="square" style="background-color: #A45FFD;"/>
                {{ $t('total') }}

                <span class="value">{{ totalDealOverview }}</span>
              </div>
            </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {FormMixin} from "../../../../core/mixins/form/FormMixin";
import { collection, urlGenerator } from "@app/Helpers/helpers";

export default {
  name: "Dashboard",
  mixins: [FormMixin],
  data() {
    return {
      dataload:false,
      lineChartLoad:false,
      pipelineDataload:false,
      urlGenerator,

      // deals Overview - line chart
      chartFilterOptions: [
        {"id": "last_seven_days", "value": "Last 7 days"},
        {"id": "this_week", "value": "This week"},
        {"id": "last_week", "value": "Last week"},
        {"id": "this_month", "value": "This month"},
        {"id": "last_month", "value": "Last month"},
        {"id": "this_year", "value": "This year"},
        {"id": "total", "value": "Total"}
      ],
      dealsFilter: 'last_seven_days',
      lineChartLabels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      lineChartData: [
        {
          "title": "Open",
          "fill": false,
          "borderWidth": 1.5,
          "borderColor": "#4466F2",
          "backgroundColor": "#4466F2",
          data: [20,30,40,50,60,20,50],
        },
        {
          "title": "Won",
          "fill": false,
          "borderWidth": 1.5,
          "borderColor": "#27AE60",
          "backgroundColor": "#27AE60",
          data: [68, 57, 41, 66, 43, 59, 51]
        },
        {
          "title": "Lost",
          "fill": false,
          "borderWidth": 1.5,
          "borderColor": "#FC5710",
          "backgroundColor": "#FC5710",
          data: [50,100,110,50,60,20,50]
        },
        {
          "title": "Total",
          "fill": false,
          "borderWidth": 1.5,
          "borderColor": "#A45FFD",
          "backgroundColor": "#A45FFD",
          data: [10,30,30,25,10,5,6]
        }
      ],
      // Total deals - pie chart
      totalDealsLabels: ['Open', 'Won', 'Lost'],
      totalDealsDataSet: [
        {
          backgroundColor: [
            '#4466F2',
            '#27AE60',
            '#FC5710',
          ],
          data: [],
          borderWidth: 0,
        }
      ],
      totalDealsChartElement: [
        {
          "key": "Open",
           value: 0,
          "background_color": "background-color: #4466F2;",
          "color": "color: #4466F2;"
        },
        {
          "key": "Won",
          value: 0,
          "background_color": "background-color: #27AE60;",
          "color": "color: #27AE60;"
        },
        {
          "key": "Lost",
          value: 0,
          "background_color": "background-color: #FC5710;",
          "color": "color: #FC5710;"
        }
      ],
      // Total Contacts - App widget
      contactList: [
        {
          icon: 'user',
          title: 'Customer',
          value: 0
        },
         {
          icon: 'user-plus',
          title: 'New customers',
          value: 0
        },
      ],
        // Total Activity - App widget
      activityList: [
        {
          icon: 'check-square',
          title: 'Task Done',
          value: 0
        },
         {
          icon: 'calendar',
          title: 'Task Remains',
          value: 0
        },
      ],
      // Total Employees - App widget
      employeesList: [
        {
          icon: 'award',
          title: 'Works as owner',
          value: 0
        },
        {
          icon: 'user-plus',
          title: 'Works as collaborators',
          value: 0
        },
        {
          icon: 'users',
          title: 'Works as both owner and collaborators',
          value: 0
        }
      ],
      // Deals on pipeline - Horizontal line chart
      lineChartFilterOption: [
        {"id": "status_open", "value": "Open"},
        {"id": "status_won", "value": "Won"},
        {"id": "status_lost", "value": "Lost"},
      ],
      lineChartFilterValue: 'status_open',
      HorizontalLineChartLabel: [],
      HorizontalLineChartData: [
        {
          label: 'Data',
          backgroundColor: [],
          barThickness: 25,
          data: [],
          borderWidth: 0,
        }
      ],
      // Top five owners - bar chart
      barChartLabel: [],
      barChartData: [
        {
          label: 'Data',
          backgroundColor: "#4466F2",
          barThickness: 15,
          data: [],
          borderWidth: 0,
        }
      ],

      totalContact: null,
      totalActivity: null,
      totalEmployees: null,
      sendingRate: null,
      acceptanceRate: null,
      totalSendProposal: null,
      totalAcceptedProposal: null,
      totalPipeline: null,
      totalDeal: null,
      openDeal: null,
      wonDeal: null,
      lostDeal: null,
      totalDealOverview: null

    }
  },
  mounted() {
    this.dashboardGetData();
     this.dealOverViewLineChartData();
  },
  methods: {
    dashboardGetData() {
      this.dataload = true;
      this.pipelineDataload = true;
      this.axiosGet(`crm/dashboard?status=${this.lineChartFilterValue}`).then((response) => {

        // Deal Chart
         this.totalDeal = response.data.total_deal // Total Deal
         this.totalDealsDataSet.forEach((value, index) => {
           value.data = response.data.deals_chart
         })

        this.totalDealsChartElement.forEach((element, index) =>{
          element.value = response.data.total_deals_chart_element[index].value

        });

        // Contact

        this.totalContact = response.data.total_contact
        this.contactList.forEach((item, index) => {
          item.value = response.data.contacts[index].value
        })

           // Activities

        this.totalActivity = response.data.total_activity
        this.activityList.forEach((item, index) => {
          item.value = response.data.activities[index].value
        })

        // Employees
        this.totalEmployees = response.data.total_employee
        this.employeesList.forEach((employee, index) => {
          employee.value = response.data.employees[index].value
        })

        // Total send proposal
        this.totalSendProposal = response.data.total_send_proposal

        // total accepted proposal
        this.totalAcceptedProposal = response.data.total_accepted_proposal

        // Sending Rate
        this.sendingRate = response.data.sending_rate

        //acceptance rate
        this.acceptanceRate = response.data.acceptance_rate

        // Total Pipeline

        this.totalPipeline = response.data.total_pipeline

        // Deal on Pipeline

        this.HorizontalLineChartLabel = response.data.deals_on_pipeline_name

        this.HorizontalLineChartData.forEach((bgColor, index) => {
          bgColor.backgroundColor = response.data.background_color;
        })

        this.HorizontalLineChartData.forEach((element, index) => {

          element.data =  response.data.pipeline_total_deals

        })
        this.HorizontalLineChartData[0].data.push(0);

        // Top Five owner name
        this.barChartLabel = response.data.top_five_owners_name
        this.barChartData.forEach((element, index) => {
          element.data = response.data.five_owner_deal
        })
        this.barChartData[0].data.push(0)
      }).finally(() => {
        this.dataload = false;
        this.pipelineDataload = false;
      })
    },

    dealOverViewLineChartData(){
      this.lineChartLoad = true;
      this.axiosGet(`crm/deal-overview?${this.dealsFilter}`).then(response => {

        this.lineChartData.forEach((element, index) => {
          element.data = response.data.deal_over_view[index]
        })

        this.openDeal = response.data.open_deal // Total Open Deal
        this.wonDeal = response.data.won_deal // Total won Deal
        this.lostDeal = response.data.lost_deal // Total lost Deal
        this.totalDealOverview = response.data.total_deal_overview

      }).finally(() => {
           this.lineChartLoad = false;
      })
    },

    dealsFilterValue(value) {
      this.dealsFilter = value;
      this.lineChartLoad = true;
      this.axiosGet(`crm/deal-overview?${this.dealsFilter}`).then(response => {

        if (this.dealsFilter == 'last_seven_days' || this.dealsFilter == 'this_week' || this.dealsFilter == 'last_week'){
          this.lineChartLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

        }else if(this.dealsFilter == 'this_month' || this.dealsFilter == 'last_month'){

          this.lineChartLabels = response.data.deal_over_view[0].map((e, i) => {return i+1})

        }else if (this.dealsFilter == 'this_year' || this.dealsFilter == 'total'){
          this.lineChartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }

        this.lineChartData.forEach((element, index) => {
          element.data = response.data.deal_over_view[index]
        })

        this.openDeal = response.data.open_deal // Total Open Deal
        this.wonDeal = response.data.won_deal // Total won Deal
        this.lostDeal = response.data.lost_deal // Total lost Deal
        this.totalDealOverview = response.data.total_deal_overview
      }).finally(() => {
        this.lineChartLoad = false
      })
    },

    getLineChartFilterValue(value) {
      this.lineChartFilterValue = value;
      this.pipelineDataload = true;
      this.axiosGet(`crm/dashboard?status=${this.lineChartFilterValue}`).then((response) => {

        this.HorizontalLineChartLabel = response.data.deals_on_pipeline_name

        this.HorizontalLineChartData.forEach((bgColor, index) => {
          bgColor.backgroundColor = response.data.background_color;
        })

        this.HorizontalLineChartData.forEach((element, index) => {
          element.data =  response.data.pipeline_total_deals
        })
        this.HorizontalLineChartData[0].data.push(0);

      }).finally(() => {
        this.pipelineDataload = false;
      })
    }
  }
}
</script>
