<template>
	<div class="card border-0 card-with-shadow">
		<div class="card-header bg-transparent p-primary d-flex justify-content-between align-items-center">
			<h5 v-show="isDetailsEditable" class="card-title text-muted mb-0">Insurance details</h5>
			<h5 v-show="!isDetailsEditable" class="card-title text-muted mb-0">Edit Insurance details</h5>

			<div>
				<a href="#" v-show="!isDetailsEditable" class="text-muted" @click.prevent="showDetailsClose">
					<app-icon stroke-width="1" name="x-square"/>
				</a>
				<a href="#" v-show="!isDetailsEditable" class="text-muted" @click.prevent="showDetailsSave">
					<app-icon stroke-width="1" name="check-square"/>
				</a>
				<a href="#" v-show="isDetailsEditable" class="text-muted" @click.prevent="showDetailsEditable">
					<app-icon stroke-width="1" name="edit"/>
				</a>
			</div>
		</div>

		<div class="card-body">
			<div v-show="isDetailsEditable">
        <template v-if="(insuranceDetails.insurance_coverage)">

          <div class="form-group mb-3">
            <div class="form-row" v-if="insuranceDetails.country">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Company</label>
              <div class="col-9">
                {{ insuranceDetails.insurance_company }}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="insuranceDetails.insurance_contact">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Contact</label>
              <div class="col-9">
                {{insuranceDetails.insurance_contact}}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <div class="form-row" v-if="insuranceDetails.insurance_policy_number">
              <label class="mb-0 text-muted col-3 d-flex align-items-center">Policy No.</label>
              <div class="col-9">
                {{ insuranceDetails.insurance_policy_number }}
              </div>
            </div>
          </div>

        </template>
        <template v-else>
          <p class="text-muted">{{ $t('not_added_yet') }}</p>
          <a href="#" class="font-size-90" @click.prevent="showDetailsEditable">
            + Add Details
          </a>
        </template>
			</div>

			<div v-show="!isDetailsEditable">

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Company</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Company name'"
						           v-model="insuranceDetails.insurance_company"/>
					</div>
				</div>


				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Contact</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Contact'"
						           v-model="insuranceDetails.insurance_contact"/>
					</div>
				</div>

				<div class="form-group form-row mb-3">
					<label class="col-3 mb-0 d-flex align-items-center">Policy No.</label>
					<div class="col-9">
						<app-input type="text"
                       :placeholder="'Policy No.'"
						           v-model="insuranceDetails.insurance_policy_number"/>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
import {FormMixin} from "../../../core/mixins/form/FormMixin";

export default {
	name: "InsuranceDetails",
	mixins: [FormMixin],
	props: {
		insuranceDetails: {
			type: Object,
			required: true
		},
		insuranceUpdateUrl: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			isDetailsEditable: true,
      formData: {},
		}
	},

	methods: {
		showDetailsSave() {
      this.formData.name = this.insuranceDetails.name;
      this.formData.contact_type_id = this.insuranceDetails.contact_type_id;
      this.formData.owner_id = this.insuranceDetails.owner_id;
      this.formData.country_id = this.insuranceDetails.country_id;
      this.formData.area = this.insuranceDetails.area;
      this.formData.city = this.insuranceDetails.city;
      this.formData.state = this.insuranceDetails.state;
      this.formData.zip_code = this.insuranceDetails.zip_code;
      this.formData.address = this.insuranceDetails.address;
      this.formData.insurance_coverage = '1';
      this.formData.insurance_company = this.insuranceDetails.insurance_company;
      this.formData.insurance_contact = this.insuranceDetails.insurance_contact;
      this.formData.insurance_policy_number = this.insuranceDetails.insurance_policy_number;
			this.submitFromFixin('patch', this.insuranceUpdateUrl, this.formData)
		},
		afterSuccess(response) {
			this.$toastr.s(response.data.message);
			this.isDetailsEditable = true
			this.$emit('update-request');
		},
		showDetailsClose() {
			this.isDetailsEditable = true;
		},
		showDetailsEditable() {
			this.isDetailsEditable = false
		},
	},

	created() {
	}
}
</script>
