export default {
  data() {
    return {
      preLoader: true,
      isActivityModal: false,
      isNoteModal: false,
      value: '',
      activitiesList: [],
      rowData: {},
      noteRowData: null,
      isfileNoteFilter: true,
      collaborators: [],
      participants: [],
      formData: {},
      today: {dateOnly: moment().format('YYYY-MM-DD')},

      activitiesFilter: [
        {
          name: 'Todo',
          key: 'status=9'
        },
        {
          name: 'Done',
          key: 'status=10'
        },
        {
          name: 'Incomplete',
          key: 'incomplete'
        },
        {
          name: 'Files',
          key: 'file'
        },
        {
          name: 'Notes',
          key: 'note'
        },
        {
          name: 'Emails',
          key: 'email'
        }
      ],
    }
  },
  mounted() {
    this.formData = this.Data;
    this.collaboratorParticipant();
    this.updateActivity();
    this.filterActivities(this.activityFilterUrl, "status=9");
  },
  methods: {
    updateActivity() {
      this.$hub.$on("activity-list", (value = true) => {
        if (value) {
          if (this.editUrl){
            this.getUpdatedData();
          }
          if (this.activityFilterUrl){
            this.filterActivities(this.activityFilterUrl, `${value}`);
          }
        }
      });
    },
    getUpdatedData() {
      this.axiosGet(this.editUrl)
        .then(({data}) => {
          if (this.quickView){
            this.formData = data.current;
          }else {
            this.formData = data;
          }
          if (this.componentType == 'deal'){
            this.$emit("update-data", this.formData);
          }
          this.collaboratorParticipant();
        })
        .catch((error) => console.log(error));
    },
    filterActivities(url, key) {
      if (this.formData){
        this.preLoader = true;
        this.value = key;
        if (key == "file" || key == "note" || key == "email") {
          this.isfileNoteFilter = false;
          this.axiosGet(`${this.fileNoteUrl}/${this.formData.id}/${key}`).then((response) => {
            this.activitiesList = response.data;
          }).finally(() => {
            this.preLoader = false;
          }).catch((error) => console.log(error));
        } else {
          this.axiosGet(`${url}${this.formData.id}?${this.value}`)
            .then((response) => {
              this.activitiesList = response.data;
              this.isfileNoteFilter = true;
            }).finally(() => {
            this.preLoader = false;
          }).catch((error) => console.log(error));
        }
      }
    },
    activityChangeStatus(id) {
      this.preLoader = true
      this.axiosPost({
        url: `crm/activities/done/${id}`
      }).then((response) => {
        this.$toastr.s(response.data.message);
        this.filterActivities(this.activityFilterUrl, 'status=10');
      }).finally(() => {
        this.preLoader = false;
      }).catch((error) => console.log(error));
    },
    activityDelete(id) {
      this.axiosDelete(`crm/activities/${id}`).then((response) => {
        this.$toastr.s(response.data.message);
        this.filterActivities(this.activityFilterUrl, 'status=9');
      }).catch((error) => console.log(error));
    },

    editActivity(activity) {
      this.$emit("open-activity", activity);
    },

     editProposal(activity) {
      this.$emit("open-proposal-modal", activity);
    },

    fileDownload(activity) {
      this.axiosGet(`crm/file/download/${activity.id}`, {responseType: 'blob'}).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'activity' + activity.path);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },

    editNote(activity) {
      this.$emit("open-note-modal", activity);
    },

    noteDelete(id) {
      this.axiosDelete(`crm/note/delete/${id}`).then((response) => {
        this.$toastr.s(response.data.message);
        this.filterActivities(this.activityFilterUrl, 'note');
      }).catch((error) => console.log(error));
    },
    collaboratorParticipant() {
      if (this.formData){

        this.collaborators = [];
        this.participants = [];

        // Collaborator Group by
        this.formData.activity.forEach((element, index) => {
          element.collaborators.forEach((item, index) => {
            this.collaborators.push(item);
          });
        });
        // Participant Group By
        this.formData.activity.forEach((element, index) => {
          element.participants.forEach((item, index) => {
            this.participants.push(item);
          });
        });

      }
    },

  }
}
