<template>
    <div class="tags-type-column">

        <div class="dropdown">

            <div id="dropdownMenuLink" data-toggle="dropdown">
                <template v-if="this.rowData.tags.length > 0">
                    <span v-for="(tag,index) in this.rowData.tags"
                          :key='index'
                          class="badge badge-text-truncate badge-sm badge-pill text-capitalize mr-1 mb-1"
                          :style="'background-color:' + tag.color_code ">{{ tag.name }}
                    </span>
                </template>
                <span class="badge badge-text-truncate badge-sm badge-pill add mb-1 badge-light">+ {{ $t("add") }}</span>
            </div>

            <div class="dropdown-menu py-0" aria-labelledby="dropdownMenuLink">
                <div class="tags-view tags-organizer">
                    <div class="card-body p-2">
                        <tags-create :tag-list="rowData.tags"
                                     :editable="isTagsEditable"
                                     :taggable-id="rowData.id"
                                     :post-url="postUrl"
                                     :table-id="tableId"
                                     :allTags="allTags"
                                     @updateAllTags="updateAllTags"
                        />
                    </div>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
    import {FormMixin} from "@core/mixins/form/FormMixin";
    export default {
        name: "TagsTypeColumn",
        mixins: [FormMixin],
        props: {
            rowData: {
                type: Object
            },
            tableId: {
                type: String
            }
        },
        data() {
            return {
                search: '',
                index: '',
                isTagsEditable: true,
                postUrl: '',
            }
        },
        created() {
            this.tagsPostUrl();
        },
        computed:{
            allTags(){
                return this.$store.getters.getAllTags
            }

        },
        methods: {
            updateAllTags(){
                this.$store.dispatch('getAllTags')
            },
            tagsPostUrl() {
                if (this.tableId === 'person-modal') {
                    this.postUrl = `crm/persons/tags/`;
                } else if (this.tableId === 'organization-modal') {
                    this.postUrl = `crm/organizations/tags/`;
                } else if (this.tableId === 'send-proposal-modal') {
                    this.postUrl = `crm/proposals/tags/`;
                } else if (this.tableId === 'deal-modal') {
                    this.postUrl = `crm/deal/tags/`;
                }else if (this.tableId === 'deals-common-table') {
                  this.postUrl = `crm/deal/tags/`;
                }
            },

        }

    }
</script>
<style scoped>
    .add {
        cursor: pointer;
    }

    .add:hover {
        box-shadow: 1px 1px 5px #263516;
        background: #8bf768;
        color: #137924;
    }
</style>
