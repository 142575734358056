<template>
    <app-modal modal-id="proposal-modal" modal-size="large" modal-alignment="top" @close-modal="closeModal">
        <template slot="header">
            <h5 class="modal-title">{{proposal.id ? $t("edit") : $t("add")}} proposal</h5>
            <button type="button" class="close outline-none" data-dismiss="modal" aria-label="Close">
                <span>
                    <app-icon :name="'x'"></app-icon>
                </span>
            </button>
        </template>
      <app-overlay-loader v-if="dataLoaded"/>
        <template v-else slot="body">
            <form class="mb-0" ref="form" :data-url='`crm/proposals/${this.proposal.id}`'>
                <div class="col-12">
                    <app-input type="text-editor" id="editProposal" v-model="formData.reply"/>
                </div>
            </form>
        </template>
        <template slot="footer">
            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal" @click.prevent="closeModal">
                {{ $t('cancel') }}
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="submitData">
                {{ $t('save') }}
            </button>
        </template>
    </app-modal>
</template>

<script>

   import {FormMixin} from "../../../core/mixins/form/FormMixin";
   import { formatDateToLocal, collection, urlGenerator } from "@app/Helpers/helpers";

   export default {
        name: "ProposalModal",
        props: ['proposal'],
        mixins: [FormMixin],
        data() {
            return{
              dataLoaded:false,
                formData:{proposal: ''}
            }
        },

        methods: {
            submitData() {
                this.selectedUrl = urlGenerator(`crm/proposals/${this.formData.id}`);
                this.save(this.formData);
            },
            afterError(response) {
                this.$toastr.e(response.data.message);
            },

            afterSuccess(response) {
                this.$toastr.s(response.data.message);
              this.$hub.$emit('activity-list', 'proposal');
            },
            afterFinalResponse(){
                this.closeModal();
            },
            closeModal(value) {
                this.$emit("close-modal", value);
            },
          editProposalData() {
             this.formData.subject = this.proposal.subject;
                this.formData.status_id = this.proposal.status_id;
            this.formData.content = this.proposal.content;
             this.formData.reply = this.proposal.reply;
            this.dataLoaded = false;
          }
        },
        mounted(){
          this.editProposalData();
        }
    }
</script>
