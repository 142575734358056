<template>
    <div class="pt-1">
        <button class="btn btn-primary btn-sm" @click.prevent="openModal()">Confirm</button>
        <app-modal
        v-if="showModal"
    :modal-id="modalId"
    modal-size="small"
    modal-alignment="middle"

    @close-modal="closeModal">
    <template slot="header">
      <h5 class="modal-title">Do you want to mark it as done?</h5>
      <button
        type="button"
        class="close outline-none"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span>
          <app-icon :name="'x'"></app-icon>
        </span>
      </button>
    </template>
    <template slot="body">
        <span>
        <app-input
                type="checkbox"
                list-value-field='Yes'
                @changed="setActivityStatusDone"
                :list="index"
                v-model="checkboxValue"
        />
        </span>
    </template>
  </app-modal>
    </div>
</template>

<script>
    import { FormMixin } from "@core/mixins/form/FormMixin";
    import { api } from "@app/Helpers/api";
    import { collect } from "@app/Helpers/Collection";

    export default {
        name: "ActivityDone",
        mixins: [FormMixin],
        props: {
            tableId: String,
        },
        data() {
            return {
                formData: this.$attrs["row-data"],
                modalId: 'activity-modal-done'+this.$attrs["row-data"].id,
                addEditData: {},
                showModal:false,
                checkboxValue: [],
                index: [{id: this.$attrs["row-data"].id, value: "",disabled:false}],
            };
        },
        mounted(){
            api
                .route("crm/statuses")
                .params({name: "status_done", type: "activity"})
                .get()
                .then((res) => {
                    this.addEditData.status_id = collect(res).first().id;
                    this.checkboxValue = this.formData.status_id == collect(res).first().id ? [this.formData.id] : [];
                    this.index[0].disabled = this.checkboxValue.length > 0 ? true:false;
                    if (!this.$can('update_activities') || !this.$can('done_activities')) {
                        this.index[0].disabled = true;
                    }
                });
        },
        methods: {

          openModal() {
            this.showModal = true;
            $('activity-modal-done'+this.$attrs["row-data"].id).modal("show");
          },
          closeModal() {
            $('activity-modal-done'+this.$attrs["row-data"].id).modal("hide");
            this.showModal = false;
          },
            setActivityStatusDone() {
                //title is required for update request
                this.addEditData.title = this.formData.title;
                this.addEditData.contextable_type = this.formData.contextable_type;
                this.addEditData.contextable_id = this.formData.contextable_id;

                // status_id should be '5' as our statuses table
                this.axiosPut({
                    url: `crm/activities/${this.formData.id}`,
                    data: this.addEditData,
                }).then((response) => {
                    this.$toastr.s(response.data.message);
                   this.closeModal();
                   $('.modal-backdrop').remove();
                   $('html').css('overflow-y','auto');
                    this.$hub.$emit("reload-" + "activity-modal");
                });
            },
        },
    };
</script>
